/* ComplaintHowItWorks.css */
.complaint-how-it-works {
    max-width: 800px;
    margin: 0 auto;
    padding: 1.5rem;
    color: #374151;
    font-family: Arial, sans-serif;
  }
  
  .complaint-header {
    margin-bottom: 2rem;
  }
  
  .complaint-section-title {
    color: #166534;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
  }
  
  .complaint-section-description {
    color: #4b5563;
    font-size: 1.0625rem;
    line-height: 1.6;
    margin: 0;
  }
  
  /* Cards */
  .complaint-process-card {
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .card-icon {
    font-size: 1.5rem;
    color: #cfe4d7;;
    margin-right: 0.75rem;
  }
  
  .card-header h3 {
    color: #cfe4d7;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  .complaint-process-card p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  /* List styles */
  .complaint-list {
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
  }
  
  .complaint-list li {
    margin-bottom: 0.75rem;
    position: relative;
    padding-left: 0.25rem;
  }
  
  .complaint-list li strong {
    color: #166534;
  }
  
  /* Notice */
  .complaint-notice {
    background: rgba(22, 101, 52, 0.05);
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    color: #166534;
    font-size: 0.9375rem;
    margin-top: 1rem;
  }
  
  .complaint-notice svg {
    flex-shrink: 0;
    font-size: 1rem;
    margin-top: 0.125rem;
  }
  
  /* Steps */
  .step-container {
    margin-top: 1.5rem;
  }
  
  .step {
    display: flex;
    margin-bottom: 1.5rem;
    position: relative;
  }
  
  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 2.5rem;
    left: 0.9375rem;
    height: calc(100% - 0.5rem);
    width: 2px;
    background-color: #e5e7eb;
  }
  
  .step-number {
    width: 2rem;
    height: 2rem;
    background-color: #166534;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 1rem;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-content h4 {
    color: #166534;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  
  .step-content p {
    margin: 0;
    line-height: 1.5;
  }
  
  /* Key points */
  .complaint-key-points {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .key-point-card {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .key-point-icon {
    font-size: 2rem;
    color: #166534;
    margin-bottom: 1rem;
  }
  
  .key-point-card h3 {
    color: #166534;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
  }
  
  .key-point-card p {
    margin: 0;
    font-size: 0.9375rem;
    line-height: 1.5;
  }
  
  /* Sharing options */
  .sharing-options {
    padding-bottom: 2rem;
  }
  
  .sharing-levels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding: 0 1rem;
  }
  
  .sharing-level {
    background: #f3f4f6;
    border-radius: 6px;
    padding: 1rem;
    width: 42%;
    text-align: center;
  }
  
  .arrow-icon {
    color: #166534;
    font-size: 1.5rem;
  }
  
  .level-label {
    display: block;
    font-weight: 600;
    color: #166534;
    margin-bottom: 0.5rem;
  }
  
  .level-description {
    font-size: 0.875rem;
    color: #4b5563;
  }
  
  .sharing-note {
    font-style: italic;
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  
  /* Footer */
  .complaint-footer {
    background: #f8faf9;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .expectations h3 {
    color: #166534;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
  }
  
  .expectations ul {
    margin: 0;
    padding-left: 1.25rem;
  }
  
  .expectations li {
    margin-bottom: 0.5rem;
  }
  
  /* Ready to submit */
  .ready-to-submit {
    text-align: center;
    margin-top: 2rem;
    padding: 2rem;
    background: #f8faf9;
    border-radius: 8px;
  }
  
  .ready-to-submit h3 {
    color: #166534;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }
  
  .ready-to-submit p {
    margin-bottom: 1.5rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .complaint-button {
    background-color: #166534;
    color: white;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .complaint-button:hover {
    background-color: #15803d;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .complaint-key-points {
      flex-direction: column;
      gap: 1rem;
    }
    
    .sharing-levels {
      flex-direction: column;
      gap: 1rem;
    }
    
    .sharing-level {
      width: 100%;
    }
    
    .arrow-icon {
      transform: rotate(90deg);
      margin: 0.5rem 0;
    }
    
    .ready-to-submit p {
      max-width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .complaint-how-it-works {
      padding: 1rem;
    }
    
    .complaint-section-title {
      font-size: 1.5rem;
    }
    
    .complaint-section-description {
      font-size: 1rem;
    }
    
    .card-header h3,
    .ready-to-submit h3 {
      font-size: 1.125rem;
    }
    
    .complaint-process-card {
      padding: 1.25rem;
    }
  }