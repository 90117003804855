:root {
  --vc-primary-green: #1b2f1b;
  --vc-secondary-green: #4a7140;
  --vc-success: #22c55e;
  --vc-warning: #ef4444;
  --vc-info: #3b82f6;
  --vc-lab: #9333ea;
  --vc-text-dark: #1f2937;
  --vc-border: #e5e7eb;
  --vc-card-bg: #ffffff;
  --vc-star-gold: #FFBA08;
  --vc-star-empty: #E9ECEF;
}

.vc-card {
  background: var(--vc-card-bg);
  border: 1px solid var(--vc-border);
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  width: 100%;
  transition: box-shadow 0.2s ease;
}

.vc-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.vc-content-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.vc-top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

/* Left Side */
.vc-left-side {
  display: flex;
  /* align-items: center; */
  gap: 16px;
}

.vc-logo-section {
  flex-shrink: 0;
}

.vc-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.vc-company-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}
.vc-company-info .claim-button{
  display: flex;      
  align-items: center;       
  gap: 0.5rem;               
  font-size: 0.8rem;
  text-transform: none;
  padding: 0; 
  color: var(--vc-text-dark);
  border: 1px solid var(--vc-border);
  background-color: transparent;
  width: 8.5rem;
}

.vc-company-info .claim-button:hover{
  background: transparent;
}


.vc-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--vc-text-dark);
  margin: 0;
}

.vc-trust-indicators {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.vc-trust-pill {
  display: inline-flex;
  align-items: center;
  gap: 17px;
  font-size: 0.875rem;
  padding: 4px 12px;
  border-radius: 6px;
  font-weight: 500;
  background-color: #f9fafb;
  white-space: nowrap;
}

.vc-warning {
  background-color: rgba(239, 68, 68, 0.1);
  color: var(--vc-warning);
}

.vc-info {
  background-color: rgba(59, 130, 246, 0.1);
  color: var(--vc-info);
}

.vc-lab {
  background-color:#ecfdf5;
  color: #059669;
}

/* Right Side and Stats */
.vc-right-side {
  text-align: right;
  display: flex;
  align-items: center;
}

.vc-stats-container {
  background: #f9fafb;
  padding: 12px 16px;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end; /* Right align on desktop */
  gap: 4px;
}

/* Star Rating Styles */
.vc-star-rating {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 6px;
}

.vc-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.vc-star {
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.vc-stats-container:hover .vc-star {
  transform: scale(1.1);
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

.vc-star.filled {
  color: var(--vc-star-gold);
}

.vc-star.half {
  color: var(--vc-star-gold);
}

.vc-star.empty {
  color: var(--vc-star-empty);
}

.vc-rating-score {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--vc-text-dark);
  margin-top: -4px;
}

.vc-recommendation-score {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--vc-text-dark);
}

.vc-review-count {
  font-size: 0.9rem;
  color: #4b5563;
}

.vc-no-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Actions */
.vc-actions {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between; /* Space out buttons */
  gap: 0.5rem; /* Reduced gap */
}

/* Button Styles */
.vc-btn-view-details {
  padding: 0.5rem 1rem; /* Reduced padding */
  border-radius: 8px;
  font-size: 0.85rem; /* Reduced font size */
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Center button content */
  text-align: center;
  gap: 0.5rem;
  border: none;
  color: #ffffff;
  white-space: nowrap;
  transition: all 0.2s ease;
  cursor: pointer;
  flex: 1 0 auto; /* Allow buttons to shrink and grow */
  min-width: 0; /* Allow button to shrink below content width */
}

.vc-btn-view-details:hover {
  opacity: 0.9;
}

.vc-btn-view-details:first-child {
  background-color: var(--vc-secondary-green);
}

.vc-btn-view-details:nth-child(2) {
  background-color: var(--vc-primary-green);
}

.vc-btn-view-details:nth-child(3) {
  background-color: var(--vc-primary-green);
}

/* Responsive Design */
@media (max-width: 768px) {
  .vc-top-row {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .vc-right-side {
    align-items: flex-start;
    width: 100%;
    margin-top: 8px;
    text-align: center;
    justify-content: center;
  }

  .vc-stats-container {
    align-items: center; /* Center the stats on mobile */
    text-align: center;
    width: 100%; /* Take full width on mobile */
  }

  .vc-actions {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to full width */
    width: 100%; /* Make actions take full width */
    gap: 1rem; /* Further reduce gap */
  }
  .vc-btn-view-details {
    width: 100%; /* Make buttons take full width */
  }
  
  .vc-star {
    font-size: 1rem;
  }
}

.vc-trust-badge {
  /* Your existing desktop styles (display: inline-flex, etc.) */
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.vc-trust-badge.vc-shipping {
  /* Your existing shipping colors, etc. */
  background-color: #eff6ff;
  color: #2563eb;
}

@media (max-width: 768px) {
  .vc-trust-badge.vc-shipping {
    display: flex;          /* Instead of inline-flex */
    /* flex-direction: column;  */
    /* Stack icon & badges vertically */
    align-items: flex-start;/* Align them to the left */
    gap: 4px;               /* A smaller gap if desired */
  }

  .american-glob-icon{
    margin-top: 0.45rem;
  }
  
  .vc-badge-group {
    flex-direction: column;
  }
}

/* A small container to align multiple lines under the same icon */
.vc-badge-group {
  display: flex;
  /* flex-direction: column; stack items vertically */
  gap: 6px;               /* space between each line */
  white-space: nowrap;    /* or allow wrapping if you prefer */
}

/* If you had ".nationwide-badge" or ".country-badge":
   rename them to something like .vc-nationwide-badge and .vc-country-badge
   but *keep* the original styling as you want the same look.
*/
.vc-nationwide-badge,
.vc-country-badge {
  /* same styling as your old badges, for example: */
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #eff6ff;
  color: #2563eb;
}