.thread-card__container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.thread-card__container:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.thread-card__pinned {
  border-left: 4px solid #4076f6;
}

.thread-card__pinned-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #4076f6;
  color: white;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
}

.thread-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.thread-card__author-info {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.thread-card__author-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.thread-card__author-name-role{
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.thread-card__author-name {
  font-weight: 500;
  color: #1b2f1b;
}

.thread-card__author-role{
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  width: fit-content;
  color:#ffffff;
  font-weight: 500;
}

.thread-card__time {
  color: #6b7280;
  font-size: 0.875rem;
}

.thread-card__content {
  display: block;
  text-decoration: none;
  color: inherit;
  margin-bottom: 12px;
}

.thread-card__title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1b2f1b;
  margin-bottom: 8px;
}

.thread-card__preview {
  color: #4b5563;
  font-size: 0.875rem;
  margin-bottom: 12px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* New taxonomy section to contain both category and tags */
.thread-card__taxonomy {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  align-items: center;
}

.thread-card__category {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgba(64, 118, 246, 0.1);
  color: #4076f6;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-weight: 500;
}

.thread-card__category:hover {
  background-color: rgba(64, 118, 246, 0.2);
}

.thread-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.thread-card__tag {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.thread-card__tag:hover {
  background-color: rgba(59, 126, 59, 0.2);
}

.thread-card__stats {
  display: flex;
  gap: 16px;
}

.thread-card__stat {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6b7280;
  font-size: 0.875rem;
}

@media (max-width: 640px) {
  .thread-card__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .thread-card__time {
    font-size: 0.75rem;
  }
  
  .thread-card__taxonomy {
    flex-direction: column;
    align-items: flex-start;
  }
}