/* CommentListItem.css */
/* Common styles for comment display (both main and replies) */
.comment-display {
  /* Optional: padding/margin if needed */
}
.comment-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.deleted-post {
  font-weight: bold;
  font-style: italic;
  color: #6b7280; /* optional: adjust the color to your liking */
}

.comment-header {
  display: flex;
  margin-bottom: 0.75rem;
}
.comment-author-info {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}
.comment-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #4285f4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
  flex-shrink: 0;
  overflow: hidden;
}
.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment-user-details {
  display: flex;
  flex-direction: column;
}
.comment-author {
  font-weight: 600;
  color: #166534;
  margin-bottom: 0.125rem;
}
.comment-timestamp {
  font-size: 0.8125rem;
  color: #6b7280;
}
.comment-content {
  color: #374151;
  line-height: 1.5;
  font-size: 0.9375rem;
  margin-bottom: 1rem;
  margin-left: 3.25rem; 
}
.comment-content a {
  color: #166534;
  text-decoration: underline;
}
.comment-content a:hover {
  color: #15803d;
}

/* Main comment container */
.my-comment-list-item {
  border-bottom: 1px solid #e5e7eb;
  padding: 1.25rem 0;
  animation: fadeSlideUp 0.3s ease-out;
}
.my-comment-list-item:last-child {
  border-bottom: none;
}

/* Comment actions (upvote, downvote, reply) */
.my-comment-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 3.25rem;
}
.my-vote-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #6b7280;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}
.my-vote-button:hover {
  background-color: rgba(22, 101, 52, 0.05);
  color: #166534;
}
.my-vote-button svg {
  font-size: 1.125rem;
}
.my-vote-button.active {
  color: #166534;
}
.my-vote-button.active svg {
  color: #166534;
}
.my-reply-button {
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: none;
  color: #166534;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s ease;
}
.my-reply-button:hover {
  color: #15803d;
  text-decoration: underline;
}

/* Reply box */
.reply-item-actions{
  margin-left: 2rem;
}

.my-reply-box {
  margin: 0.75rem 0 1rem 4rem;
  border-left: 2px solid #e5e7eb;
  padding-left: 1rem;
  animation: fadeIn 0.3s ease-out;
}
.my-reply-textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.875rem;
  resize: vertical;
  min-height: 80px;
  margin-bottom: 0.75rem;
  color: #374151;
  transition: border-color 0.2s ease;
}
.my-reply-textarea:focus {
  outline: none;
  border-color: #166534;
}
.my-submit-reply-button {
  background-color: #166534;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}
.my-submit-reply-button:hover {
  background-color: #15803d;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(22, 101, 52, 0.1);
}
.my-submit-reply-button:active {
  transform: translateY(0);
}

/* Replies section */
.my-replies-list {
  margin: 1rem 0 1.5rem 4rem;
  border-left: 2px solid rgba(22, 101, 52, 0.2);
  padding-left: 1rem;
}
.my-reply-item {
  padding: 0.75rem 0;
  border-bottom: 1px solid #f3f4f6;
  animation: fadeIn 0.3s ease-out;
}
.my-reply-item:last-child {
  border-bottom: none;
}

/* Tech-style toggle replies button */

.replies-toggle {
  margin: 0.5rem 0 0.75rem 3.25rem;
  position: relative;
}

.toggle-replies-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  background-color: #f8faf9;
  border: 1px solid #e5e7eb;
  border-left: 3px solid #166534;
  border-radius: 2px;
  color: #374151;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggle-replies-button:hover {
  background-color: #f0fdf4;
  border-color: #bbf7d0;
  border-left-color: #15803d;
  box-shadow: 0 2px 4px rgba(22, 101, 52, 0.1);
}

.toggle-replies-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(22, 101, 52, 0.1);
}

/* Icon container */
.toggle-replies-button .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #166534;
  border-radius: 2px;
  color: white;
  transition: all 0.2s ease;
}

.toggle-replies-button:hover .icon-container {
  background-color: #15803d;
  transform: rotate(90deg);
}

/* Icon styling */
.toggle-replies-button svg {
  font-size: 0.625rem;
}

/* Reply count badge */
.reply-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  background-color: #e5e7eb;
  border-radius: 10px;
  color: #374151;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 4px;
  transition: all 0.2s ease;
}

.toggle-replies-button:hover .reply-count {
  background-color: #166534;
  color: white;
}

/* Thread line */
.thread-line {
  position: absolute;
  top: 50%;
  left: -1rem;
  width: 1rem;
  height: 1px;
  background-color: #e5e7eb;
}

.thread-line::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background-color: #fff;
}

/* Collapsed vs. expanded states */
.replies-collapsed .toggle-replies-button {
  opacity: 0.85;
}

.replies-expanded .toggle-replies-button {
  background-color: #f0fdf4;
  border-left-color: #15803d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .replies-toggle {
    margin-left: 3rem;
  }
}

@media (max-width: 576px) {
  .replies-toggle {
    margin-left: 0;
  }
  
  .toggle-replies-button {
    font-size: 0.75rem;
    padding: 0.25rem 0.625rem 0.25rem 0.375rem;
  }
}

/* Optional override for reply display */
.reply-display .comment-content {
  margin-left: 2.5rem;
}

@keyframes fadeSlideUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* .my-reply-box .my-quill-container {
  display: flex;
  flex-direction: column;
}

.my-reply-box .my-reply-quill {
  height: 120px;
  border-radius: 6px;
  margin-bottom: 0.5rem;
} */
/* Responsive styles */
@media (max-width: 768px) {
  .my-comment-actions {
    flex-wrap: wrap;
    margin-left: 3rem;
  }
  .comment-content {
    margin-left: 3rem;
  }
  .my-replies-list {
    padding-left: 0.75rem;
  }
  .my-reply-box {
    padding-left: 0.75rem;
  }
  .reply-item-actions{
    margin-left: 2rem;
  }
}
@media (max-width: 576px) {
  .my-comment-list-item {
    padding: 1rem 0;
  }
  .comment-avatar {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
  .comment-content {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    margin-left: 0;
  }
  .comment-timestamp {
    font-size: 0.75rem;
  }
  .my-comment-actions {
    margin-left: 0;
  }
  .my-vote-button {
    font-size: 0.75rem;
  }
  .my-reply-button {
    font-size: 0.75rem;
  }
  .my-replies-list {
    margin-left: 1rem;
    padding-left: 0.75rem;
  }
  .my-reply-box {
    margin-left: 1rem;
    padding-left: 0.5rem;
  }
}
