/* ============================
   ReviewForm.css (combined)
============================ */

/* Shared styles for BOTH Offcanvas & Modal */
.review-modal {
    /* This container class is applied to both <Offcanvas className="review-modal offcanvas"> 
       and <Modal className="review-modal modal"> */
  }
  
  /* Intro text, sections, rating, inputs, etc. that apply to BOTH */
  .review-modal .intro-text {
    font-size: 0.9rem;
    color: #4b5563;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .review-modal .form-section {
    margin-bottom: 1.5rem;
  }
  
  .review-modal .section-label,
  .review-modal .section-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .review-modal .section-subtext,
  .review-modal .section-description {
    font-size: 0.8rem;
    color: #6b7280;
    margin-bottom: 0.75rem;
  }
  
  .review-modal .input-group {
    margin-bottom: 0.75rem;
  }
  
  .review-modal .input-group .form-control {
    font-size: 0.9rem;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
  }
  
  .review-modal .input-group .btn {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
  }
 
  .review-modal .remove-feedback-btn{
    width: auto;
  }

  .review-modal .list-group-item {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
  }
  
  .review-modal .list-group-item .btn-outline-danger {
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
  }
  
  .review-modal textarea.form-control {
    min-height: 5rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    resize: vertical;
  }
  
  .review-modal .radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .review-modal .form-check-label {
    font-size: 0.9rem;
  }
  
  .review-icons{
    width: 1rem!important;
    height: 1rem!important;
  }

  .review-modal .text-danger {
    font-size: 0.8rem;
  }
  
  /* Submit button section */
  .review-modal .submit-button-section {
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .review-modal .submit-button-section .btn {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
  
  /* Custom Button Colors (shared) */
  .review-modal .btn-success {
    background-color: #10b981;
    border-color: #10b981;
  }
  
  .review-modal .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .review-modal .btn-primary {
    background-color: #4B0082;
    border-color: #4B0082;
  }
  
  .review-modal .btn-outline-secondary {
    color: #4B0082;
  }
  
  /* Rating Section (shared) */
  .review-modal .rating-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .review-modal .rating-section h6 {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .rating-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .rating-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    color: #6b7280;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    border: 1px solid transparent;
  }
  
  .rating-option svg {
    font-size: 2rem;
    margin-bottom: 0.2rem;
  }
  
  .rating-option .label-text {
    display: block;
    line-height: 1;
  }
  
  /* Style the selected option */
  .rating-option.selected {
    border-color: #4B0082;
    background-color: #f5f2ff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .rating-option.selected svg {
    color: #4B0082;
  }
  
  /* Mobile adjustments for rating */
  @media (max-width: 576px) {
    .rating-option {
      font-size: 0.7rem;
      padding: 0.3rem;
    }
    .rating-option svg {
      font-size: 1.5rem;
    }
  }
  
  
  /* =========================================
     Offcanvas-specific styles (namespaced)
  ========================================= */
  .review-modal.offcanvas .offcanvas-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .review-modal.offcanvas .offcanvas-title {
    font-size: 1.25rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  
  .review-modal.offcanvas .offcanvas-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
    max-height: 80vh;
  }
  
  .review-modal.offcanvas .private-info-section {
    border-top: 1px solid #e5e7eb;
    padding-top: 1.5rem;
  }
  
  /* Offcanvas height adjustments on small screens */
  @media (max-width: 576px) {
    .review-modal.offcanvas .offcanvas-title {
      font-size: 1.1rem;
    }
    .review-modal.offcanvas .intro-text {
      font-size: 0.8rem;
    }
    .review-modal.offcanvas.offcanvas-bottom {
      height: 80vh;
      max-height: 800px; /* if needed */
    }
    .review-modal.offcanvas .section-label,
    .review-modal.offcanvas .section-title {
      font-size: 0.9rem;
    }
    .review-modal.offcanvas .section-subtext,
    .review-modal.offcanvas .section-description,
    .review-modal.offcanvas .rating-section h6,
    .review-modal.offcanvas .input-group .form-control,
    .review-modal.offcanvas .input-group .btn,
    .review-modal.offcanvas .list-group-item,
    .review-modal.offcanvas textarea.form-control,
    .review-modal.offcanvas .form-check-label {
      font-size: 0.8rem;
    }
    .review-modal.offcanvas .list-group-item .btn-outline-danger {
      padding: 0.1rem 0.4rem;
      font-size: 0.6rem;
    }
    .review-modal.offcanvas .submit-button-section .btn {
      font-size: 0.9rem;
    }
  }
  
  /* Additional offcanvas mobile responsiveness */
  @media (max-width: 767px) {
    .review-modal.offcanvas .form-section {
      width: 100%;
    }
    .review-modal.offcanvas .input-group {
      width: 100%;
    }
    .review-modal.offcanvas .form-control {
      width: 100%;
    }
  }
  
  /* Touch optimizations for offcanvas */
  @media (hover: none) {
    .review-modal.offcanvas .btn:active {
      opacity: 0.8;
    }
  }
  
  
  
  /* ====================================
     Modal-specific styles (namespaced)
  ==================================== */
  .review-modal.modal .modal-content {
    border-radius: 16px 16px 0 0;
    border: none;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .review-modal.modal .modal-dialog {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }
  
  .review-modal.modal .modal-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
  }
  
  .review-modal.modal .modal-title {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  
  .review-modal.modal .modal-body {
    flex: 1 1 auto; /* let body expand */
    overflow-y: auto; /* scroll if needed */
    padding: 16px;
    max-height: 70vh; /* adjust if needed */
  }
  
  .review-modal.modal .modal-footer {
    padding: 16px;
    border-top: 1px solid #eee;
    display: flex;
    gap: 12px;
    flex: 0 0 auto;
  }
  .add-button{
    z-index: 0!important;
    width: 3rem!important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  /* Mobile responsiveness for modals */
  @media (max-width: 768px) {
    .review-modal.modal .modal-dialog {
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
    .review-modal.modal .modal-content {
      border-radius: 0;
      max-height: 100vh;
    }
    .review-modal.modal .modal-header,
    .review-modal.modal .modal-footer {
      padding: 12px;
    }
    .review-modal.modal .input-group {
      /* flex-direction: column; */
      gap: 8px;
    }
    .review-modal.modal .list-group-item {
      flex-direction: row;
      gap: 8px;
    }
    .review-modal.modal .modal-footer {
      flex-direction: column;
    }
    .review-modal.modal .modal-footer .btn {
      width: 100%;
    }
  }
  
  /* Touch optimizations for modal */
  @media (hover: none) {
    .review-modal.modal .btn:active {
      opacity: 0.8;
    }

  }
  
  /* Unverified Notice Styles */
.unverified-notice {
    background-color: #fffaf0;           /* Subtle pale-yellow background */
    border: 1px solid #fef6c3;          /* Light, warm border color */
    border-radius: 6px;                 /* Slightly rounded corners */
    padding: 1rem;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;                  /* Tweak as needed */
    color: #4b5563;                     /* Dark gray text for readability */
    line-height: 1.4;
  }
  
  .unverified-notice strong {
    color: #2d3748;                     /* Slightly darker for emphasis */
    font-weight: 600;
  }
  
  .unverified-notice em {
    font-style: italic;
    color: #2d3748;
  }
  
  .unverified-notice a {
    color: #2b6cb0;                     /* Link color that stands out */
    text-decoration: underline;
    font-weight: 500;
  }
  
  /* Default: Use Bootstrap’s usual horizontal layout */
.input-group-responsive {
    display: flex; /* Usually Bootstrap does this by default */
    flex-direction: row;
  }
  
  /* For mobile screens, stack the input and button */
  @media (max-width: 768px) {
    .review-modal .input-group {
      flex-direction: row!important;
      flex-wrap: nowrap!important;
      gap: 0!important;
    }
  }

  
  .image-preview-container {
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .error-message {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .error-summary {
    font-weight: bold;
    font-size: 1.1em;
  }
  