/* RichTextEditor.css - Enhanced for more reliable GIF button display */
/* Container styling */
.rich-text-editor-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
}

/* Custom editor styling */
.custom-quill-editor {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.custom-quill-editor .ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #e2e8f0;
  background-color: #f8f9fa;
  padding: 8px;
}

.custom-quill-editor .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: #e2e8f0;
  font-size: 16px;
}

/* Enhanced GIF button styling - More reliable selector */
button.ql-gif {
  width: 28px !important;
  height: 24px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  background: none !important;
  padding: 0 !important;
}

/* Blank GIF button fallback styling - Ensures something is always visible */
button.ql-gif:empty::before {
  content: "GIF";
  font-weight: 700;
  font-size: 12px;
  color: #4076f6;
  background-color: #e6f0ff;
  padding: 2px 4px;
  border-radius: 4px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Style the GIF icon with a colorful text approach */
.ql-gif-icon {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #4076f6 !important;
  background-color: #e6f0ff !important;
  padding: 2px 4px !important;
  border-radius: 4px !important;
  line-height: 1 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.2s, color 0.2s !important;
}

/* Hover effect for the GIF button */
button.ql-gif:hover .ql-gif-icon,
button.ql-gif:hover:empty::before {
  background-color: #4076f6 !important;
  color: white !important;
}

/* Active state for the GIF button when picker is open */
button.ql-gif.ql-active .ql-gif-icon,
button.ql-gif.ql-active:empty::before {
  background-color: #3366e6 !important;
  color: white !important;
}

/* Better focus indicators for accessibility */
.custom-quill-editor .ql-toolbar button:focus {
  outline: 2px solid #4076f6;
  outline-offset: 2px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .custom-quill-editor .ql-toolbar {
    padding: 6px;
    flex-wrap: wrap;
  }
  
  .ql-formats {
    margin-right: 6px !important;
  }
  
  .custom-quill-editor .ql-container {
    font-size: 14px;
  }
}

/* Darken the text when hovering over toolbar buttons */
.custom-quill-editor .ql-toolbar button:hover {
  color: #000;
}

/* Style the editor placeholder */
.custom-quill-editor .ql-editor.ql-blank::before {
  font-style: italic;
  color: #a0aec0;
}

/* Ensure proper spacing between toolbar buttons */
.ql-formats {
  margin-right: 8px !important;
  display: inline-flex !important;
  align-items: center !important;
}

/* Make sure toolbar formats don't wrap awkwardly */
.custom-quill-editor .ql-toolbar.ql-snow .ql-formats {
  margin-bottom: 4px;
}