/* LabTestCard.css */
/* Improved KratomHub LabTestCard - Mobile-First Responsive Design */
:root {
  --primary-color: #1b2f1b;
  --primary-hover: #2c5e2c;
  --primary-light: #3b7e3b;
  --primary-very-light: rgba(59, 126, 59, 0.1);
  --success-color: #059669;
  --success-bg: #e6f7ed;
  --warning-color: #d97706;
  --warning-bg: #fffbeb;
  --text-primary: #2d3748;
  --text-secondary: #4a5568;
  --border-color: rgba(59, 126, 59, 0.15);
  --background-subtle: #f9fafb;
  --card-bg: #ffffff;
  --danger-color: #dc2626;
  --button-primary: #4076f6;
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 0.75rem;
  --spacing-lg: 1rem;
  --spacing-xl: 1.5rem;
  --border-radius-sm: 0.375rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 0.625rem;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  --transition-speed: 0.2s;
}

/* Card Container - Mobile First */
.ltc-card {
  background: var(--card-bg);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  border: 1px solid var(--border-color);
  position: relative;
  margin-bottom: var(--spacing-lg);
  width: 100%;
}

.ltc-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* Status Banner */
.ltc-status-banner {
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.ltc-status-banner.ltc-status-pass {
  background-color: var(--success-bg);
}

.ltc-status-banner.ltc-status-attention {
  background-color: var(--warning-bg);
}

.ltc-status-indicator {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.3rem 0.7rem;
  border-radius: 20px;
  white-space: nowrap;
}

.ltc-status-indicator .ltc-icon {
  font-size: 1rem;
}

.ltc-status-indicator.ltc-status-pass {
  background-color: var(--success-bg);
  color: var(--success-color);
}

.ltc-status-indicator.ltc-status-attention {
  background-color: rgba(217, 119, 6, 0.1);
  color: var(--warning-color);
}

.ltc-date-display {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  font-size: 0.8125rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.3rem 0.7rem;
  border-radius: 20px;
  white-space: nowrap;
}

.ltc-date-display .ltc-icon {
  margin-right: var(--spacing-xs);
  font-size: 0.9rem;
  color: var(--primary-light);
}

/* Main Content */
.ltc-card-content {
  padding: var(--spacing-md) var(--spacing-md);
}

.ltc-card-main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

/* Product & Vendor Info */
.ltc-info-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.ltc-info-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  transition: transform var(--transition-speed) ease;
}

.ltc-info-row .ltc-icon {
  color: var(--primary-light);
  flex-shrink: 0;
  font-size: 1.1rem;
  margin-top: 0.1rem;
}

.ltc-info-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.ltc-info-text a {
  color: var(--primary-light);
  text-decoration: none;
  transition: color var(--transition-speed) ease;
  font-weight: 600;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ltc-info-text a:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

.ltc-product-name {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 2px;
  color: var(--text-primary);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ltc-lot-number {
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin: 0;
}

/* Results Grid */
.ltc-results-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
  width: 100%;
}

.ltc-result-card {
  background-color: #f5f9f5;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  border: 1px solid var(--border-color);
}

.ltc-result-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-sm);
  color: var(--primary-color);
  font-size: 0.95rem;
  font-weight: 700;
}

.ltc-result-header .ltc-icon {
  color: var(--primary-light);
  font-size: 1rem;
}

.ltc-result-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ltc-result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0.45rem 0.7rem;
  border-radius: var(--border-radius-sm);
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.ltc-result-label {
  font-size: 0.875rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.ltc-result-value {
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.2rem 0.6rem;
  border-radius: 20px;
  min-width: 3.5rem;
  text-align: center;
}

.ltc-result-value.ltc-result-pass {
  background-color: var(--success-bg);
  color: var(--success-color);
}

.ltc-result-value.ltc-result-fail {
  background-color: rgba(220, 38, 38, 0.1);
  color: var(--danger-color);
}

.ltc-result-value.ltc-result-neutral {
  background-color: var(--primary-very-light);
  color: var(--primary-light);
}

/* Action Buttons */
.ltc-action-buttons {
  display: flex;
  margin-top: var(--spacing-md);
  width: 100%;
}

.ltc-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1rem;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  font-size: 0.9rem;
  transition: all var(--transition-speed) ease;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.ltc-btn-primary {
  background-color: var(--button-primary);
  color: white;
}

.ltc-btn-primary:hover,
.ltc-btn-primary:focus {
  background-color: #3366e6;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.ltc-btn-outline {
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
  background-color: white;
}

.ltc-btn-outline:hover,
.ltc-btn-outline:focus {
  background-color: var(--primary-very-light);
  transform: translateY(-2px);
}

.ltc-btn .ltc-icon {
  margin-left: var(--spacing-xs);
  font-size: 1rem;
  transition: transform var(--transition-speed) ease;
}

.ltc-btn:hover .ltc-icon {
  transform: translateX(3px);
}

/* Responsive Design for Larger Screens */
@media (min-width: 500px) {
  .ltc-results-grid {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-md);
  }
}

@media (min-width: 768px) {
  .ltc-card-main {
    flex-direction: row;
    align-items: stretch;
  }
  
  .ltc-info-section {
    flex: 1;
    min-width: 180px;
    max-width: 40%;
  }
  
  .ltc-results-grid {
    flex: 2;
  }
  
  .ltc-action-buttons {
    margin-top: auto;
  }
}

/* Homepage Layout Specific */
.homepage-layout .ltc-card {
  max-width: 480px;
  margin: 0 auto var(--spacing-lg);
}

/* Lab Test List Layout Specific */
.list-layout .ltc-card {
  max-width: 100%;
}

/* Mobile-Optimized Layout */
@media (max-width: 480px) {
  .ltc-card {
    margin-bottom: var(--spacing-md);
  }
  
  .ltc-status-banner {
    padding: var(--spacing-xs) var(--spacing-md);
  }
  
  .ltc-card-content {
    padding: var(--spacing-sm);
  }
  
  .ltc-product-name {
    font-size: 1rem;
  }
  
  .ltc-btn {
    padding: 0.5rem 0.8rem;
    font-size: 0.85rem;
  }
  
  .ltc-status-indicator {
    font-size: 0.8rem;
    padding: 0.25rem 0.6rem;
  }
  
  .ltc-date-display {
    font-size: 0.75rem;
    padding: 0.25rem 0.6rem;
  }
  
  .ltc-result-header {
    font-size: 0.875rem;
  }
  
  .ltc-result-label,
  .ltc-result-value {
    font-size: 0.8rem;
  }
  
  .ltc-result-item {
    padding: 0.4rem 0.6rem;
  }
}

/* iPhone-Specific Tweaks */
@media screen and (max-width: 390px) {
  .ltc-status-banner {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
    padding-bottom: var(--spacing-sm);
  }
  
  .ltc-date-display {
    align-self: flex-end;
    margin-top: -1.5rem;
  }
  
  .ltc-info-row .ltc-icon {
    font-size: 1rem;
  }
  
  .ltc-results-grid {
    gap: var(--spacing-sm);
  }
  
  .ltc-result-card {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .ltc-btn {
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem;
  }
}

/* Clean Card (Alternative Design Option) */
.clean-card-layout .ltc-card {
  border-radius: var(--border-radius-lg);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  border: none;
}

.clean-card-layout .ltc-status-banner {
  border-bottom: 1px solid var(--border-color);
}

.clean-card-layout .ltc-status-banner.ltc-status-pass {
  background-color: white;
  border-left: 4px solid var(--success-color);
}

.clean-card-layout .ltc-status-banner.ltc-status-attention {
  background-color: white;
  border-left: 4px solid var(--warning-color);
}

.clean-card-layout .ltc-result-card {
  background-color: white;
  border: 1px solid var(--border-color);
}

.clean-card-layout .ltc-result-item {
  background-color: var(--background-subtle);
}

/* Add this to your existing LabTestCard.css */

.ltc-test-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ltc-test-title {
    font-size: 1rem;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .ltc-test-title {
    font-size: 0.9rem;
  }
}