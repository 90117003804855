/* VendorSignup.css */

.kwt-vendor-signup-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    color: #374151;
    font-family: Arial, sans-serif;
  }
  
  .kwt-vendor-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .kwt-vendor-card-header {
    background: linear-gradient(to right, #1b2f1b, #3b7e3b);
    padding: 1.5rem 2rem;
    color: white;
  }
  
  .kwt-vendor-card-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .kwt-vendor-card-description {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .kwt-vendor-card-content {
    padding: 2rem;
  }
  
  /* Step indicator styles */
  .kwt-stepper {
    margin-bottom: 2rem;
  }
  
  .kwt-step {
    position: relative;
    padding-bottom: 2rem;
  }
  
  .kwt-step:last-child {
    padding-bottom: 0;
  }
  
  .kwt-step::before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 2.5rem;
    bottom: 0;
    width: 2px;
    background-color: #e5e7eb;
  }
  
  .kwt-step:last-child::before {
    display: none;
  }
  
  .kwt-step.active::before {
    background-color: #3b7e3b;
  }
  
  .kwt-step-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .kwt-step-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: #6b7280;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
  
  .kwt-step.active .kwt-step-circle {
    background-color: #3b7e3b;
    color: white;
  }
  
  .kwt-step.completed .kwt-step-circle {
    background-color: #3b7e3b;
    color: white;
  }
  
  .kwt-step-label {
    flex: 1;
  }
  
  .kwt-step-title {
    font-weight: 600;
    color: #1f2937;
  }
  
  .kwt-step.active .kwt-step-title {
    color: #3b7e3b;
  }
  
  .kwt-step-description {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .kwt-step-content {
    margin-left: 3rem;
    padding-bottom: 1rem;
  }
  
  .kwt-form-group {
    margin-bottom: 1.5rem;
  }
  
  .kwt-form-label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #4b5563;
  }
  
  .kwt-form-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 0.875rem;
    transition: border-color 0.2s ease;
  }
  
  .kwt-form-input:focus {
    outline: none;
    border-color: #3b7e3b;
    box-shadow: 0 0 0 3px rgba(59, 126, 59, 0.1);
  }
  
  .kwt-form-input.error {
    border-color: #ef4444;
  }
  
  .kwt-form-error {
    color: #ef4444;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .kwt-checkbox-group {
    display: flex;
    align-items: flex-start;
  }
  
  .kwt-checkbox {
    margin-right: 0.5rem;
    margin-top: 0.25rem; /* Align with first line of text */
  }
  
  .kwt-checkbox-label {
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .kwt-summary-section {
    background-color: #f9fafb;
    border-radius: 0.375rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .kwt-summary-row {
    display: flex;
    border-bottom: 1px solid #e5e7eb;
    padding: 0.75rem 0;
  }
  
  .kwt-summary-row:last-child {
    border-bottom: none;
  }
  
  .kwt-summary-label {
    font-weight: 500;
    color: #6b7280;
    width: 40%;
  }
  
  .kwt-summary-value {
    width: 60%;
  }
  
  .kwt-terms-container {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .kwt-terms-link {
    color: #4076f6;
    text-decoration: none;
  }
  
  .kwt-terms-link:hover {
    text-decoration: underline;
  }
  
  .kwt-confirmation-container {
    text-align: center;
    padding: 2rem 1rem;
  }
  
  .kwt-confirmation-icon {
    width: 3rem;
    height: 3rem;
    background-color: #d1fae5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1.5rem;
  }
  
  .kwt-confirmation-icon.warning {
    background-color: #fef3c7;
  }
  
  .kwt-confirmation-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  
  .kwt-next-steps {
    background-color: #eff6ff;
    border-radius: 6px;
    padding: 1.25rem;
    margin: 1.5rem 0;
    text-align: left;
  }
  
  .kwt-next-steps-title {
    font-weight: 600;
    color: #1e40af;
    margin-bottom: 0.75rem;
  }
  
  .kwt-next-steps-list {
    padding-left: 1rem;
    color: #1e3a8a;
  }
  
  .kwt-next-steps-list li {
    margin-bottom: 0.5rem;
  }
  
  .kwt-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  .kwt-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
  }
  
  .kwt-button-primary {
    background-color: #3b7e3b;
    color: white;
  }
  
  .kwt-button-primary:hover {
    background-color: #2d6a2d;
  }
  
  .kwt-button-primary:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  .kwt-button-secondary {
    background-color: #e5e7eb;
    color: #4b5563;
  }
  
  .kwt-button-secondary:hover {
    background-color: #d1d5db;
  }
  
  .kwt-spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 0.5rem;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .kwt-alert {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-start;
  }
  
  .kwt-alert-success {
    background-color: #ecfdf5;
    border: 1px solid #a7f3d0;
    color: #065f46;
  }
  
  .kwt-alert-error {
    background-color: #fef2f2;
    border: 1px solid #fecaca;
    color: #b91c1c;
  }
  
  .kwt-alert-icon {
    margin-right: 0.75rem;
    flex-shrink: 0;
    margin-top: 0.125rem;
  }
  
  .kwt-alert-content {
    flex: 1;
  }
  
  .kwt-alert-title {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .kwt-button-container {
      flex-direction: column;
      gap: 0.75rem;
    }
    
    .kwt-summary-row {
      flex-direction: column;
    }
    
    .kwt-summary-label, .kwt-summary-value {
      width: 100%;
    }
    
    .kwt-summary-label {
      margin-bottom: 0.25rem;
    }
  }