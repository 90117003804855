/* TestResultsContent.css */
:root {
    --primary-green: #1b2f1b;
    --secondary-green: #4a7140;
    --success-color: #047857;
    --warning-color: #b45309;
    --fail-color: #dc2626;
    --neutral-color: #2563eb;
    --border-color: #e5e7eb;
    --background-light: #f9fafb;
  }
  
  /* .lab-tests-container {
    padding: 1.5rem 0;
  } */
  
  .lab-test-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .lab-test-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Test Status Banner */
  .test-status-banner {
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
  }
  
  .test-status-banner[data-status="pass"] {
    background-color: #ecfdf5;
    color: var(--success-color);
  }
  
  .test-status-banner[data-status="attention"] {
    background-color: #fff7ed;
    color: var(--warning-color);
  }
  
  /* Test Header */
  .test-header {
    border-bottom: 1px solid var(--border-color);
  }
  
  .test-meta {
    padding: 1.25rem 1.5rem;
  }
  
  .product-info {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
  
  .product-info h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
    color: var(--primary-green);
  }
  
  .product-info span {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .test-date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }

  /* Test Content */
  .test-content {
    padding: 1.5rem;
  }
  
  .test-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .metric {
    background: var(--background-light);
    padding: 1.25rem;
    border-radius: 8px;
  }
  
  .metric-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--primary-green);
  }
  
  .metric-values {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .value-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
  }
  
  .value-item span:first-child {
    color: #6b7280;
  }
  
  .value-item .pass {
    color: var(--success-color);
    font-weight: 600;
  }
  
  .value-item .fail {
    color: var(--fail-color);
    font-weight: 600;
  }
  
  .value-item .neutral {
    color: var(--neutral-color);
    font-weight: 600;
  }
  
  /* Icons */
  .icon {
    font-size: 1.25rem !important;
    color: var(--secondary-green);
  }

  .test-date .icon {
    margin: 0;  
  }

  /* View Details Link */
  .view-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: var(--background-light);
    color: var(--secondary-green);
    text-decoration: none;
    border-radius: 6px;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .view-details:hover {
    background-color: #f3f4f6;
    color: var(--primary-green);
    text-decoration: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .test-metrics {
      grid-template-columns: 1fr;
    }
    
    .product-info h3 {
      font-size: 1rem;
    }
    
    .test-meta {
      padding: 1rem;
    }
    
    .test-content {
      padding: 1rem;
    }
  }

  .no-lab-tests {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    text-align: center;
    padding: 2rem 1rem;
    color: #6b7280; /* A neutral gray */
  }
  
  .no-lab-tests-icon {
    font-size: 3rem !important; /* Make the icon larger */
    color: #9ca3af; /* Slightly darker gray */
    margin-bottom: 1rem;
  }
  
  .no-lab-tests h3 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #374151; /* Dark gray */
  }
  
  .no-lab-tests-text {
    font-size: 0.9375rem; /* 15px approx */
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    .no-lab-tests {
      padding: 1.5rem;
    }
    .no-lab-tests h3 {
      font-size: 1.25rem;
    }
  }
  