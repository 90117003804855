/* CustomTabs.css */

.custom-tabs {
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ddd;
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box; /* Include padding and border in element's total width */
  overflow-x: auto;
}

.tab-item {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  width: 8.9rem;
  justify-content: center;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #444;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

.tab-item:not(.active):hover {
  background-color: #e9ecef;
}

.tab-item.active {
  background-color: #ffffff;
  border-bottom: 3px solid #43c529;
  color: #43c529;
}

.tab-icon {
  font-size: 1.5rem; /* Adjust icon size as needed */
  color: #555;
}

.tab-label {
  font-size: 0.9rem; /* Adjust label font size */
  font-weight: 600;
  margin-top: 5px;
  position: relative; /* To position the count correctly */
}

.tab-count {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ddd; /* Revert to original gray background */
  color: #333; /* Darker text color */
  font-size: 0.75rem;
  font-weight: 600;
  padding: 4px; /* Use padding instead of border-radius for square shape */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0; /* Remove border-radius to keep it a square */
}


.tab-count:empty {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tab-item {
    padding: 10px 5px;
  }

  .tab-icon {
    font-size: 1.3rem;
  }

  .tab-label {
    font-size: 0.8rem;
  }

}
