.community-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .community-layout {
    max-width: 1200px;
    width: 100%;
    padding: 0 16px 24px 16px;
    margin: 0 auto;
  }
  
  .community-layout {
    display: flex;
    gap: 24px;
  }
  
  .community-sidebar {
    flex: 0 0 280px;
  }
  
  .community-main {
    flex: 1;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .community-layout {
      flex-direction: column;
    }
    
    .community-sidebar {
      width: 100%;
      flex: none;
      order: 2; /* Move sidebar below main content on mobile */
    }
    
    .community-main {
      order: 1;
    }
  }
  
  /* Custom scrollbar for the sidebar */
  .community-sidebar::-webkit-scrollbar {
    width: 6px;
  }
  
  .community-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .community-sidebar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  
  .community-sidebar::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
  
  /* Page background */
  .community-page {
    background-color: #f9fafb;
    min-height: calc(100vh - 64px); /* Adjust based on your header height */
  }
  
  /* Sticky sidebar for desktop */
  @media (min-width: 769px) {
    .community-sidebar {
      position: sticky;
      top: 24px;
      height: calc(100vh - 48px);
      overflow-y: auto;
    }
  }
  
  /* Animation for loading states */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .community-main > * {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Error state styling */
  .community-error {
    background-color: #fee2e2;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    color: #b91c1c;
    text-align: center;
  }
  
  /* Empty state styling */
  .community-empty {
    background-color: white;
    border-radius: 8px;
    padding: 48px 24px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .community-empty p {
    color: #6b7280;
    margin-bottom: 16px;
  }
  
  /* Smooth transitions between pages */
  .community-main {
    transition: opacity 0.2s ease-in-out;
  }