/* KratomHub Review Component CSS - Enhanced version */

/* SCOPED under .krReview to avoid clashes */
.krReview .review-card {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(59, 126, 59, 0.1);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
  transition: all 0.3s ease;
  overflow: hidden;
}

.krReview .review-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.12);
}

.krReview .review-card .card-body {
  padding: 24px;
}

/* HEADER */
.krReview .review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.krReview .user-info-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.krReview .avatar-container {
  flex-shrink: 0;
  position: relative;
}

.krReview .user-avatar,
.krReview .default-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid #f5f5dc;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.krReview .default-avatar {
  background: linear-gradient(135deg, #3b7e3b 0%, #65a765 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.krReview .user-info {
  display: flex;
  flex-direction: column;
}

.krReview .username {
  font-size: 18px;
  font-weight: 700;
  color: #2c5e2c;
  margin: 0;
  margin-bottom: 4px;
  position: relative;
  display: inline-block;
}

.krReview .review-date {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
}

/* EXPERIENCE BADGE + DELETE */
.krReview .header-right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.krReview .experience-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.krReview .experience-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.krReview .experience-badge svg {
  width: 16px;
  height: 16px;
}

.krReview .experience-badge.very-positive {
  background: linear-gradient(135deg, #0d9488 0%, #10b981 100%);
}

.krReview .experience-badge.positive {
  background: linear-gradient(135deg, #16a34a 0%, #34d399 100%);
}

.krReview .experience-badge.neutral {
  background: linear-gradient(135deg, #4b5563 0%, #6b7280 100%);
}

.krReview .experience-badge.negative {
  background: linear-gradient(135deg, #dc2626 0%, #ef4444 100%);
}

.krReview .experience-badge.very-negative {
  background: linear-gradient(135deg, #b91c1c 0%, #dc2626 100%);
}

.krReview .delete-button,
.krReview .edit-button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.krReview .edit-button {
  color: #3b7e3b;
}

.krReview .edit-button:hover {
  background-color: rgba(59, 126, 59, 0.1);
  transform: translateY(-2px);
}

.krReview .delete-button {
  color: #dc2626;
}

.krReview .delete-button:hover {
  background-color: rgba(220, 38, 38, 0.1);
  transform: translateY(-2px);
}

/* PRODUCT REVIEWED */
.krReview .product-info-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: rgba(245, 245, 220, 0.3);
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid rgba(59, 126, 59, 0.1);
}

.krReview .product-info-container h6 {
  margin: 0;
  color: #2c5e2c;
  font-weight: 600;
  font-size: 16px;
}

.krReview .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 16px;
  row-gap: 12px;
  padding: 0;
  margin: 0;
}

.krReview .product-info-container .product-item {
  list-style: none;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.krReview .product-info-container .product-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.krReview .product-info-container .kr-bullet {
  height: 18px;
  width: auto;
  margin-right: 10px;
  color: #3b7e3b;
  flex-shrink: 0;
}

.krReview .product-info-container .product-item span {
  font-size: 14px;
  color: #444;
  line-height: 1.4;
  font-weight: 500;
}

/* REVIEW TEXT */
.krReview .review-content {
  margin: 24px 0;
  position: relative;
  padding: 0 0 0 28px;
}

.krReview .quote-icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #3b7e3b;
  font-size: 20px;
  opacity: 0.6;
}

.krReview .review-text {
  color: #333;
  font-size: 16px;
  line-height: 1.7;
  margin: 0;
  white-space: pre-wrap;
}

.krReview .btn-read-more {
  color: #3b7e3b;
  font-weight: 600;
  padding: 0;
  margin-top: 10px;
  align-self: flex-end;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.krReview .btn-read-more:hover {
  color: #2c5e2c;
  text-decoration: none;
}

.krReview .btn-read-more::after {
  content: '';
  display: inline-block;
  margin-left: 6px;
  width: 6px;
  height: 6px;
  border-right: 2px solid #3b7e3b;
  border-bottom: 2px solid #3b7e3b;
  transform: rotate(45deg);
  position: relative;
  top: -2px;
  transition: all 0.3s ease;
}

.krReview .btn-read-more:hover::after {
  margin-left: 8px;
}

/* REVIEW PHOTOS */
.krReview .review-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px 0;
}

.krReview .review-photo-thumbnail {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #f5f5dc;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.krReview .review-photo-thumbnail:hover {
  transform: scale(1.05);
  border-color: #3b7e3b;
}

/* Fullscreen (Modal) Image */
.krReview .review-photo-full {
  max-width: 100%;
  max-height: 85vh;
  border-radius: 10px;
  object-fit: contain;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

/* FEEDBACK SECTIONS */
.krReview .feedback-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  background: linear-gradient(135deg, rgba(59, 126, 59, 0.05) 0%, rgba(59, 126, 59, 0.1) 100%);
  border-radius: 10px;
  padding: 24px;
  margin: 24px 0;
}

.krReview .feedback-title {
  font-size: 14px;
  font-weight: 700;
  color: #2c5e2c;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  display: inline-block;
}

.krReview .feedback-title::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #3b7e3b;
  border-radius: 1.5px;
}

.krReview .feedback-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.krReview .feedback-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 14px;
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.krReview .feedback-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.krReview .feedback-item:last-child {
  margin-bottom: 0;
}

.krReview .feedback-icon {
  flex-shrink: 0;
  font-size: 16px;
  width: 18px !important;
  height: 18px !important;
}

.krReview .feedback-icon.positive {
  color: #10b981;
}

.krReview .feedback-icon.negative {
  color: #ef4444;
}

.krReview .feedback-item span {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}

/* VENDOR RESPONSE */
.krReview .vendor-response {
  margin: 24px 0 16px;
  padding: 20px;
  background-color: #f0fdf4;
  border-radius: 10px;
  border-left: 6px solid #3b7e3b;
  box-shadow: 0 4px 12px rgba(59, 126, 59, 0.1);
  position: relative;
}

.krReview .vendor-response::before {
  content: '"';
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 60px;
  color: rgba(59, 126, 59, 0.1);
  font-family: Georgia, serif;
  line-height: 1;
}

.krReview .vendor-response h6 {
  font-size: 16px;
  font-weight: 700;
  color: #2c5e2c;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.krReview .vendor-response h6::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #3b7e3b;
  border-radius: 50%;
}

.krReview .vendor-response p {
  font-size: 15px;
  color: #333;
  margin: 0;
  line-height: 1.6;
}

.krReview .btn-vendor-toggle {
  color: #3b7e3b;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  margin-top: 12px;
}

.vendor-reply-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  resize: vertical;
  min-height: 120px;
  font-size: 15px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.vendor-reply-textarea:focus {
  outline: none;
  border-color: #3b7e3b;
  box-shadow: 0 0 0 3px rgba(59, 126, 59, 0.2);
}

/* REVIEW ACTIONS (Useful/Edit/Delete) */
.krReview .review-actions {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  justify-content: space-between;
  border-top: 1px solid rgba(59, 126, 59, 0.1);
  padding-top: 16px;
}

.krReview .action-button {
  background: none;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.krReview .action-button:hover {
  color: #3b7e3b;
  background-color: rgba(59, 126, 59, 0.1);
}

.krReview .action-button svg {
  font-size: 16px;
}

.krReview .action-button.active {
  color: #3b7e3b;
  font-weight: 600;
  background-color: rgba(59, 126, 59, 0.1);
}

/* RESPONSIVE STYLES */
@media (max-width: 992px) {
  .krReview .review-card .card-body {
    padding: 20px;
  }
  
  .krReview .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (max-width: 768px) {
  .krReview .review-card .card-body {
    padding: 16px;
  }
  
  .krReview .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .krReview .feedback-container {
    padding: 16px;
    gap: 16px;
  }
  
  .krReview .product-info-container .product-item {
    white-space: normal;
  }
  
  .krReview .user-avatar,
  .krReview .default-avatar {
    width: 48px;
    height: 48px;
  }
  
  .krReview .username {
    font-size: 16px;
  }
  
  .krReview .review-text {
    font-size: 15px;
  }
}

/* MOBILE LAYOUT */
@media (max-width: 576px) {
  .krReview .review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .krReview .experience-badge {
    margin-left: auto;
  }
  
  .krReview .feedback-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .krReview .review-photo-thumbnail {
    width: 70px;
    height: 70px;
  }
  
  .krReview .product-grid {
    grid-template-columns: 1fr;
  }
  
  .krReview .review-actions {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .krReview .review-card .card-body {
    padding: 16px;
  }
  
  .krReview .product-info-container {
    padding: 12px;
  }
  
  .krReview .review-content {
    padding-left: 20px;
  }
  
  .krReview .quote-icon {
    font-size: 16px;
  }
  
  .krReview .review-text {
    font-size: 14px;
  }
  
  .krReview .review-photo-thumbnail {
    width: 60px;
    height: 60px;
  }
  
  .krReview .user-avatar,
  .krReview .default-avatar {
    width: 42px;
    height: 42px;
    border-width: 2px;
  }
  
  .krReview .default-avatar {
    font-size: 18px;
  }
  
  .krReview .action-button {
    padding: 6px 10px;
    font-size: 13px;
  }
  
  .krReview .action-button svg {
    font-size: 14px;
  }
}

/* ANIMATIONS */
@keyframes subtle-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.krReview .experience-badge svg,
.krReview .feedback-icon {
  animation: subtle-bounce 3s ease-in-out infinite;
}