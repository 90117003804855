.cf-comment-form-container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.cf-comment-form-container.cf-reply-form {
  margin-top: 12px;
  margin-bottom: 12px;
}

.cf-comment-form-header {
  display: flex;
  margin-bottom: 12px;
}

.cf-user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.cf-comment-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cf-comment-input-container {
  width: 100%;
}

/* RichTextEditor custom styling */
.cf-comment-input-container .custom-quill-editor {
  border-radius: 6px;
  border-color: #e5e7eb;
}

.cf-comment-input-container .custom-quill-editor.error .ql-toolbar,
.cf-comment-input-container .custom-quill-editor.error .ql-container {
  border-color: #ef4444;
}

/* Make the editor more compact for replies */
.cf-reply-form .custom-quill-editor .ql-toolbar {
  padding: 6px;
}

.cf-reply-form .custom-quill-editor .ql-container {
  min-height: 100px;
}

/* Standard form styling */
.cf-comment-input-container .custom-quill-editor .ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #f9fafb;
}

.cf-comment-input-container .custom-quill-editor .ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 150px;
}

.cf-error-message {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 6px;
}

.cf-comment-form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.cf-login-message {
  font-size: 0.875rem;
  color: #6b7280;
}

.cf-login-message a {
  color: #4076f6;
  text-decoration: none;
}

.cf-login-message a:hover {
  text-decoration: underline;
}

.cf-submit-button {
  background-color: #4076f6;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cf-submit-button:hover {
  background-color: #2c5cd1;
}

.cf-submit-button:disabled {
  background-color: #9fb5eb;
  cursor: not-allowed;
}

/* Sign-in required component styling */
.cf-signin-required {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 24px;
  margin: 24px 0;
  text-align: center;
}

.cf-signin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 500px;
  margin: 0 auto;
}

.cf-signin-content svg {
  color: #4076f6;
}

.cf-signin-content h3 {
  font-size: 1.2rem;
  color: #1b2f1b;
  margin: 0;
}

.cf-signin-content p {
  color: #6b7280;
  margin: 0 0 8px 0;
  font-size: 0.95rem;
  line-height: 1.5;
}

.cf-signin-buttons {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.cf-signin-button, 
.cf-signup-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.cf-signin-button {
  background-color: #4076f6;
  color: white;
}

.cf-signin-button:hover {
  background-color: #2c5cd1;
}

.cf-signup-button {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #4b5563;
}

.cf-signup-button:hover {
  background-color: #f9fafb;
}

@media (max-width: 640px) {
  .cf-comment-form-actions {
    flex-direction: column;
    align-items: stretch;
  }
  
  .cf-login-message {
    order: 2;
    text-align: center;
    margin-top: 8px;
  }
  
  .cf-submit-button {
    order: 1;
    width: 100%;
  }

  .cf-signin-buttons {
    flex-direction: column;
    width: 100%;
  }

  .cf-signin-button, 
  .cf-signup-button {
    width: 100%;
    text-align: center;
  }
}