.category-list-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 100%;
  max-width: 280px;
}

.category-list-header {
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.category-list-header h3 {
  color: #1b2f1b;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.category-navigation {
  margin-bottom: 24px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 500px; /* Arbitrary large height */
  opacity: 1;
  overflow: hidden;
}

.category-navigation.collapsed {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
}

.navigation-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-item {
  margin-bottom: 4px;
}

.navigation-link {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 6px;
  text-decoration: none;
  color: #4b5563;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: 0.875rem;
}

.navigation-link:hover {
  background-color: #f9fafb;
  color: #1b2f1b;
}

.navigation-link.active {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
  font-weight: 500;
}

.navigation-link svg {
  color: #6b7280;
}

.navigation-link.active svg {
  color: #3b7e3b;
}

.category-count {
  margin-left: auto;
  background-color: #e5e7eb;
  color: #4b5563;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 12px;
  min-width: 24px;
  text-align: center;
}

.navigation-link.active .category-count {
  background-color: rgba(59, 126, 59, 0.2);
  color: #3b7e3b;
}

/* Collapsible header styles */
.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.collapse-icon {
  color: #6b7280;
  display: flex;
  align-items: center;
}

/* Horizontal Categories (Mobile only) */
.horizontal-categories {
  display: none;
  white-space: nowrap;
  overflow-x: auto;
  padding: 12px 16px 12px 12px;
  background-color: white;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.horizontal-categories::-webkit-scrollbar {
  display: none;
}

.category-pill {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: #f3f4f6;
  color: #4b5563;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.category-pill svg {
  margin-right: 6px;
  font-size: 16px;
  color: #6b7280;
}

.category-pill.active {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
}

.category-pill.active svg {
  color: #3b7e3b;
}

/* Floating Category Button */
.floating-categories-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #3b7e3b;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.floating-categories-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.floating-categories-menu {
  display: none;
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 220px;
  max-height: 60vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 99;
  padding: 8px 0;
}

.floating-categories-menu.open {
  display: block;
  animation: fadeInUp 0.3s ease;
}

.floating-category-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  color: #4b5563;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.floating-category-item:hover {
  background-color: #f3f4f6;
}

.floating-category-item.active {
  color: #3b7e3b;
  font-weight: 500;
}

.floating-category-item svg {
  color: #6b7280;
}

.floating-category-item.active svg {
  color: #3b7e3b;
}

.floating-category-divider {
  height: 1px;
  background-color: #e5e7eb;
  margin: 4px 16px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .category-list-container {
    max-width: 100%;
    margin-bottom: 16px;
  }
   
  .navigation-link {
    padding: 10px 12px;
  }

  /* Show horizontal categories on mobile */
  .horizontal-categories {
    display: flex;
  }

  /* Show floating button on mobile */
  .floating-categories-button {
    display: flex;
  }

  /* Add specific collapsible styles for mobile */
  .collapsible-header {
    padding: 4px 0;
  }
}

/* Improved Horizontal Categories for Mobile */
.horizontal-categories {
  display: none;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 12px 16px;
  background-color: white;
  margin: 0 16px 16px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-snap-type: x proximity; /* Add scroll snapping */
  position: relative; /* For the fade effect */
  max-width: calc(100% - 32px);
}

.horizontal-categories::-webkit-scrollbar {
  display: none;
}

/* Add fade effect on the right side to indicate more content */
.horizontal-categories::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30px;
  background: linear-gradient(to right, transparent, white);
  pointer-events: none;
}

.category-pill {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: #f3f4f6;
  color: #4b5563;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
  scroll-snap-align: start; /* Enable scroll snapping to the start of each pill */
  min-width: fit-content;
  flex-shrink: 0;
}

.category-pill:last-child {
  margin-right: 30px; /* Add extra padding on the right to account for the fade */
}

.category-pill svg {
  margin-right: 6px;
  font-size: 16px;
  color: #6b7280;
}

.category-pill.active {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
}

.category-pill.active svg {
  color: #3b7e3b;
}

/* Show horizontal categories only on mobile */
@media (max-width: 768px) {
  .horizontal-categories {
    display: flex;
  }
}