/* LabTestPage.css */
:root {
  --primary-green: #1b2f1b;
  --secondary-green: #4a7140;
  --trust-blue: #2c5282;
  --verified-green: #276749;
  --warning-red: #c53030;
  --cream-bg: #f5f5dc;
  --text-dark: #2d3748;
  --border-color: #e2e8f0;
}

/* Header Section */
.lab-test-header {
  background-color: var(--primary-green);
  padding: 2.5rem 0;
  border-bottom: 4px solid var(--secondary-green);
  margin-bottom: 2rem;
}

.lab-test-header h1 {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.lab-test-subtitle {
  color: var(--cream-bg);
  text-align: center;
  font-size: 1.1rem;
}

.test-date {
  text-align: center;
}

/* Test Info Wrapper */
.test-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: white;
}

.test-header-main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.test-identity {
  text-align: center;
}

.test-identity h1 {
  color: white;
  font-size: 2.75rem;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}

.vendor-attribution {
  margin-top: 0.75rem;
  font-size: 1.2rem;
  color: var(--cream-bg);
}

.vendor-link {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.vendor-link:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.test-metadata {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.metadata-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.metadata-label {
  color: var(--cream-bg);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.metadata-value {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
}

.highlight {
  color: white;
  font-weight: 600;
}

.product-label-link {
  color: white;
  text-decoration: none;
  transition: opacity 0.2s ease;
  text-align: center;
}

.product-label-link:hover {
  opacity: 0.9;
  text-decoration: underline;
}

/* Product Vendor Info */
.product-vendor-info {
  text-align: center;
}

.product-vendor-info h1 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.vendor-name {
  margin-bottom: 0.5rem;
}

/* Tabs Section */
.custom-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

/* Update tab styling for the vendor lab test tab */
.custom-tabs button:nth-child(2) {
  color: var(--primary-green);
}

/* Content Card */
.content-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
  height: auto;
  overflow: visible;
}

/* PDF Container and Viewers */
.pdf-container {
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 2rem;
  height: auto;
  overflow: visible;
}

/* PDF Viewer */
.pdf-viewer {
  flex: 1;
  min-width: 0; 
  border-radius: 8px;
  padding: 1.5rem;
  height: auto;
  overflow: visible;
  border: none;
}


.pdf-viewer .react-pdf__Document {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdf-viewer .react-pdf__Page {
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.pdf-loading {
  text-align: center;
  font-size: 1.2rem;
  padding: 2rem;
}

.pdf-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.pdf-nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-green);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.pdf-nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pdf-controls span {
  font-weight: bold;
}

.pdf-actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.text-to-sign-in {
  margin: 1rem;
}

.sing-in-btn-link{
  padding: 0;
  border-width: 0;
  text-underline-offset:0.25rem;
}

.no-vendor-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  color: var(--text-dark);
  font-style: italic;
}

/* Lab Details Section */
.lab-details-section {
  margin-bottom: 2rem;
}

.lab-info-section {
  background: #f7fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.section-title {
  color: var(--text-dark);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--border-color);
}

.full-width-comparison {
  grid-column: 1 / -1;
  width: 100%;
}

/* Test Results Grid */
.test-results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.result-card {
  background: #f7fafc;
  border-radius: 8px;
  padding: 1.5rem;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.result-card:not(.comparison-card):hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.result-card h4 {
  color: var(--text-dark);
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.result-card.vendor-product-card {
  /* Force this card to stretch across all columns: */
  grid-column: 1 / -1;
  /* Same base background as other "analysis" cards */
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.result-card.vendor-product-card h3 {
  margin-bottom: 1.25rem; /* slightly more space under the heading */
  font-size: 1.2rem;      /* a bit larger than the other card headings */
}

.result-card.vendor-product-card p {
  margin-bottom: 1rem;
}

.result-card.disclaimer-card {
  grid-column: 1 / -1;          
  background: #e9ecef; /* a slightly different light gray background */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.result-card.disclaimer-card h4 {
  margin-bottom: 1rem;
}

.result-card.disclaimer-card .disclaimer-text {
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 0.85rem;
  color: var(--text-dark);
}

.result-card.vendor-product-card p,
.result-card.disclaimer-card p {
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
  color: var(--text-dark);
}

/* Test Result Items */
.test-result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

.test-result-item:last-child {
  border-bottom: none;
}

.result-label {
  color: var(--text-dark);
  font-weight: 500;
}

.result-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.result-value.pass {
  color: var(--verified-green);
}

.result-value.fail {
  color: var(--warning-red);
}

/* Make pass/fail icons a bit larger for emphasis */
.test-result-item .result-value svg {
  font-size: 1.25rem;
}

/* Links Section */
.links-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.link-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  transition: all 0.2s;
  font-weight: 500;
}

.link-button.primary {
  background-color: var(--primary-green);
  color: white;
}

.link-button.primary:hover {
  background-color: var(--secondary-green);
}

.link-button.secondary {
  background-color: white;
  color: var(--primary-green);
  border: 2px solid var(--primary-green);
}

.link-button.secondary:hover {
  background-color: #f0fff4;
}

.header-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.header-link {
  color: var(--cream-bg);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid var(--cream-bg);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.header-link:hover {
  background-color: var(--cream-bg);
  color: var(--primary-green);
}

.product-link {
  display: inline-block;
  color: var(--cream-bg);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid var(--cream-bg);
  border-radius: 4px;
  margin-top: 1rem;
  transition: all 0.2s ease;
}

.product-link:hover {
  background-color: var(--cream-bg);
  color: var(--primary-green);
}

.product-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.separator {
  color: var(--cream-bg);
}

/* Lab Verification Info */
.lab-verification-info {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.lab-verification-info p {
  color: var(--cream-bg);
  margin: 0;
  font-size: 1rem;
}

.lab-name {
  font-weight: 500;
}

/* Comments Section */
.comments-section {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: visible;
}

/* Product Images Grid */
.product-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.product-image-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
}

.product-image-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.product-image-card img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  border-radius: 4px;
  transition: opacity 0.2s ease;
}

.product-image-card img:hover {
  opacity: 0.9;
}

/* Modal styling for product images */
.modal-header {
  border-bottom: none;
  padding: 0.75rem 1rem;
}

.modal-header .close {
  padding: 0.75rem;
  margin: -0.75rem -0.75rem -0.75rem auto;
}

.modal-body.text-center {
  padding: 1.5rem;
}

.modal-body.text-center img {
  max-height: 80vh;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.product-image-title {
  text-align: center;
  margin-top: 1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.no-images-message {
  text-align: center;
  padding: 2rem;
  color: var(--text-dark);
  font-style: italic;
}

/* Breadcrumbs */
.breadcrumbs {
  background-color: #f8f9fa;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e9ecef;
}

.breadcrumbs ol {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.breadcrumbs li {
  display: flex;
  align-items: center;
  color: var(--text-dark);
  font-size: 0.9rem;
}

.breadcrumbs a {
  color: var(--primary-green);
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumb-separator {
  font-size: 0.75rem;
  color: #6c757d;
}

/* Comparison Card Styles */
.comparison-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.comparison-card:hover {
  transform: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Card Header */
.card-header-comparison {
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border-color);
}

.header-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title-row h4 {
  margin-bottom: 0;
  flex: 1;
}

.result-headers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 270px;
  gap: 0.5rem;
}

.result-header {
  padding: 0.3rem 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 4px;
  margin: 0 0.5rem;
}

.result-header.our-tests {
  background-color: var(--trust-blue);
  color: white;
}

.result-header.vendor-tests {
  background-color: var(--secondary-green);
  color: white;
}

.no-data-message {
  padding: 1.5rem;
  text-align: center;
  color: #6b7280;
  font-style: italic;
}

/* Horizontal comparison layout */
.horizontal-comparison-layout {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
}

/* Table rows */
.comparison-table-body {
  display: flex;
  flex-direction: column;
}

.comparison-table-row {
  display: grid;
  grid-template-columns: 1fr 240px;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.comparison-table-row:last-child {
  border-bottom: none;
}

.comparison-table-row:hover {
  background-color: #f8fafc;
}

/* Test name cell */
.test-name-cell {
  display: flex;
  align-items: center;
  font-weight: 500;
  flex: 1;
}

.test-name-cell .labtest-option-info-icon {
  margin-right: 0.75rem;
}

/* Result cells container */
.result-cells-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 250px;
}

/* Result cell */
.result-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
}

.result-cell .result-value {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.result-cell .result-value.pass {
  color: var(--verified-green);
}

.result-cell .result-value.fail {
  color: var(--warning-red);
}

/* Keep the styling for lab test info */
.lab-test-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.highlight-text {
  color: var(--trust-blue);
  font-weight: 600;
}

.labtest-option-info-icon {
  display: inline-flex;
  margin-right: 0.5rem;
  vertical-align: middle;
}


/* Responsive Design - All Media Queries Combined */
@media (max-width: 768px) {
  /* Header and General Layout */
  .lab-test-header {
    padding: 1.5rem 0;
  }
  
  .test-identity h1 {
    font-size: 2rem;
  }
  
  .product-vendor-info h1 {
    font-size: 2rem;
  }
  
  .test-metadata {
    gap: 1.5rem;
    align-items: center;
  }
  
  .metadata-item {
    text-align: center;
  }
  
  .vendor-attribution {
    font-size: 1.1rem;
  }
  
  /* Test Results */
  .test-results-grid {
    grid-template-columns: 1fr;
  }
   /* Mobile-friendly adjustments */
   .header-title-row {
    display: flex;
    flex-direction: column;
  }
  .header-title-row h4 {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  /* PDF Viewer */
  .pdf-viewer {
    padding: 1rem 0.5rem;
  }
  
  .pdf-controls {
    flex-direction: column;
  }
  
  .pdf-nav-button {
    width: 100%;
    justify-content: center;
  }
  
  .pdf-controls span {
    margin: 0.5rem 0;
  }
  
  .pdf-actions {
    flex-direction: column;
  }
  
  /* Links */
  .link-button {
    width: 100%;
    justify-content: center;
  }
  
  .header-links {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .separator {
    display: none;
  }
  
  /* Lab Verification */
  .lab-verification-info {
    flex-direction: column;
    gap: 0.75rem;
    text-align: center;
  }
  
  /* Breadcrumbs */
  .breadcrumbs {
    padding: 0.5rem 0;
  }
  
  .breadcrumbs ol {
    gap: 0.25rem;
  }
  
  .breadcrumbs li {
    font-size: 0.8rem;
  }
  
  .comparison-table-row {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
  .result-headers {
    width: 100%;
  }
  .test-name-cell {
    width: 100%;
    justify-content: center;
  }
  
  .result-cells-container {
    width: 100%;
    /* justify-content: space-around; */
  }
  
  .lab-test-info {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .result-headers {
    width: 100%;
    justify-content: space-around;
  }
  
  .result-header {
    flex: 1;
  }
  
  .test-name-cell {
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .test-name-cell .labtest-option-info-icon {
    margin-right: 0.5rem;
  }
  
  .result-cell {
    min-width: 0;
  }
  
  .result-cell:first-child .result-value {
    background-color: rgba(44, 82, 130, 0.15); /* light trust-blue background */
    border: 1px solid rgba(44, 82, 130, 0.2);
  }
  
  .result-cell:last-child .result-value {
    background-color: rgba(74, 113, 64, 0.15); /* light secondary-green background */
    border: 1px solid rgba(74, 113, 64, 0.2);
  }
  
  .result-cell .result-value {
    min-width: 60px;
    font-size: 0.9rem;
    padding: 0.25rem 0.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .comparison-table-row {
    padding: 1rem;
  }

}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.loading-content {
  text-align: center;
  padding: 2rem;
}

.loading-spinner {
  height: 3rem;
  width: 3rem;
  color: var(--primary-green) !important;
  margin-bottom: 1.5rem;
}

.loading-text {
  color: var(--primary-green);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.loading-subtext {
  color: var(--text-dark);
  font-size: 1rem;
  opacity: 0.8;
}