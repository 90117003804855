.thread-creation-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px 16px;
  }
  
  .thread-creation-header {
    margin-bottom: 24px;
  }
  
  .thread-creation-header h2 {
    color: #1b2f1b;
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
  
  .thread-creation-header p {
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  .thread-creation-form {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 24px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
    color: #1b2f1b;
  }
  
  .form-group input[type="text"],
  .thread-category-select {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: border-color 0.2s ease;
  }
  
  .form-group input[type="text"]:focus,
  .thread-category-select:focus {
    outline: none;
    border-color: #4076f6;
    box-shadow: 0 0 0 2px rgba(64, 118, 246, 0.2);
  }
  
  .form-group input[type="text"].error {
    border-color: #ef4444;
  }
  
  .selected-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 36px;
    padding: 4px 8px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  
  .selected-tag {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: rgba(59, 126, 59, 0.1);
    color: #3b7e3b;
    font-size: 0.75rem;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .selected-tag button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: #3b7e3b;
  }
  
  .custom-tag-form {
    margin: 0;
  }
  
  .custom-tag-form input {
    border: none;
    outline: none;
    padding: 4px 0;
    font-size: 0.875rem;
    width: 100px;
    min-width: 40px;
    flex-grow: 1;
  }
  
  .suggested-tag:hover {
    background-color: rgba(59, 126, 59, 0.1);
    color: #3b7e3b;
  }
  
  .error-message {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ef4444;
    font-size: 0.75rem;
    margin-top: 6px;
  }
  
  /* RichTextEditor custom styling */
  .form-group .custom-quill-editor {
    border-radius: 6px;
    border-color: #e5e7eb;
  }
  
  .form-group .custom-quill-editor.error .ql-toolbar,
  .form-group .custom-quill-editor.error .ql-container {
    border-color: #ef4444;
  }
  
  .form-group .custom-quill-editor .ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #f9fafb;
  }
  
  .form-group .custom-quill-editor .ql-container {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    min-height: 200px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancel-button {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .cancel-button:hover {
    background-color: #f9fafb;
  }
  
  .submit-button {
    background-color: #4076f6;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #2c5cd1;
  }
  
  .submit-button:disabled {
    background-color: #9fb5eb;
    cursor: not-allowed;
  }
  
  @media (max-width: 640px) {
    .thread-creation-container {
      padding: 16px 12px;
    }
    
    .thread-creation-form {
      padding: 16px;
    }
    
    .form-actions {
      flex-direction: column;
    }
    
    .cancel-button,
    .submit-button {
      width: 100%;
    }
  }s {
    margin-top: 8px;
  }
  
  .suggested-tags-title {
    font-size: 0.75rem;
    color: #6b7280;
    margin-right: 8px;
  }
  
  .tag-suggestions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
  
  .suggested-tag {
    background: none;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 0.75rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  /* Add these styles to your existing ThreadCreationForm.css file */

/* Sign-in required container */
.signin-required-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 32px 24px;
}

.signin-required-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.signin-icon-container {
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
}

.signin-icon-container svg {
  color: #4076f6;
}

.signin-icon-overlay {
  position: absolute;
  bottom: -5px;
  right: -10px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signin-required-content h3 {
  color: #1b2f1b;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 16px;
}

.signin-required-content p {
  color: #6b7280;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.signin-benefits {
  font-weight: 600;
  color: #1b2f1b;
  margin-bottom: 8px !important;
}

.signin-benefits-list {
  text-align: left;
  max-width: 300px;
  margin: 0 auto 24px;
  padding-left: 24px;
  color: #6b7280;
  font-size: 0.95rem;
  line-height: 1.6;
}

.signin-benefits-list li {
  margin-bottom: 6px;
}

.signin-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 20px;
}

.signin-button, 
.signup-button {
  padding: 10px 24px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s ease;
  min-width: 140px;
}

.signin-button {
  background-color: #4076f6;
  color: white;
}

.signin-button:hover {
  background-color: #2c5cd1;
}

.signup-button {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #4b5563;
}

.signup-button:hover {
  background-color: #f9fafb;
}

.signin-note {
  font-size: 0.85rem;
  font-style: italic;
  color: #6b7280;
  margin-bottom: 0 !important;
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .signin-required-container {
    padding: 24px 16px;
  }
  
  .signin-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .signin-button, 
  .signup-button {
    width: 100%;
    text-align: center;
  }
}
