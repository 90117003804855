.community-header {
    background-color: #1b2f1b;
    color: white;
    padding: 40px 24px;
    margin-bottom: 32px;
    width: 100%;
  }
  
  .community-header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }
  
  .community-header-text h1 {
    font-size: 2rem;
    margin: 0 0 8px 0;
    color: white;
  }
  
  .community-header-text p {
    font-size: 1rem;
    margin: 0;
    opacity: 0.9;
  }
  
  .community-header-actions {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .search-form {
    display: flex;
    gap: 8px;
  }
  
  .search-input-container {
    position: relative;
    width: 250px;
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #6b7280;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 12px 10px 40px;
    border: none;
    border-radius: 6px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .search-input:focus {
    outline: none;
    background-color: white;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
  
  .search-button {
    background-color: #3b7e3b;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .search-button:hover {
    background-color: #326e32;
  }
  
  .create-thread-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #4076f6;
    border: none;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 0.875rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
  }
  
  .create-thread-button:hover {
    background-color: #2c5cd1;
  }
  
  @media (max-width: 950px) {
    .community-header-content {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .community-header-actions {
      width: 100%;
    }
    
    .search-form {
      width: 100%;
      flex: 1;
    }
    
    .search-input-container {
      width: 100%;
    }
  }
  
  @media (max-width: 640px) {
    .community-header {
      padding: 24px 16px;
    }
    
    .community-header-text h1 {
      font-size: 1.5rem;
    }
    
    .community-header-actions {
      flex-direction: column;
      align-items: stretch;
    }
    
    .search-form {
      flex-direction: column;
      gap: 8px;
    }
    
    .create-thread-button {
      width: 100%;
      justify-content: center;
    }
  }