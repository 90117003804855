.thread-list-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .thread-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .thread-list-title {
    display: flex;
    flex-direction: column;
  }
  
  .thread-list-title h2 {
    color: #1b2f1b;
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 4px;
  }
  
  .thread-count {
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  .thread-list-actions {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .thread-filter-dropdown {
    position: relative;
  }
  
  .filter-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .filter-button:hover {
    background-color: #f9fafb;
  }
  
  .filter-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 160px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-top: 4px;
    z-index: 10;
    border: 1px solid #e5e7eb;
    overflow: hidden;
  }
  
  .filter-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 12px;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .filter-option:hover {
    background-color: #f9fafb;
  }
  
  .filter-option.active {
    background-color: rgba(64, 118, 246, 0.1);
    color: #4076f6;
  }
  
  .create-thread-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #4076f6;
    border: none;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 0.875rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .create-thread-button:hover {
    background-color: #2c5cd1;
  }
  
  .thread-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .thread-list-empty {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .thread-list-empty p {
    margin-bottom: 16px;
    color: #6b7280;
  }
  
  .thread-list-error {
    background-color: #fee2e2;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    color: #b91c1c;
  }
  
  .thread-list-loading,
  .thread-list-loading-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    color: #6b7280;
  }
  
  .loading-spinner {
    border: 3px solid rgba(59, 126, 59, 0.3);
    border-radius: 50%;
    border-top: 3px solid #3b7e3b;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 8px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .thread-list-footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  
  .load-more-button {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .load-more-button:hover {
    background-color: #f9fafb;
  }
  
  @media (max-width: 640px) {
    .thread-list-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .thread-list-actions {
      width: 100%;
      justify-content: space-between;
    }
  }