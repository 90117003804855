.requestTest-page {
  padding-bottom: 40px;
}

/* Full-width header with green background and white text */
.page-header {
  background-color: #1b2f1b;
  color: white;
  padding: 40px 0;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.page-title {
  margin: 0;
  padding: 0 20px;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.info-section,
.form-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  border-top: 4px solid #4CAF50;
}

.info-section h2,
.form-section h2 {
  margin-bottom: 20px;
  font-weight: 600;
  color: #2E7D32;
  font-size: 1.75rem;
}

.info-section h3 {
  font-size: 1.25rem;
  margin: 25px 0 15px;
  color: #1A3E1A;
}

.info-section p {
  line-height: 1.6;
  color: #333;
}

.info-section ol,
.info-section ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.info-section li {
  margin-bottom: 10px;
  line-height: 1.5;
}

/* Highlighted note box */
.note-box {
  background-color: #f8f9fa;
  border-left: 4px solid #4CAF50;
  padding: 15px 20px;
  margin: 20px 0;
  border-radius: 0 4px 4px 0;
}

.note-box p {
  margin-bottom: 10px;
}

.note-box ul {
  margin-bottom: 0;
}

/* Form styling */
.form-section label {
  font-weight: 500;
  color: #1A3E1A;
  margin-bottom: 8px;
}

.form-field {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 12px;
  transition: border-color 0.2s;
}

.form-field:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.add-product-btn {
  /* background-color: #2E7D32; */
  border-color: #2E7D32;
  transition: background-color 0.2s;
}

.add-product-btn:hover {
  /* background-color: #1A3E1A; */
  border-color: #1A3E1A;
}

.product-list {
  border-radius: 4px;
  overflow: hidden;
}

.product-item {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.product-link {
  color: #2E7D32;
  max-width: 85%;
}

.product-link:hover {
  color: #1A3E1A;
}

.remove-btn {
  color: #d32f2f;
  border-color: #d32f2f;
  font-size: 0.8rem;
  padding: 2px 8px;
}

.remove-btn:hover {
  background-color: #d32f2f;
  color: white;
}

.note-text {
  color: #666;
  font-style: italic;
  margin-top: 8px;
  display: block;
}

.submit-btn {
  background-color: #4CAF50;
  border-color: #4CAF50;
  font-weight: 600;
  padding: 12px 24px;
  transition: background-color 0.2s;
}

.submit-btn:hover {
  background-color: #2E7D32;
  border-color: #2E7D32;
}

.submit-btn:disabled {
  background-color: #A5D6A7;
  border-color: #A5D6A7;
}

/* Sign in alert */
.signin-alert {
  background-color: #f8f9fa;
  border-left: 4px solid #4CAF50;
  color: #1A3E1A;
  padding: 20px;
  border-radius: 0 4px 4px 0;
}

.signin-btn {
  color: #4CAF50;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
}

.signin-btn:hover {
  color: #2E7D32;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .page-title {
    font-size: 2rem;
  }
  
  .info-section, 
  .form-section {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .page-title {
    font-size: 1.75rem;
  }
}