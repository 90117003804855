.sc-sharing-consent {
    margin-top: 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding-top: 1.5rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), transparent);
  }
  
  .sc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }
  
  .sc-header-text {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .sc-header-title {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #1a2027;
    margin: 0;
    letter-spacing: -0.01em;
  }
  
  .sc-header-description {
    font-size: 0.875rem;
    color: #5f6b7c;
    margin: 0;
    line-height: 1.4;
  }
  
  /* Button Styling */
  .sc-button-icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.625rem;
    transition: transform 0.2s ease;
  }
  
  .MuiButton-root:hover .sc-button-icon {
    transform: translateY(-1px);
  }
  
  /* Dialog Styling */
  .sc-dialog .MuiDialog-paper {
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
  
  .sc-dialog-content {
    padding: 1.5rem !important;
    min-width: 400px;
  }
  
  .sc-alert {
    margin-bottom: 2rem !important;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.5;
    border: none;
    background-color: rgba(255, 171, 0, 0.08) !important;
  }
  
  .sc-benefits-container {
    display: grid;
    gap: 1.25rem;
    margin: 1.5rem 0;
    padding: 0.5rem 0;
  }
  
  .sc-benefit-item {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding: 0.5rem;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }
  
  .sc-benefit-item:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .sc-icon-wrapper {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: linear-gradient(135deg, #e3f2fd, #bbdefb);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    flex-shrink: 0;
  }
  
  .sc-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #1976d2;
  }
  
  .sc-benefit-text {
    flex: 1;
  }
  
  .sc-benefit-title {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #1a2027;
    margin: 0 0 0.25rem 0;
    letter-spacing: -0.01em;
  }
  
  .sc-benefit-description {
    font-size: 0.875rem;
    color: #5f6b7c;
    margin: 0;
    line-height: 1.5;
  }
  
  .sc-consent-container {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .sc-consent-label {
    font-size: 0.875rem;
    color: #444a54;
    line-height: 1.5;
    user-select: none;
  }
  
  /* Dialog Actions */
  .sc-dialog-actions {
    padding: 1rem 1.5rem !important;
    background-color: rgba(0, 0, 0, 0.02);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .sc-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .sc-dialog-content {
      min-width: auto;
    }
  
    .sc-benefit-item {
      padding: 0.25rem;
    }
  
    .sc-icon-wrapper {
      width: 1.75rem;
      height: 1.75rem;
    }
  
    .sc-icon {
      width: 1rem;
      height: 1rem;
    }
  }
  
  /* Animation for Dialog */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .sc-dialog .MuiDialog-paper {
    animation: fadeIn 0.3s ease-out;
  }