.vendor-page {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f3f6f9;
}

.lab-test-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}

.lab-test-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.lab-test-card .card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.lab-test-card .card-subtitle {
  font-size: 0.9rem;
  color: #666666;
  margin: 0 0 8px;
}

.lab-test-card .card-text {
  font-size: 0.85rem;
  color: #555555;
}

.status-badge {
  font-size: 0.9rem;
  padding: 0.4em 0.8em;
  border-radius: 12px;
}

.lab-test-card .btn-outline-primary {
  font-size: 0.9rem;
  padding: 0.4em 1.2em;
}

.lab-test-card .divider {
  border-top: 1px solid #e0e0e0;
  margin: 8px 0;
}

.lab-test-card .card-footer {
  padding: 0.5rem;
}

.card {
  border-radius: 8px;
  border: none;
}

.card-body {
  padding: 20px;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #eee;
}

.badge {
  padding: 0.5em 0.75em;
  font-size: 0.9em;
}

.btn-primary {
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  padding: 10px 15px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

h5 {
  font-size: 1.25rem;
  color: #333;
}

/* p {
  font-size: 0.9rem;
  color: #666;
} */

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #eee;
}