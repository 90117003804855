  
  /* Form header */
  .complaints-form__header {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid var(--secondary-green);;
  }
  
  .complaints-form__header h2 {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  
  .complaints-form__description {
    color: var(--text-secondary);
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  .complaints-form__description a {
    color: var(--primary-green);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
  }
  
  .complaints-form__description a:hover {
    color: var(--primary-green-hover);
    text-decoration: underline;
  }
  
  /* Main form wrapper */
  .complaints-form-unique {
    max-width: 800px;
    margin: 0 auto;
    transition: all 0.3s ease;
  }
  
  /* Form group styling */
  .complaints-form__group {
    margin-bottom: 1.5rem;
  }
  
  /* Labels */
  .complaints-form__group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: var(--text-primary);
    font-size: 0.95rem;
  }
  
  /* React Select styling */
  .complaints-form__select {
    margin-bottom: 0.5rem;
  }
  
  .complaints-form__select .select__control {
    border: 2px solid var(--border-color);
    border-radius: 8px;
    min-height: 42px;
    box-shadow: var(--shadow-sm);
  }
  
  .complaints-form__select .select__control--is-focused {
    border-color: var(--primary-green);
    box-shadow: 0 0 0 3px rgba(46, 139, 87, 0.1);
  }
  
  .complaints-form__select .select__option--is-focused {
    background-color: rgba(46, 139, 87, 0.1);
  }
  
  .complaints-form__select .select__option--is-selected {
    background-color: var(--primary-green);
  }
  
  .complaints-form__select .select__indicator-separator {
    background-color: var(--border-color);
  }
  
  /* Input fields and textarea */
  .complaints-form__input,
  .complaints-form__textarea {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-color: white;
  }
  
  .complaints-form__input:focus,
  .complaints-form__textarea:focus {
    outline: none;
    border-color: var(--primary-green);
    box-shadow: 0 0 0 3px rgba(46, 139, 87, 0.1);
  }
  
  /* Textarea specific styling */
  .complaints-form__textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  /* File input styling */
  .complaints-form__file-input {
    width: 100%;
    padding: 0.75rem;
    border: 2px dashed var(--border-color);
    border-radius: 8px;
    background-color: var(--background-light);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .complaints-form__file-input:hover {
    border-color: var(--primary-green);
    background-color: rgba(46, 139, 87, 0.05);
  }
  
  /* File name display */
  .complaints-form__file-name {
    margin-top: 0.75rem;
    padding: 0.5rem;
    background-color: var(--background-light);
    border-radius: 6px;
    font-size: 0.9rem;
    color: var(--text-secondary);
  }
  
  .list-group-flush{
    /* background-color: red; */
  }
  .complaints-form-unique .submit-button-section{
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
  /* Error message styling */
  .complaints-form__error {
    color: var(--error-red);
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  /* Submit button styling */
  .complaints-form__submit {
    background-color: #4B0082 !important;
    border: #4B0082!important;
    padding: 0.75rem 1.5rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    transition: all 0.3s ease !important;
    min-width: 150px;
  }
  
  .complaints-form__submit:hover:not(:disabled) {
    background-color: var(--primary-color) !important;
    transform: translateY(-1px);
  }
  
  .complaints-form__submit:active:not(:disabled) {
    background-color: var(--primary-color) !important;
    transform: translateY(0);
  }
  
  .complaints-form__submit:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Spinner styling */
  .complaints-form__submit .spinner-border {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
  
  .required{
    color: orange;
  }

  .visibility-tag{
    font-size: 14px;
    color: grey;
  }

  .remove-ing-btn{
    width: 1.5rem;
    height: 1.5rem;
  }

  /* Mobile Responsive Design */
  @media (max-width: 768px) {

    .complaints-form__input,
    .complaints-form__textarea,
    .complaints-form__file-input {
      padding: 0.625rem;
      font-size: 16px; /* Prevent zoom on iOS */
    }
  
    .complaints-form__submit {
      width: 100%;
    }
  }
  
  /* Canvas version styles */
  @media (max-width: 480px) {
    .complaints-form-unique {
      margin: 0;
      padding: 1rem;
      border-radius: 0;
      box-shadow: none;
      height: 100vh;
      overflow-y: auto;
    }
  
    .complaints-form__group {
      margin-bottom: 1rem;
    }
  
    .complaints-form__file-input {
      padding: 0.5rem;
    }
  
    /* Enhance touch targets */
    .complaints-form__submit {
      padding: 1rem !important;
      margin-top: 1rem;
    }
  }