/* Refined SignInSignUpModal CSS with Fixes */
:root {
    --primary-color: #1b2f1b;
    --primary-hover: #2c5e2c;
    --primary-light: #3b7e3b;
    --primary-very-light: rgba(59, 126, 59, 0.1);
    --button-primary: #4076f6;
    --button-primary-hover: #3366e6;
    --success-color: #059669;
    --success-bg: #e6f7ed;
    --text-primary: #2d3748;
    --text-secondary: #4a5568;
    --border-color: rgba(59, 126, 59, 0.15);
    --background-subtle: #f9fafb;
    --form-input-focus: #4076f6;
    --form-input-border: #cbd5e0;
    --modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    --transition-speed: 0.2s;
  }
  
  /* Modal Backdrop */
  .modal-backdrop.show {
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px);
  }
  
  /* Modal Container */
  .modal-dialog {
    margin: 1.75rem auto !important;
    max-width: 480px;
  }
  
  /* Main modal box */
  .modal-content {
    border: none;
    border-radius: 12px;
    box-shadow: var(--modal-shadow);
    overflow: hidden;
  }
  
  /* Custom Header with solid background */
  .modal-custom-header {
    background-color: var(--primary-color);
    border-bottom: none;
  }
  
  .modal-custom-title {
    color: white !important;
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
  }
  
  /* Fix for header layout */
  .modal-custom-header .modal-header {
    border-bottom: none;
    background-color: transparent;
    padding: 1.25rem 1.5rem;
  }
  
  /* Close button styling */
  .modal-custom-header .btn-close {
    color: white;
    opacity: 0.8;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  }
  
  .modal-custom-header .btn-close:hover,
  .modal-custom-header .btn-close:focus {
    opacity: 1;
    box-shadow: none;
  }
  
  /* Modal Body */
  .modal-body {
    padding: 1.75rem;
    background-color: white;
  }
  
  /* Form Elements */
  .form-label {
    font-size: 0.9rem;
    color: var(--text-primary);
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .form-control {
    border: 1px solid var(--form-input-border);
    border-radius: 8px;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: var(--text-primary);
    background-color: white;
    transition: all var(--transition-speed);
    height: auto;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .form-control:focus {
    border-color: var(--form-input-focus);
    box-shadow: 0 0 0 2px rgba(64, 118, 246, 0.2);
    outline: none;
  }
  
  .form-control::placeholder {
    color: #a0aec0;
    opacity: 1;
  }
  
  .form-group {
    margin-bottom: 1.25rem;
  }
  
  /* Password Field Group */
  .psw-group {
    position: relative;
  }
  
  .password-toggle {
    position: absolute !important;
    right: 12px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    color: #6b7280 !important;
    font-size: 1.1rem !important;
    z-index: 10 !important;
    transition: color var(--transition-speed) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0.4rem !important;
  }
  
  .password-toggle:hover {
    color: var(--form-input-focus) !important;
  }
  
  /* Fix for the icon showing */
  .input-group-text.password-toggle {
    background-color: transparent !important;
    border: none !important;
    position: absolute !important;
    padding: 0 !important;
    right: 12px !important;
  }
  
  /* Alert Styling - Fixed info alert */
  .alert-info {
    background-color: #ebf8ff;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    color: #2c5282;
    font-size: 0.9rem;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Fix icon issues on the alert */
  .alert-info strong {
    font-weight: 600;
  }
  
  .alert-info em {
    font-style: italic;
    font-weight: 500;
  }
  
  /* Remove any additional icons that might be showing */
  .alert-info::before,
  .alert-info svg,
  .alert-info img {
    display: none;
  }
  
  /* Submit Button */
  .submit-grn-btn {
    background-color: var(--button-primary);
    border: none;
    color: white;
    font-weight: 600;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 8px;
    width: 100%;
    transition: all var(--transition-speed);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.01em;
    height: auto;
    margin-top: 0.5rem;
  }
  
  .submit-grn-btn:hover {
    background-color: var(--button-primary-hover);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .submit-grn-btn:active {
    transform: translateY(0px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Google Sign In Button */
  .google-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    border-radius: 8px !important;
    border: 1px solid #e2e8f0 !important;
    color: #4a5568 !important;
    font-weight: 600;
    background-color: white !important;
    transition: all var(--transition-speed);
    padding: 0.75rem 1.5rem !important;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    font-size: 1rem !important;
    margin-top: 1rem;
  }
  
  .google-btn:hover {
    background-color: #f9fafb !important;
    border-color: #cbd5e0 !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
  
  /* Switch Mode Button */
  .switch-mode-btn {
    color: var(--form-input-focus);
    font-weight: 500;
    font-size: 0.95rem;
    text-decoration: none;
    transition: all var(--transition-speed);
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 0;
    margin-bottom: 0.5rem;
    border: none !important;
    background-color: transparent !important;
  }
  
  .switch-mode-btn:hover {
    color: var(--button-primary-hover);
    text-decoration: underline;
  }
  
  /* Custom scrollbar styling for WebKit browsers (Chrome, Edge, Safari) */
  .thin-scroll-modal .modal-body::-webkit-scrollbar {
      width: 6px;
  }
  
  .thin-scroll-modal .modal-body::-webkit-scrollbar-track {
      background: #f1f1f1;
  }
  
  .thin-scroll-modal .modal-body::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
  }
  
  .thin-scroll-modal .modal-body::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 576px) {
    .modal-dialog {
      margin: 0.5rem !important;
      max-width: calc(100% - 1rem);
    }
    
    .modal-custom-header .modal-header {
      padding: 1rem;
    }
    
    .modal-custom-title {
      font-size: 1.2rem;
    }
    
    .modal-body {
      padding: 1.25rem;
    }
    
    .form-control {
      padding: 0.7rem 0.9rem;
      font-size: 0.95rem;
    }
    
    .submit-grn-btn, 
    .google-btn {
      padding: 0.7rem 1rem !important;
      font-size: 0.95rem !important;
    }
    
    .alert-info {
      padding: 0.75rem;
      font-size: 0.85rem;
    }
  }
  
  /* iPhone SE and other small devices */
  @media (max-width: 375px) {
    .modal-custom-title {
      font-size: 1.1rem;
    }
    
    .modal-body {
      padding: 1rem;
    }
    
    .form-control {
      padding: 0.6rem 0.8rem;
      font-size: 0.9rem;
    }
    
    .google-btn, .submit-grn-btn {
      font-size: 0.9rem !important;
    }
  }
  
  /* Animation for modal entrance */
  .fade .modal-dialog {
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    transform: translateY(-10px);
    opacity: 0;
  }
  
  .fade.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
  }
  
  /* Fixed specific issues with the alert text */
  .alert-info {
    position: relative;
  }
  
  /* Remove any unwanted icons that might be appearing */
  .alert-info i, 
  .alert-info img,
  .alert-info svg {
    display: none !important;
  }
  
  /* Ensuring icon before "Your username" doesn't appear */
  .alert-info strong:before,
  .alert-info strong::before {
    display: none !important;
    content: none !important;
  }

  /* Username Alert Spacing Fix */
  .username-alert {
      background-color: #ebf8ff;
      border: none;
      border-radius: 8px;
      padding: 1rem;
      margin-bottom: 1.5rem;
      color: #2c5282;
      font-size: 0.9rem;
      line-height: 1.5;
    }
    
    .username-alert strong {
      font-weight: 600;
      margin-right: 5px;
    }
    
    .username-alert em {
      font-style: italic;
      font-weight: 500;
      margin: 0 5px;
    }
    
    /* Fix for "on your reviews" text */
    .username-alert-text {
      display: inline;
      white-space: normal;
      word-spacing: normal;
    }
    
    /* Override Bootstrap's default Alert styles */
    .username-alert.alert-info {
      display: block !important;
    }
    
    /* Make sure no unwanted icons appear */
    .username-alert::before,
    .username-alert::after {
      display: none !important;
      content: none !important;
    }

  