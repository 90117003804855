/* Main Container */
.unique-complaint-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background: #f8fafc;
}

/* Complaint Summary Card */
.unique-complaint-summary {
  background: #ffffff;
  border: none;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 10px 15px -3px rgba(0, 0, 0, 0.05);
}

.unique-complaint-summary .card-body {
  padding: 2rem;
}

/* Complaint Header */
.unique-complaint-header {
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.unique-complaint-header h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.75rem;
  line-height: 1.3;
}

.unique-complaint-id {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9375rem;
  color: #64748b;
  margin-bottom: 1rem;
}

.unique-dot-separator {
  color: #94a3b8;
}

/* Status Badge */
.unique-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 6px;
}

.unique-badge-warning {
  background-color: #fef3c7;
  color: #92400e;
}

.unique-badge-info {
  background-color: #dbeafe;
  color: #1e40af;
}

.unique-badge-success {
  background-color: #dcfce7;
  color: #166534;
}

.unique-badge-secondary {
  background-color: #f1f5f9;
  color: #475569;
}

/* Meta Info */
.unique-meta-info {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.unique-complaint-type {
  font-size: 1rem;
  font-weight: 500;
  color: #334155;
  margin-bottom: 0.5rem;
}

.unique-meta-info .text-muted {
  font-size: 0.875rem;
  color: #64748b;
}

/* Complaint Details */
.unique-complaint-details {
  color: #334155;
}

.unique-complaint-details h6 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.unique-complaint-details p {
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #475569;
  margin-bottom: 1.5rem;
}

/* Conversation Thread */
.unique-conversation-thread {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

/* Reply Form */
.unique-reply-form {
  background: #ffffff;
  border: none;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 10px 15px -3px rgba(0, 0, 0, 0.05);
}

.unique-reply-form .card-body {
  padding: 1.5rem;
}

.unique-reply-form textarea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background: #f8fafc;
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #1e293b;
  transition: all 0.2s ease;
}

.unique-reply-form textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background: #ffffff;
}

.unique-reply-form textarea::placeholder {
  color: #94a3b8;
}

/* File Upload */
.unique-selected-files {
  background: #f8fafc;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.unique-selected-file {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.unique-selected-file:last-child {
  margin-bottom: 0;
}

.unique-file-name {
  font-size: 0.875rem;
  color: #475569;
  margin-right: 0.5rem;
}

.unique-file-input-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* Custom Buttons (renamed to avoid clashing with Bootstrap) */
.unique-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.unique-btn-primary {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: #ffffff;
}

.unique-btn-primary:hover {
  background-color: #2563eb;
  border-color: #2563eb;
  transform: translateY(-1px);
}

.unique-btn-primary:disabled {
  background-color: #93c5fd;
  border-color: #93c5fd;
  transform: none;
}

.unique-btn-outline-secondary {
  color: #475569;
  border-color: #e2e8f0;
  background: #ffffff;
}

.unique-btn-outline-secondary:hover {
  background-color: #f8fafc;
  border-color: #cbd5e0;
  color: #1e293b;
}

/* Loading State */
.spinner-border {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 0.15em;
  margin-right: 0.5rem;
}

/* Alert Styles */
.alert {
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  border: none;
}

.alert-danger {
  background-color: #fef2f2;
  color: #991b1b;
}

.alert-warning {
  background-color: #fffbeb;
  color: #92400e;
}

.unique-complaint-details p {
  white-space: pre-wrap;
}

.toggle-description {
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .unique-complaint-page {
    padding: 1rem;
  }

  .unique-complaint-summary .card-body,
  .unique-reply-form .card-body {
    padding: 1.25rem;
  }

  .unique-meta-info {
    position: relative;
    text-align: left;
    margin-top: 1rem;
  }

  .unique-complaint-header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .unique-complaint-header h4 {
    font-size: 1.25rem;
  }
}
