/* Variables (if you use CSS variables in a global :root or a higher-level stylesheet) */

  
  /* Global spacing variable for sections */
  .about-page {
    --padding-section: 4rem 0;
  }
  
  /* HERO SECTION */
  .hero-section {
    background: linear-gradient(to right, var(--primary-green), var(--secondary-green));
    color: #fff;
    padding: 6rem 0;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;
  }
  
  .hero-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    margin-top: 1rem;
    opacity: 0.9;
  }
  
  /* MISSION SECTION */
  .mission-section {
    padding: var(--padding-section);
    background-color: #ffffff;
  }
  
  .mission-image img {
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
  
  .section-title {
    color: var(--text-dark);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .section-text {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #4a5568;
  }
  
  /* VALUES SECTION */
  .values-section {
    padding: var(--padding-section);
    background-color: #f8fafc;
  }
  
  .values-section .section-title {
    margin-bottom: 0.5rem;
  }
  
  .section-subtitle {
    font-size: 1rem;
    color: #718096;
    margin-bottom: 3rem;
  }
  
  .value-card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    /* height: 100%; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-bottom: 2rem;
  }
  
  .value-card:hover {
    transform: translateY(-5px);
  }
  
  .value-icon {
    font-size: 2.5rem;
    color: var(--secondary-green);
    margin-bottom: 1.5rem;
  }
  
  .value-title {
    color: var(--text-dark);
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
  
  .value-description {
    color: #666;
    line-height: 1.6;
  }
  
  /* IMPACT SECTION */
  .impact-section {
    padding: var(--padding-section);
    background-color: #ffffff;
  }
  
  .impact-section .section-title {
    margin-bottom: 0.5rem;
  }
  
  .stat-card {
    text-align: center;
    padding: 2rem;
    background: #f8fafc;
    border-radius: 12px;
    transition: transform 0.3s ease;
    margin-bottom: 2rem;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
  }
  
  .stat-number {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--secondary-green);
    margin-bottom: 0.5rem;
  }
  
  .stat-label {
    color: var(--text-dark);
    font-size: 1.1rem;
  }
  
  /* DONATION SECTION */
  .donation-section {
    background: #f8fafc;
    padding: var(--padding-section);
    text-align: center;
  }
  
  .donate-button {
    background-color: var(--primary-green);
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 30px;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .donate-button:hover {
    background-color: var(--secondary-green);
  }
  
  /* FAQ SECTION */
  .faq-section {
    padding: var(--padding-section);
    background-color: #ffffff;
  }
  
  .faq-item {
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  
  .faq-item:hover {
    /* Remove or comment out background-color change */
    /* background-color: #f8fafc; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .faq-question {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 1.5rem;
    font-size: 1.1rem;
    color: var(--text-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-weight: 600;
  }
  
  .faq-icon {
    transition: transform 0.3s ease;
    margin-left: 1rem;
  }
  
  .faq-icon.rotate {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    margin-top: 0.5rem;
    padding: 0 1.5rem 1.5rem;
    color: #666;
    line-height: 1.6;
    font-size: 1rem;
  }
  
  /* BACK TO TOP BUTTON */
  .back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: var(--primary-green);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: none;
    z-index: 999;
    transition: background-color 0.3s ease;
  }
  
  .back-to-top:hover {
    background-color: var(--secondary-green);
  }
  
  .rotate-180 {
    transform: rotate(180deg);
    /* margin-bottom: 0.1rem; */
  }
  
  /* RESPONSIVE */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  
    .hero-subtitle {
      font-size: 1rem;
      padding: 0 1rem;
    }
  
    .mission-section,
    .values-section,
    .impact-section,
    .donation-section,
    .faq-section {
      padding: 2rem 1rem;
    }
  
    .section-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .stat-card,
    .value-card {
      margin-bottom: 2rem;
    }
  }

  /* Enhanced FAQ Section Styling */

/* FAQ Section Container */
.faq-section {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

/* Section Title Styling */
.faq-section .section-title {
  margin-bottom: 1rem;
  color: #1b2f1b;
  font-weight: 700;
  font-size: 2.25rem;
}

.faq-section .section-subtitle {
  color: #4a5568;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}

/* FAQ Item Styling */
.faq-item {
  margin-bottom: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  background-color: white;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

/* FAQ Question Button */
.faq-question {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  background-color: white;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 10px;
}

.faq-question:hover {
  background-color: #f7fafc;
}

/* FAQ Icon Animation */
.faq-icon {
  transition: transform 0.3s ease;
  color: #3b7e3b;
  min-width: 24px;
  margin-left: 1rem;
}

.faq-icon.rotate {
  transform: rotate(180deg);
}

/* FAQ Answer Container */
.faq-answer {
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: #4a5568;
  line-height: 1.6;
  font-size: 1rem;
}

/* Styling for answer content */
.faq-answer p {
  margin-bottom: 1rem;
  line-height: 1.7;
}

.faq-answer p:last-child {
  margin-bottom: 0;
}

/* Better styling for HTML content inside answers */
.faq-answer a {
  color: #3b7e3b;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.faq-answer a:hover {
  color: #2c5e2c;
  text-decoration: underline;
}

.faq-answer em {
  font-style: italic;
  color: #2d3748;
}

/* Spacing between paragraphs */
.faq-answer p + p {
  margin-top: 1rem;
}

/* Active FAQ Item */
.faq-item:has(.faq-question[aria-expanded="true"]) {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.faq-question[aria-expanded="true"] {
  border-bottom: 1px solid #e2e8f0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-section {
    padding: 3rem 0;
  }
  
  .faq-section .section-title {
    font-size: 1.75rem;
  }
  
  .faq-question {
    padding: 1rem 1.25rem;
    font-size: 1rem;
  }
  
  .faq-answer {
    padding: 0 1.25rem 1.25rem 1.25rem;
    font-size: 0.95rem;
  }
}