.ch-header-card {
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .ch-header-content {
    padding: 1.5rem;
  }
  
  /* Vendor Section */
  .ch-vendor-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .ch-vendor-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .ch-vendor-logo {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
  }
  
  .ch-vendor-details {
    display: flex;
    flex-direction: column;
  }
  
  .ch-vendor-name {
    color: #2563eb;
    font-size: 1.125rem;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .ch-vendor-name:hover {
    color: #1d4ed8;
  }
  
  .ch-vendor-id {
    display: flex;
    align-items: center;
    color: #6b7280;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .ch-vendor-id svg {
    margin-right: 0.25rem;
  }
  
  /* Status Badge */
  .ch-status-badge {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .ch-status-badge.ch-pending {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .ch-status-badge.ch-in-review {
    background-color: #dbeafe;
    color: #1e40af;
  }
  
  .ch-status-badge.ch-resolved {
    background-color: #dcfce7;
    color: #166534;
  }
  
  .ch-status-badge.ch-closed {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  
  /* Complaint Information */
  .ch-complaint-info {
    margin-top: 1rem;
  }
  
  .ch-complaint-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #111827;
    margin-bottom: 1rem;
    line-height: 1.3;
  }
  
  .ch-complaint-metadata {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    color: #4b5563;
    font-size: 0.875rem;
  }
  
  .ch-metadata-item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
  
  .ch-complaint-type {
    padding: 0.25rem 0.75rem;
    background-color: #f3f4f6;
    border-radius: 0.375rem;
    font-weight: 500;
  }
  
  /* Description Section */
  .ch-description-section {
    margin-top: 2rem;
    border-top: 1px solid #e5e7eb;
    padding-top: 1.5rem;
  }
  
  .ch-section-title {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.75rem;
  }
  
  .ch-description-text {
    color: #6b7280;
    font-size: 0.9375rem;
    line-height: 1.6;
    white-space: pre-wrap;
  }
  
  .ch-toggle-description {
    color: #2563eb;
    cursor: pointer;
    font-weight: 600;
    margin-left: 0.25rem;
    display: inline-block;
  }
  
  .ch-toggle-description:hover {
    color: #1d4ed8;
    text-decoration: underline;
  }
  
  .ch-resolution-section {
    margin-top: 1.5rem;
  }

  /* Status Badge */
.ch-status-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .ch-status-badge {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: capitalize;
    cursor: help;
  }
  
  /* Status Variants */
  .ch-status-badge.ch-info {
    background-color: #dbeafe;
    color: #1e40af;
  }
  
  .ch-status-badge.ch-warning {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .ch-status-badge.ch-primary {
    background-color: #fff7ed;
    color: #9a3412;
  }
  
  .ch-status-badge.ch-secondary {
    background-color: #dcfce7;
    color: #166534;
  }
  
  .ch-status-badge.ch-success {
    background-color: #ccfbf1;
    color: #0f766e;
  }
  
  .ch-status-badge.ch-dark {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  
  /* Tooltip */
  .ch-status-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem 1rem;
    background-color: #1f2937;
    color: white;
    text-align: center;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    line-height: 1.25;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  /* Tooltip Arrow */
  .ch-status-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
  
  .ch-status-wrapper:hover .ch-status-tooltip {
    visibility: visible;
    opacity: 1;
  }

  /* Evidence Section */
.ch-evidence-section {
    margin-top: 2rem;
    border-top: 1px solid #e5e7eb;
    padding-top: 1.5rem;
  }
  
  .ch-evidence-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .ch-evidence-item {
    position: relative;
    aspect-ratio: 1;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #e5e7eb;
    transition: transform 0.2s ease;
  }
  
  .ch-evidence-item:hover {
    transform: scale(1.02);
  }
  
  .ch-evidence-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .ch-evidence-file {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
    padding: 1rem;
  }
  
  .ch-evidence-file-icon {
    width: 2rem;
    height: 2rem;
    color: #6b7280;
  }
  
  .ch-evidence-filename {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  /* Modal Content */
  .ch-modal-content {
    width: 100%;
    max-height: 80vh;
    overflow: auto;
  }
  
  .ch-modal-image {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: contain;
  }
  
  .ch-modal-pdf {
    width: 100%;
    height: 80vh;
    border: none;
  }
  
  /* Responsive Design */
  @media (max-width: 640px) {
    .ch-header-content {
      padding: 1rem;
    }
  
    .ch-vendor-section {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .ch-complaint-metadata {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
    }
  
    .ch-complaint-title {
      font-size: 1.25rem;
    }
  
    .ch-description-section {
      margin-top: 1.5rem;
      padding-top: 1rem;
    }
  }

  /* Image Modal */
.ch-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .ch-modal-container {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    margin: 1rem;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .ch-modal-header {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 1;
  }
  
  .ch-modal-close {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .ch-modal-close:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .ch-modal-image {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
  }