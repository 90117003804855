/* CommentList.css */
.my-comment-list {
    margin: 0 1.5rem 1.5rem;
  }
  
  .my-comment-list-title {
    color: #166534;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .my-no-comments {
    text-align: center;
    color: #6b7280;
    padding: 2rem;
    background: #f8faf9;
    border-radius: 6px;
  }
  
  @media (max-width: 768px) {
    .my-comment-list {
      margin: 0 1.25rem 1.25rem;
    }
  }
  
  @media (max-width: 576px) {
    .my-comment-list {
      margin: 0 1rem 1rem;
    }
    
    .my-comment-list-title {
      font-size: 1rem;
    }
  }