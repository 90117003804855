/* Updated GifPicker.css with properly working infinite scrolling */
:root {
  --primary-color: #4076f6;
  --primary-hover: #3366e6;
  --text-color: #333;
  --border-color: #e2e8f0;
  --background-light: #f8f9fa;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --modal-bg: #ffffff;
  --animation-speed: 0.3s;
  --border-radius: 8px;
}

/* Modal Overlay */
.gif-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
  animation: fadeIn var(--animation-speed) ease;
}

/* Main Container */
.gif-picker-container {
  background-color: var(--modal-bg);
  border-radius: var(--border-radius);
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 25px var(--shadow-color);
  overflow: hidden;
  animation: slideIn var(--animation-speed) ease;
}

/* Header */
.gif-picker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-light);
  z-index: 2; /* Keep above scrolling content */
}

.gif-picker-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--text-color);
  font-weight: 600;
}

.gif-close-button {
  background: none;
  border: none;
  color: #666;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
}

.gif-close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

/* Search Form */
.gif-search-form {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  z-index: 2; /* Keep above scrolling content */
  background-color: var(--modal-bg);
}

.gif-search-input-container {
  display: flex;
  gap: 0.5rem;
}

.gif-search-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.gif-search-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(64, 118, 246, 0.2);
}

.gif-search-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: 0.75rem 1.25rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.gif-search-button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.gif-search-button:active {
  transform: translateY(0);
}

/* Grid Container */
.gif-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  max-height: 50vh;
  grid-auto-rows: 1fr; /* Make rows the same height */
  scroll-behavior: smooth; /* Smooth scrolling */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--border-color) transparent; /* Firefox */
  position: relative; /* For positioning loading indicator */
}

/* Scrollbar styling for Chrome/Safari */
.gif-grid::-webkit-scrollbar {
  width: 6px;
}

.gif-grid::-webkit-scrollbar-track {
  background: transparent;
}

.gif-grid::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 6px;
}

/* GIF Items */
.gif-item {
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  height: 0;
  padding-bottom: 100%; /* Create square aspect ratio */
}

.gif-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gif-item:hover {
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.gif-item:active {
  transform: translateY(0) scale(1);
}

/* Loading State */
.gif-loading {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: var(--text-color);
}

/* "Loading more" indicator at bottom */
.gif-loading-more {
  min-height: 80px;
  margin-top: 0.5rem;
}

.gif-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 0.5rem;
}

/* No Results State */
.gif-no-results {
  grid-column: 1 / -1;
  padding: 2rem;
  text-align: center;
  color: var(--text-color);
}

/* Footer */
.gif-footer {
  padding: 0.75rem;
  border-top: 1px solid var(--border-color);
  text-align: center;
  z-index: 2; /* Keep above scrolling content */
  background-color: var(--modal-bg);
}

.gif-attribution {
  margin: 0;
  font-size: 0.8rem;
  color: #777;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .gif-picker-container {
    max-width: 100%;
    max-height: 90vh;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    margin-top: auto;
  }
  
  .gif-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.75rem;
    max-height: 45vh; /* Slightly shorter on mobile */
  }
  
  .gif-search-input {
    padding: 0.6rem 0.8rem;
    font-size: 0.95rem;
  }
  
  .gif-search-button {
    padding: 0.6rem 1rem;
    font-size: 0.95rem;
  }
}

@media (max-width: 400px) {
  .gif-picker-header h3 {
    font-size: 1.1rem;
  }
  
  .gif-search-form {
    padding: 0.75rem;
  }
  
  .gif-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    padding: 0.5rem;
  }
  
  .gif-search-input-container {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .gif-search-button {
    width: 100%;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #e2e8f0;
    --border-color: #2d3748;
    --background-light: #1a202c;
    --modal-bg: #2d3748;
  }
  
  .gif-close-button {
    color: #cbd5e0;
  }
  
  .gif-close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #f7fafc;
  }
  
  .gif-search-input {
    background-color: #1a202c;
    color: #e2e8f0 !important;
    border-color: #4a5568;
  }
  
  .gif-attribution {
    color: #a0aec0;
  }
}