/* VendorComplaintsTab.css */
.kwt-complaint-container {
    background-color: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: 24px 0;
    overflow: hidden;
  }
  
  .kwt-complaint-header {
    background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
    color: white;
    padding: 32px;
  }
  
  .kwt-complaint-header-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .kwt-complaint-title {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    color: white;
  }
  
  .kwt-complaint-stats {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }
  
  .kwt-stat-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 12px 20px;
    border-radius: 8px;
    min-width: 100px;
  }
  
  .kwt-stat-divider {
    width: 1px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .kwt-stat-label-complaint {
    font-size: 0.85rem;
    opacity: 0.9;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .kwt-stat-value {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .kwt-complaint-content {
    padding: 32px;
  }
  
  .kwt-complaint-loading {
    text-align: center;
    padding: 48px;
    color: #6b7280;
    font-size: 1.1rem;
    background-color: white;
    border-radius: 8px;
    margin: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .kwt-complaint-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .kwt-complaint-empty {
    text-align: center;
    padding: 48px;
    color: #6b7280;
    background-color: white;
    border-radius: 8px;
    margin: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .kwt-complaint-empty-icon {
    font-size: 48px;
    color: #9ca3af;
    margin-bottom: 16px;
  }
  
  .kwt-complaint-empty-text {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: #374151;
  }
  
  .kwt-complaint-empty-subtext {
    font-size: 0.95rem;
    color: #9ca3af;
  }
  
  /* Pagination Styles */
  .kwt-complaint-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid #e5e7eb;
  }
  
  .kwt-pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: white;
    color: #1a472a;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .kwt-pagination-button:hover:not(:disabled) {
    background-color: #f3f4f6;
    border-color: #1a472a;
  }
  
  .kwt-pagination-button:disabled {
    color: #9ca3af;
    cursor: not-allowed;
    border-color: #e5e7eb;
  }
  
  .kwt-pagination-numbers {
    display: flex;
    gap: 8px;
  }
  
  .kwt-pagination-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: white;
    color: #1a472a;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .kwt-pagination-number:hover {
    background-color: #f3f4f6;
    border-color: #1a472a;
  }
  
  .kwt-pagination-number.active {
    background-color: #1a472a;
    color: white;
    border-color: #1a472a;
  }
  
  /* Status-specific styles */
  .kwt-stat-item.pending {
    background-color: rgba(234, 179, 8, 0.15);
  }
  
  .kwt-stat-item.in-progress {
    background-color: rgba(59, 130, 246, 0.15);
  }
  
  .kwt-stat-item.resolved {
    background-color: rgba(34, 197, 94, 0.15);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .kwt-complaint-header {
      padding: 24px;
    }
  
    .kwt-complaint-stats {
      gap: 16px;
    }
  
    .kwt-stat-item {
      flex: 1;
      min-width: auto;
      padding: 10px 16px;
    }
  
    .kwt-stat-divider {
      display: none;
    }
  
    .kwt-complaint-content {
      padding: 16px;
    }
  }
  
  @media (max-width: 640px) {
    .kwt-complaint-pagination {
      gap: 8px;
    }
  
    .kwt-pagination-button,
    .kwt-pagination-number {
      width: 36px;
      height: 36px;
    }
  
    .kwt-stat-value {
      font-size: 1.1rem;
    }
  
    .kwt-stat-label-complaint{
      font-size: 0.75rem;
    }
  }