/* Comment Delete Dialog Overlay */
.kwcd-delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
  }
  
  /* Dialog Container */
  .kwcd-delete-dialog {
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* Dialog Header */
  .kwcd-delete-dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
  }
  
  .kwcd-delete-dialog-header h3 {
    color: #343a40;
    margin: 0;
    font-size: 1.25rem;
  }
  
  /* Close Button */
  .kwcd-delete-close-button {
    background: none;
    border: none;
    color: #6c757d;
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .kwcd-delete-close-button:hover {
    background-color: #f1f3f5;
    color: #343a40;
  }
  
  .kwcd-delete-close-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Dialog Content */
  .kwcd-delete-dialog-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Warning Box */
  .kwcd-delete-warning {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem;
    background-color: #fff5f5;
    border: 1px solid #ff8787;
    border-radius: 6px;
  }
  
  .kwcd-delete-warning-icon {
    color: #e03131;
    flex-shrink: 0;
  }
  
  .kwcd-delete-warning-text {
    flex: 1;
  }
  
  .kwcd-delete-warning-text p {
    margin: 0.5rem 0;
    color: #495057;
    font-size: 0.95rem;
  }
  
  /* Form Group */
  .kwcd-delete-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .kwcd-delete-form-group label {
    font-weight: 500;
    font-size: 0.95rem;
    color: #495057;
  }
  
  .kwcd-delete-form-group textarea {
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 0.95rem;
    width: 100%;
    color: #212529;
    transition: border-color 0.2s;
    resize: vertical;
  }
  
  .kwcd-delete-form-group textarea:focus {
    border-color: #4076f6;
    outline: none;
  }
  
  .kwcd-delete-form-group textarea:disabled {
    background-color: #f8f9fa;
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Dialog Action Buttons */
  .kwcd-delete-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .kwcd-delete-cancel-button {
    padding: 0.625rem 1.25rem;
    border: 1px solid #dee2e6;
    background-color: white;
    color: #495057;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .kwcd-delete-cancel-button:hover {
    background-color: #f1f3f5;
  }
  
  .kwcd-delete-cancel-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .kwcd-delete-button {
    padding: 0.625rem 1.25rem;
    border: none;
    background-color: #fa5252;
    color: white;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .kwcd-delete-button:hover {
    background-color: #e03131;
  }
  
  .kwcd-delete-button:disabled {
    background-color: #ff8787;
    cursor: not-allowed;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 640px) {
    .kwcd-delete-dialog {
      max-width: 95%;
    }
    
    .kwcd-delete-dialog-actions {
      flex-direction: column;
    }
    
    .kwcd-delete-cancel-button,
    .kwcd-delete-button {
      width: 100%;
      justify-content: center;
    }
  }
  