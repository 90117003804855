/* Container for the entire Terms of Service page */
.terms-container {
    font-family: inherit;
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
    color: #333;
    line-height: 1.6;
  }
  
  /* Hero Section Styling */
  .terms-hero {
    background: linear-gradient(90deg, #0f9d58, #34a853);
    color: #fff;
    padding: 3rem 2rem;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .terms-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .terms-hero p {
    font-size: 1.2rem;
  }
  
  /* General Section Styling */
  .terms-section {
    margin-bottom: 2rem;
  }
  
  .terms-section h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #222;
    font-weight: bold;
  }
  
  .terms-section p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #555;
  }
  
  /* Link Styling */
  .terms-section a.terms-link {
    color: #34a853;
    text-decoration: none;
  }
  
  .terms-section a.terms-link:hover {
    text-decoration: underline;
  }
  