.complaints-list-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: all 0.3s ease;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
  
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    }
  
    .complaint-header {
      background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding: 1.25rem;
  
      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
      }
  
      .case-info {
        display: flex;
        align-items: center;
        gap: 1.25rem;
      }
  
      .case-number-link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        color: #2563eb;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
        border-radius: 8px;
        background: rgba(37, 99, 235, 0.05);
        transition: all 0.2s ease;
  
        &:hover {
          background: rgba(37, 99, 235, 0.1);
          color: #1d4ed8;
  
          .link-icon {
            opacity: 1;
            transform: translateX(0);
          }
        }
  
        .link-icon {
          opacity: 0;
          transform: translateX(-4px);
          transition: all 0.2s ease;
          width: 16px;
          height: 16px;
        }
      }
  
      .timestamp {
        color: #64748b;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
  
        &::before {
          content: "•";
          margin: 0 0.5rem;
          color: #cbd5e1;
        }
      }
  
      .header-actions {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
  
      .status-badge {
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        border-radius: 6px;
        
        &.bg-warning {
          background: #fff7ed !important;
          color: #9a3412;
        }
        
        &.bg-info {
          background: #eff6ff !important;
          color: #1e40af;
        }
        
        &.bg-success {
          background: #f0fdf4 !important;
          color: #166534;
        }
        
        &.bg-secondary {
          background: #f8fafc !important;
          color: #334155;
        }
      }
  
      .expand-button {
        padding: 0.5rem;
        color: #64748b;
        border: none;
        background: transparent;
        border-radius: 8px;
        transition: all 0.2s ease;
  
        &:hover {
          color: #1e293b;
          background: rgba(0, 0, 0, 0.05);
        }
  
        .expand-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  
    .complaint-body {
      padding: 1.5rem;
  
      .complaint-main {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1.5rem;
      }
  
      .complaint-info {
        flex: 1;
      }
  
      .complaint-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: #0f172a;
        margin-bottom: 1rem;
        line-height: 1.4;
      }
  
      .complaint-type {
        display: inline-flex;
        padding: 0.5rem 1rem;
        background: #f1f5f9;
        border-radius: 8px;
        color: #334155;
        font-size: 0.875rem;
        font-weight: 500;
        transition: background 0.2s ease;
  
        &:hover {
          background: #e2e8f0;
        }
      }
  
      .evidence-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        border: 1px solid #e2e8f0;
        background: #ffffff;
        color: #475569;
        transition: all 0.2s ease;
  
        &:hover {
          background: #f8fafc;
          border-color: #cbd5e1;
        }
  
        .evidence-icon {
          width: 16px;
          height: 16px;
        }
      }
  
      .complaint-details {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #e2e8f0;
  
        .detail-section {
          margin-bottom: 1.5rem;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          h6 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #475569;
            margin-bottom: 0.75rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
          }
  
          p {
            color: #334155;
            font-size: 0.9375rem;
            line-height: 1.6;
            margin: 0;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .detail-actions {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e2e8f0;
    display: flex;
    justify-content: flex-end;
  }
  
  .view-details-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background: #166534;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9375rem;
    transition: all 0.2s ease;
  }
  
  .view-details-button:hover {
    background: #15803d;
    color: white;
    text-decoration: none;
    transform: translateY(-1px);
  }
  
  .view-details-button:active {
    transform: translateY(0);
  }
  
  /* Update the case-number-link to be less prominent */
  .case-number-link {
    font-size: 0.875rem !important;
    opacity: 0.9;
  }
  
  /* Add a hint about expandability */
  .expand-button {
    position: relative;
  }
  
  .expand-button::after {
    content: 'Click to see more';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.75rem;
    color: #64748b;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .expand-button:hover::after {
    opacity: 1;
  }
  .comp-vendor-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.75rem;
    background: rgba(22, 101, 52, 0.05);
    border-radius: 6px;
    width: fit-content;
  }
  
  .vendor-info svg {
    color: #166534;
  }
  
  .comp-vendor-name {
    color: #166534;
    font-weight: 500;
    font-size: 0.9375rem;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .comp-vendor-name:hover {
    color: #15803d;
    text-decoration: underline;
  }
  
  /* Update existing styles to accommodate vendor info */
  .comp-complaint-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .comp-complaint-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 640px) {
    .vendor-info {
      margin-bottom: 0.5rem;
      padding: 0.375rem 0.625rem;
    }
  
    .vendor-name {
      font-size: 0.875rem;
    }
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 640px) {
    .detail-actions {
      margin-top: 1.5rem;
      padding-top: 1.25rem;
    }
  
    .view-details-button {
      width: 100%;
      justify-content: center;
    }
  }
  
  @media (max-width: 640px) {
    .complaints-list-item {
      .complaint-header {
        .header-content {
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
        }
  
        .case-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
        }
  
        .timestamp {
          &::before {
            display: none;
          }
        }
  
        .header-actions {
          width: 100%;
          justify-content: space-between;
        }
      }
  
      .complaint-body {
        .complaint-main {
          flex-direction: column;
        }
  
        .evidence-button {
          align-self: flex-start;
          margin-top: 1rem;
        }
      }
    }
  }