/* VendorProfile.css */
.kwt-vendor-profile {
  background-color: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 24px 0;
}

.kwt-profile-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  padding: 32px;
  border-radius: 12px 12px 0 0;
  margin-bottom: 40px;
}

.kwt-profile-header h2 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: none;
  color: white;
}

.kwt-profile-form {
  padding: 0 32px 32px;
}

.form-section{
  border-top: 2px solid var(--secondary-green);
  padding-top: 1rem;
}

.form-section h6{
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.kwt-form-group {
  margin-bottom: 24px;
  position: relative;
}

.kwt-form-label {
  display: block;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 8px;
  font-size: 0.95rem;
}

.kwt-form-control {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  background-color: white;
}

.kwt-form-control:focus {
  border-color: #1a472a;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
  outline: none;
}

.kwt-form-error {
  color: #dc2626;
  font-size: 0.85rem;
  margin-top: 6px;
}

/* Custom styling for react-select */
.kwt-select-container .select__control {
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  min-height: 45px;
}

.kwt-select-container .select__control--is-focused {
  border-color: #1a472a;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.kwt-select-container .select__menu {
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.kwt-select-container .select__option--is-focused {
  background-color: rgba(26, 71, 42, 0.1);
}

.kwt-select-container .select__option--is-selected {
  background-color: #1a472a;
}

/* File upload styling */
.kwt-file-upload {
  border: 2px dashed #e5e7eb;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.kwt-file-upload:hover {
  border-color: #1a472a;
  background-color: rgba(26, 71, 42, 0.05);
}

.kwt-file-upload input[type="file"] {
  display: none;
}

.kwt-file-upload-label {
  display: block;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 8px;
}

/* Screenshot preview section */
.kwt-screenshot-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.kwt-screenshot-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kwt-screenshot-item img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

/* Carousel-style Screenshot Previews */
.vendor-dashboard__screenshot-previews {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 15px 0;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: #1a472a #e5e7eb;
}

.vendor-dashboard__screenshot-previews::-webkit-scrollbar {
  height: 6px;
}

.vendor-dashboard__screenshot-previews::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 10px;
}

.vendor-dashboard__screenshot-previews::-webkit-scrollbar-thumb {
  background-color: #1a472a;
  border-radius: 10px;
}

.screenshot-thumbnail {
  scroll-snap-align: start;
  flex: 0 0 auto;
  position: relative;
  border-radius: 8px !important;
  padding: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important; /* Center horizontally */
  justify-content: center !important; /* Center vertically */
  background-color: #f8f9fa;
  border: 1px solid #e9ecef !important;
  margin: 0 !important;
  width: 150px;
  min-height: 150px;
  padding-bottom: 55px !important; /* Make room for the button at bottom */
  transition: transform 0.2s ease;
}

.screenshot-thumbnail:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.screenshot-thumbnail img {
  display: block;
  max-width: 100%;
  max-height: 100px;
  border-radius: 4px;
  object-fit: contain; /* This will ensure the image keeps its aspect ratio */
}

.screenshot-thumbnail .remove-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
  font-weight: 500;
  transition: all 0.2s ease;
  width: 100px;
}

.screenshot-thumbnail .remove-btn:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.kwt-remove-btn:hover {
  background-color: #dc2626;
}

/* Save panel styling */
.kwt-save-panel {
  position: fixed;
  bottom: 32px;
  right: 32px;
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  max-width: 300px;
  border: 1px solid #e5e7eb;
}

.kwt-save-message {
  color: #1f2937;
  margin-bottom: 16px;
  font-size: 0.95rem;
}

.kwt-save-btn {
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
}

.kwt-save-btn:hover {
  background-color: #2d5a3c;
}

.kwt-save-btn:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

/* Modal styling */
.kwt-modal {
  border-radius: 12px;
  overflow: hidden;
}

.kwt-modal-header {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.kwt-modal-body {
  padding: 24px;
}

.kwt-modal img {
  max-width: 100%;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .kwt-profile-form {
      padding: 0 16px 16px;
  }
  
  .kwt-profile-header {
      padding: 24px;
  }
  
  .kwt-save-panel {
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      border-radius: 12px 12px 0 0;
  }
}

@media (max-width: 640px) {
  .vendor-dashboard__screenshot-previews {
      padding-bottom: 20px; /* Extra spacing for scrollbar on mobile */
  }
  
  .screenshot-thumbnail {
      width: 130px;
  }
}

/* Static Save Panel (sticky and clearly visible) */
.vendor-dashboard__save-panel {
  position: fixed;
  top: 50%;                /* Center vertically */
  right: 20px;             /* 20px from the right edge */
  width: 220px;
  transform: translateY(-50%);  /* Adjust to truly center the panel vertically */
  background-color: #fff;
  padding: 15px;
  border: 1px solid #007bff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.vendor-dashboard__save-message {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #007bff;
}

/* File Previews */
.vendor-dashboard__screenshot-previews,
.vendor-dashboard__logo-preview {
  margin-top: 10px;
}

.vendor-dashboard__logo-preview img {
  border: 1px solid #ccc;
  border-radius: 4px;
}
  
/* Profile header styles */
.kwt-profile-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  padding: 24px 32px;
  border-radius: 12px 12px 0 0;
  margin-bottom: 32px;
}

.kwt-profile-header h2 {
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.kwt-status-notice {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

/* Pending review status */
.kwt-status-notice.pending {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.kwt-status-notice.pending .notice-icon {
  color: #4ade80; /* Green for success */
}

/* Incomplete status */
.kwt-status-notice.incomplete {
  background-color: rgba(239, 68, 68, 0.1);
  color: white;
}

.kwt-status-notice.incomplete .notice-icon {
  color: #ef4444; /* Red for warning */
}

/* Icon styling */
.notice-icon {
  flex-shrink: 0;
}

.kwt-status-notice.public {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
}

.kwt-status-notice.public .notice-icon {
  color: #38bdf8; /* Light blue for public status */
}

/* Animation for the public notice */
.kwt-status-notice.public {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-5px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .kwt-profile-header {
      padding: 20px;
  }

  .kwt-status-notice {
      padding: 10px 14px;
      font-size: 0.9rem;
  }
}

.logo-upload-info {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.logo-upload-info small {
  color: #6c757d;
  white-space: pre-line;
}

.vendor-dashboard__logo-preview {
  margin: 1rem;
}

.logo-container {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}