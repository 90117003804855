/* VendorOverview.css */
:root {
  --primary-green: #1b2f1b;
  --secondary-green: #4a7140;
  --trust-blue: #2c5282;
  --verified-green: #276749;
  --cream-bg: #f5f5dc;
  --text-dark: #2d3748;
  --border-color: #e2e8f0;
}

.vendor-overview {
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Section Headers */
.section-header {
  color: var(--text-dark);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--border-color);
}

/* Grid Layout for Cards */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

/* Base Card Style */
.info-card {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: all 0.2s ease;
  position: relative;
}

/* Available/Unavailable States */
.info-card.available {
  border-color: var(--verified-green);
}

.info-card.unavailable {
  border-color: var(--border-color);
  opacity: 0.7;
}

/* Status Icons */
.status-icon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  font-size: 1rem;
}

.status-icon-check {
  color: var(--verified-green);
}

.status-icon-cancel {
  color: var(--warning-red);
}

/* Card Icon */
.card-icon {
  font-size: 2rem;
  color: var(--secondary-green);
  margin-bottom: 1rem;
}

/* Card Label */
.card-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--text-dark);
  margin: 0;
}

/* Kratom Colors Section */
.color-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.color-card {
  text-align: center;
  padding: 1rem;
}

.color-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem;
}

.color-icon svg {
  color: white;
}

/* States Section */
.states-section {
  background: #f7fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
}

.state-search {
  max-width: 400px;
  margin-bottom: 1.5rem;
}

.state-details {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;
}

/* Screenshots Gallery */
.screenshots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.screenshot-card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.screenshot-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease;
}

.screenshot-card:hover img {
  transform: scale(1.05);
}

/* Modal Styles */
.screenshot-modal {
  background-color: rgba(0, 0, 0, 0.9);
}

.screenshot-modal img {
  max-height: 90vh;
  width: auto;
  margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-overview {
    padding: 1rem;
  }

  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .color-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .screenshots-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .color-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .screenshots-grid {
    grid-template-columns: 1fr;
  }
}

/* Accessibility */
.info-card:focus-within {
  outline: 2px solid var(--secondary-green);
  outline-offset: 2px;
}

.state-search .select__control:focus-within {
  box-shadow: 0 0 0 2px var(--secondary-green);
}
.vendor-overview {
  padding: 1.5rem;
}

.overview-section {
  margin-bottom: 2rem;
}

.section-header {
  color: var(--text-dark);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Info Section Styles */
.info-section {
  background: white;
  border-radius: 12px;
  padding: 1rem;
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.info-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.info-title h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.info-icon {
  color: var(--primary-green);
  font-size: 1.25rem;
}

.status-check {
  color: var(--verified-green);
  font-size: 1.125rem;
}

/* Info Content Styles */
.info-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item {
  background-color: #f8fafc;
  padding: 0.625rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  color: var(--text-dark);
  transition: background-color 0.2s ease;
}

.info-item:hover {
  background-color: #f1f5f9;
}

/* View More Button */
.view-more-btn {
  background: none;
  border: none;
  color: var(--trust-blue);
  font-size: 0.875rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-more-btn:hover {
  color: var(--secondary-green);
  background-color: #f8fafc;
  border-radius: 6px;
}

.view-more-icon {
  font-size: 0.75rem;
}

/* Modal Styles */
.vendor-overview .modal-content{
  border-radius: 12px;
}

.modal-header {
  border-bottom: 1px solid #e5e7eb;
  padding: 1rem 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid #e5e7eb;
  padding: 1rem 1.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .vendor-overview {
    padding: 1rem;
  }

  .info-section {
    padding: 0.875rem;
  }

  .info-item {
    padding: 0.5rem 0.875rem;
  }
}

/* Animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .info-item {
  animation: slideDown 0.2s ease-out;
} */

/* Community Feedback Section */
 /* Community Feedback Styles */
 .community-feedback {
  background-color: #f7fafc;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.feedback-summary h4 {
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.feedback-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.feedback-stat {
  text-align: center;
}

.feedback-stat .stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-green);
}

.feedback-stat .stat-label {
  font-size: 0.875rem;
  color: var(--text-dark);
  margin-top: 0.25rem;
}

/* Experience Breakdown */
.experience-breakdown {
  margin-top: 1.5rem;
}

.experience-item {
  display: grid;
  grid-template-columns: 120px 1fr 50px;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.75rem;
}

.experience-label {
  font-size: 0.875rem;
  color: var(--text-dark);
}

.experience-bar {
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
}

.experience-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.experience-count {
  font-size: 0.875rem;
  color: var(--text-dark);
  text-align: right;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feedback-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .experience-item {
    grid-template-columns: 100px 1fr 40px;
    gap: 0.5rem;
  }

  .experience-label {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .feedback-grid {
    grid-template-columns: 1fr;
  }
}

/* VendorOverview.css */
.vendor-overview {
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.overview-section {
  margin-bottom: 32px;
}

.overview-section:last-child {
  margin-bottom: 0;
}

/* Section Headers */
.section-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e7eb;
}

.section-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.section-icon {
  font-size: 1.25rem;
  color: #1a472a;
}

/* Items Grid */
.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
}

.item-card {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: #f9fafb;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.item-card:hover {
  background: #f3f4f6;
  transform: translateY(-1px);
}

.item-icon {
  color: #1a472a;
  font-size: 1rem;
}

.item-label {
  font-size: 0.875rem;
  color: #374151;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Expand Button */
.expand-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: none;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #1a472a;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.expand-button:hover {
  background: #f9fafb;
  border-color: #1a472a;
}

/* Gallery */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
}

.gallery-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-overlay svg {
  color: white;
  font-size: 2rem;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 24px;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  margin: 0 auto;
}

.modal-content img {
  max-width: 100%;
  max-height: 90vh;
  border-radius: 8px;
}

.modal-close {
  position: absolute;
  top: -48px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .vendor-overview {
    padding: 16px;
  }

  .items-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 12px;
  }

  .item-card {
    padding: 10px 12px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .section-header h3 {
    font-size: 1.125rem;
  }
}

@media (max-width: 480px) {
  .vendor-overview {
    padding: 12px;
  }

  .items-grid {
    grid-template-columns: 1fr 1fr;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 
.item-card {
  animation: fadeIn 0.3s ease;
} */

/* Accessibility */
/*  */

/* payment method icon */
.payment-method-icon{
  width: 1.5rem;
  height: auto;
  border: none;
  color: #276749;
}