/* UserProfile.css */
.userProfile {
  background-color: #f8fafc;
  min-height: 100vh;
  padding: 32px 0;
}

/* Card styling */
.card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.card-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  padding: 20px 24px;
  border-bottom: none;
}

.card-header h5 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: white;
}

.card-body {
  padding: 24px;
}

/* Profile section */
.userProfile-segment {
  text-align: left;
}

.userProfile-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 3px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userProfile-avatarPlaceholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin: auto;
  border: 3px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userProfile-name {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1a472a;
  margin-bottom: 8px;
}
/* Styles for the verification badge/button section */
.verification-badge {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.verification-badge .badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 0.85rem;
}


/* Stats section */
.stats-wrapper {
  display: flex;
  justify-content: space-around;
  gap: 24px;
  padding: 16px 0;
}

.stat-item {
  text-align: center;
  min-width: 80px;
}

.stat-number {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1a472a;
  line-height: 1;
  margin-bottom: 8px;
}

.stats-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.stat-label {
  font-size: 0.9rem;
  color: #4b5563;
  font-weight: 500;
}

.icon {
  color: #1a472a;
  opacity: 0.8;
}

/* Account details section */
.card strong {
  color: #1a472a;
  font-weight: 600;
}

/* Button styling */
.btn-outline-primary {
  color: #1a472a;
  border-color: #1a472a;
  padding: 8px 16px;
  font-weight: 500;
  transition: all 0.2s ease;
  width: auto;
}

.btn-outline-primary:hover {
  background-color: #1a472a;
  border-color: #1a472a;
  color: white;
}

/* Reviews section */
.text-left {
  text-align: left;
  color: #4b5563;
  font-weight: 500;
  margin-bottom: 16px;
}

.text-left a {
  color: #1a472a;
  text-decoration: none;
  font-weight: 600;
}

.text-left a:hover {
  text-decoration: underline;
}

/* Pagination controls */
.pagination-controls {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.pagination-controls .btn {
  min-width: 100px;
}

.pagination-controls .text-muted {
  color: #6b7280;
  font-weight: 500;
}

/* Modal styling */
.modal-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  border-bottom: none;
}

.modal-title {
  font-weight: 600;
  color: white;
}

.modal-body {
  padding: 24px;
}

.form-label {
  color: #1a472a;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-control {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px 16px;
}

.form-control:focus {
  border-color: #1a472a;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .userProfile {
    padding: 16px 0;
  }

  .card-header {
    padding: 16px 20px;
  }

  .card-body {
    padding: 20px;
  }

  .stats-wrapper {
    margin-top: 16px;
    gap: 16px;
  }

  .userProfile-avatar,
  .userProfile-avatarPlaceholder {
    width: 80px;
    height: 80px;
    font-size: 2rem;
  }

  .userProfile-segment {
    text-align: center;
    margin-top: 16px;
  }

  .userProfile-name {
    font-size: 1.5rem;
  }

  .stat-number {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .stats-wrapper {
    flex-wrap: wrap;
  }

  .stat-item {
    flex: 1;
  }

  .userProfile-avatar,
  .userProfile-avatarPlaceholder {
    width: 70px;
    height: 70px;
    font-size: 1.75rem;
  }

  .userProfile-name {
    font-size: 1.4rem;
  }
}

/* Add these styles to your UserProfile.css */

/* Edit Profile Button */
.btn-outline-primary {
  background-color: white;
  color: #1a472a;
  border: 2px solid white;
  padding: 8px 20px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:hover {
  background-color: #1a472a;
  border-color: white;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
}

/* Card header with better button contrast */
.card-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  padding: 20px 24px;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Ensure header title and button align properly */
.card-header h5 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-header {
    flex-direction: row; /* Keep header and button side by side */
    padding: 16px 20px;
  }
  
  .btn-outline-primary {
    padding: 6px 16px;
    font-size: 0.9rem;
    
  }

  .verification-badge {
    justify-self:  center;
    display: grid;
   
  }
}