.vendor-contact-container{
  padding: 2rem 2rem 1rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.vendor-contact-container .list{
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;  
}

.vendor-contact-container .list li{
    display: flex;
    align-items: center;
    gap:1rem;
    margin-bottom: 1rem;
}

.vendor-contact-container .list a{
    color: var(--text-dark);
}

.vendor-contact-container .list .contact-icon{
    color: var(--secondary-green)
}

.contact-link {        
    text-decoration: underline;
    text-decoration-thickness: 1px; 
    text-underline-offset: 5px;      
    text-decoration-color: var(--secondary-green)
  }

