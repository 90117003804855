.conversation-card {
    border: 1px solid #e2e8f0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .user-badge {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
  }
  
  .user-type-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-weight: 500;
  }
  
  /* Admin styling */
  .admin-user.user-badge {
    background-color: #2563eb;
  }
  
  .admin-user.user-type-tag {
    background-color: #dbeafe;
    color: #1e40af;
  }
  
  /* Vendor styling */
  .vendor-user.user-badge {
    background-color: #059669;
  }
  
  .vendor-user.user-type-tag {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  /* Customer styling */
  .customer-user.user-badge {
    background-color: #7c3aed;
  }
  
  .customer-user.user-type-tag {
    background-color: #ede9fe;
    color: #5b21b6;
  }
  
  .message-content {
    font-size: 0.95rem;
    line-height: 1.5;
    color: #1a202c;
    white-space: pre-wrap;
  }
  
  .attachments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
  }
  
  .attachment-item {
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .attachment-item:hover {
    transform: scale(1.05);
  }
  
  .image-preview {
    aspect-ratio: 1;
    background-color: #f8fafc;
  }
  
  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .file-preview {
    aspect-ratio: 1;
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }
  
  .file-preview .file-name {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .file-preview-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f8fafc;
    border-radius: 8px;
  }
  
  .file-preview-large p {
    margin-top: 1rem;
    color: #4a5568;
  }

  .conversation-card {
    border: 1px solid #e2e8f0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
  }
  
  .conversation-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .user-badge {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    transition: transform 0.2s ease, border-color 0.2s ease;
  }
  
  .user-badge:hover {
    transform: scale(1.05);
  }
  
  .user-badge .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  /* Add specific border colors for different user types */
  .admin-user.user-badge {
    background-color: #2563eb;
    border-color: #1e40af;
  }
  
  .vendor-user.user-badge {
    background-color: #059669;
    border-color: #065f46;
  }
  
  .vendor-user.user-badge .profile-pic {
    border: 2px solid #d1fae5;
  }
  
  .customer-user.user-badge {
    background-color: #7c3aed;
    border-color: #5b21b6;
  }
  
  .customer-user.user-badge .profile-pic {
    border: 2px solid #ede9fe;
  }
  
  /* Fallback styling for missing profile pictures */
  .user-badge img[src=""], 
  .user-badge img:not([src]), 
  .user-badge img[src="null"] {
    display: none;
  }
  
  .user-badge:not(:has(img[src])):before {
    content: attr(data-initials);
    position: absolute;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  /* Enhanced user type tags */
  .user-type-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-weight: 500;
    text-transform: capitalize;
    transition: transform 0.2s ease;
  }
  
  .user-type-tag:hover {
    transform: translateY(-1px);
  }