.custom-breadcrumb {
  padding: 0.5rem 0;
  /* margin-bottom: 1.5rem; */
  background: transparent;
  font-size: 1rem; /* Slightly larger font size */
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 0.5rem;
}

.custom-breadcrumb .breadcrumb-item {
  display: inline-flex;
  align-items: center;
  color: var(--text-color); /* Updated color */
  font-weight: 500;
}

.custom-breadcrumb .breadcrumb-item a {
  color: var(--text-color); /* Updated color */
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s;
}

.custom-breadcrumb .breadcrumb-item a:hover {
  color: var(--hover-color); /* Updated color */
}

.custom-breadcrumb .breadcrumb-item.active {
  color: var(--text-color); /* Updated color */
  font-weight: 600;
  cursor: default;
}

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "›";
  color: var(--text-color); /* Updated color */
  padding: 0 0.5rem;
}

.custom-breadcrumb .breadcrumb-item:first-child a {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem;
}

/* Make sure it looks good on smaller screens */
@media (max-width: 768px) {
  .custom-breadcrumb {
    font-size: 0.95rem; /* Slightly smaller on mobile for space */
  }
}


/* Enhance breadcrumb items */
.custom-breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  max-width: 250px; /* Limit width for long test names */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style for the last (active) breadcrumb item */
.custom-breadcrumb .breadcrumb-item.active {
  max-width: 300px;
  color: var(--text-color);
  font-weight: 600;
}

/* Make sure Home icon aligns properly */
.custom-breadcrumb .breadcrumb-item:first-child {
  display: flex;
  align-items: center;
}

/* Add tooltip-like behavior for truncated items */
.custom-breadcrumb .breadcrumb-item[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: -25px;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-breadcrumb .breadcrumb-item {
    max-width: 150px;
  }
  
  .custom-breadcrumb .breadcrumb-item.active {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .custom-breadcrumb .breadcrumb-item {
    max-width: 100px;
  }
  
  .custom-breadcrumb .breadcrumb-item.active {
    max-width: 150px;
  }
  
  /* Hide some breadcrumb items on very small screens */
  .custom-breadcrumb .breadcrumb-item:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
    display: none;
  }
}