.crd-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .crd-modal-container {
    background-color: white;
    border-radius: 8px;
    width: 95%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .crd-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #eee;
  }
  
  .crd-modal-header h3 {
    margin: 0;
    font-size: 1.125rem;
  }
  
  .crd-modal-close-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }
  
  .crd-modal-content {
    padding: 20px;
  }
  
  .crd-report-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 16px 0;
  }
  
  .crd-report-option {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .crd-other-reason-input {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 12px;
    resize: vertical;
  }
  
  .crd-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 20px;
    border-top: 1px solid #eee;
  }
  
  .crd-cancel-button {
    background-color: #f3f4f6;
    color: #4b5563;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .crd-submit-button {
    background-color: #ef4444;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .crd-submit-button:disabled,
  .crd-cancel-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }