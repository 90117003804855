/* JoinUsVendor.css with unique classnames */
.kwt-join-vendor-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
    color: #374151;
    font-family: Arial, sans-serif;
  }
  
  /* Hero Section */
  .kwt-vendor-hero {
    padding: 3rem 0;
    background-color: #f8faf9;
    border-radius: 8px;
    /* margin: 2rem 0; */
  }
  
  .kwt-vendor-hero-content {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
  }
  
  .kwt-vendor-hero h1 {
    color: #166534;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .kwt-hero-subtitle {
    font-size: 1.25rem;
    color: #4b5563;
    margin-bottom: 2.5rem;
    line-height: 1.6;
  }
  
  .kwt-claim-options {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .kwt-claim-option {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .kwt-claim-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  }
  
  .kwt-claim-icon {
    font-size: 2.5rem;
    color: #166534;
    margin-bottom: 1.5rem;
  }
  
  .kwt-claim-option h3 {
    color: #166534;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .kwt-claim-option p {
    color: #6b7280;
    margin-bottom: 1.5rem;
    flex-grow: 1;
  }
  
  /* Value Proposition */
  .kwt-value-proposition {
    text-align: center;
    background-color: #166534;
    color: white;
    padding: 1.5rem;
    border-radius: 8px;
    margin: 2rem 0;
  }
  
  .kwt-value-proposition h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
  
  .kwt-highlight {
    font-weight: 700;
    font-size: 1.75rem;
  }
  
  /* Benefits Section */
  .kwt-benefits-section {
    padding: 3rem 0;
  }
  
  .kwt-benefits-section h2 {
    text-align: center;
    color: #166534;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }
  
  .kwt-benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .kwt-benefit-card {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
    transition: transform 0.3s ease;
  }
  
  .kwt-benefit-card:hover {
    transform: translateY(-5px);
  }
  
  .kwt-benefit-icon {
    font-size: 2.5rem;
    color: #166534;
    margin-bottom: 1.5rem;
  }
  
  .kwt-benefit-card h3 {
    color: #166534;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .kwt-benefit-card p {
    color: #6b7280;
    margin: 0;
    line-height: 1.6;
  }
  
  /* Complaint System */
  .kwt-complaint-system {
    background-color: #f8faf9;
    padding: 3rem;
    border-radius: 8px;
    margin: 3rem 0;
  }
  
  .kwt-complaint-system h2 {
    color: #166534;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .kwt-complaint-system > p {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 2.5rem;
    color: #4b5563;
    line-height: 1.6;
  }
  
  .kwt-complaint-features {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .kwt-complaint-feature {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .kwt-feature-icon-wrapper {
    background-color: rgba(22, 101, 52, 0.1);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .kwt-feature-icon {
    color: #166534;
    font-size: 1.5rem;
  }
  
  .kwt-feature-content {
    flex: 1;
  }
  
  .kwt-feature-content h3 {
    color: #166534;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
  }
  
  .kwt-feature-content p {
    color: #4b5563;
    margin: 0;
    line-height: 1.6;
  }
  
  /* Community Section */
  .kwt-community-section {
    padding: 3rem 0;
  }
  
  .kwt-community-section h2 {
    text-align: center;
    color: #166534;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .kwt-community-section > p {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 2.5rem;
    color: #4b5563;
    line-height: 1.6;
  }
  
  .kwt-community-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
  }
  
  .kwt-stat {
    text-align: center;
  }
  
  .kwt-stat-number {
    display: block;
    font-size: 2.5rem;
    font-weight: 700;
    color: #166534;
    margin-bottom: 0.5rem;
  }
  
  .kwt-stat-label {
    color: #6b7280;
    font-size: 1rem;
  }
  
  .kwt-testimonials {
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
  }
  
  .kwt-testimonial {
    flex: 1;
    background: #f8faf9;
    padding: 2rem;
    border-radius: 8px;
    border-left: 4px solid #166534;
  }
  
  .kwt-testimonial p {
    font-style: italic;
    color: #4b5563;
    margin: 0 0 1rem;
    line-height: 1.6;
    font-size: 1.0625rem;
  }
  
  .kwt-testimonial-author {
    color: #166534;
    font-weight: 600;
  }
  
  /* Join Today Section */
  .kwt-join-today {
    text-align: center;
    background-color: #166534;
    color: white;
    padding: 3rem;
    border-radius: 8px;
    margin: 3rem 0;
  }
  
  .kwt-join-today h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .kwt-join-today p {
    margin-bottom: 2rem;
    font-size: 1.125rem;
  }
  
  .kwt-join-buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
  }
  
  /* FAQ Section */
  .kwt-faq-section {
    padding: 3rem 0;
  }
  
  .kwt-faq-section h2 {
    text-align: center;
    color: #166534;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }
  
  .kwt-faq-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .kwt-faq-item h3 {
    color: #166534;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  
  .kwt-faq-item p {
    color: #4b5563;
    margin: 0;
    line-height: 1.6;
  }
  
  /* Buttons */
  .kwt-vendor-button {
    display: inline-block;
    background-color: #166534;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .kwt-vendor-button:hover {
    background-color: #15803d;
    transform: translateY(-2px);
  }
  
  .kwt-secondary-button {
    background-color: white;
    color: #166534;
    border: 1px solid #166534;
  }
  
  .kwt-secondary-button:hover {
    background-color: #f0fdf4;
    color: #15803d;
    border-color: #15803d;
  }
  
  /* Responsive */
  @media (max-width: 992px) {
    .kwt-benefits-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .kwt-vendor-hero h1 {
      font-size: 2rem;
    }
    
    .kwt-hero-subtitle {
      font-size: 1.125rem;
    }
  }
  
  @media (max-width: 768px) {
    .kwt-claim-options {
      flex-direction: column;
      align-items: center;
    }
    
    .kwt-claim-option {
      width: 100%;
      max-width: 500px;
      margin-bottom: 1.5rem;
    }
    
    .kwt-testimonials {
      flex-direction: column;
    }
    
    .kwt-faq-grid {
      grid-template-columns: 1fr;
    }
    
    .kwt-community-stats {
      flex-direction: column;
      gap: 1.5rem;
    }
    
    .kwt-stat {
      padding: 1rem;
      background: #f8faf9;
      border-radius: 8px;
    }
  }
  
  @media (max-width: 576px) {
    .kwt-benefits-grid {
      grid-template-columns: 1fr;
    }
    
    .kwt-complaint-feature {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .kwt-join-buttons {
      flex-direction: column;
      gap: 1rem;
    }
    
    .kwt-vendor-hero h1 {
      font-size: 1.75rem;
    }
    
    .kwt-value-proposition h2 {
      font-size: 1.25rem;
    }
    
    .kwt-highlight {
      font-size: 1.5rem;
    }
  }