
.footer-logo{
  width: 61%;
  height: 100%;
  padding: 10px;
  margin-top: 39px;
}
/* mobile */
@media (max-width: 480px) {

    .footer-logo{
        width: 100%;
        height: 100%;
        padding: 10px;
      }
  
}

