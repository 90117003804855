/* DiscussionPage.css */
.my-discussion-page {
    margin: 1rem 0;
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    max-width: 100%;
    font-family: Arial, sans-serif;
  }
  
  .my-discussion-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    background: #f8faf9;
    border-radius: 8px 8px 0 0;
    margin-bottom: 1.5rem;
  }
  
  .my-discussion-title {
    color: #166534;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  
  .my-discussion-description {
    color: #4b5563;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 0;
  }
  
  .my-quill-container {
    display: flex;
    flex-direction: column;  
  }
  
  .my-quill-editor {
    height: 150px;
    border-radius: 6px;
    margin-bottom: 0.5rem;
  }
  
  .my-quill-editor .ql-toolbar {
    border-radius: 6px 6px 0 0;
    border-color: #e5e7eb;
  }
  
  .my-quill-editor .ql-container {
    border-radius: 0 0 6px 6px;
    border-color: #e5e7eb;
  }
  
  .my-submit-comment-button {
    background-color: #166534 !important;
    border-color: #166534 !important;
    padding: 0.75rem 1.5rem !important;
    font-weight: 500;
    font-size: 0.9375rem;
    border-radius: 6px;
    min-width: 180px;
    white-space: nowrap;
    transition: all 0.2s ease;
    color: #fff;
    cursor: pointer;
    border: none;
    box-shadow: 0 2px 4px rgba(22, 101, 52, 0.1);
    margin: 3rem 0 1.5rem;
    align-self: flex-end;
  }
  
  .my-submit-comment-button:hover {
    background-color: #15803d !important;
    border-color: #15803d !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(22, 101, 52, 0.15);
  }
  
  .my-submit-comment-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(22, 101, 52, 0.1);
  }
  
  .my-empty-comments {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
    background: #f8faf9;
    border-radius: 0 0 8px 8px;
    margin: 0 1.5rem 1.5rem;
  }
  
  .my-empty-content {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .my-empty-icon {
    font-size: 48px !important;
    color: #166534;
    margin-bottom: 1.5rem;
    opacity: 0.7;
  }
  
  .my-empty-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #166534;
    margin-bottom: 0.75rem;
  }
  
  .my-empty-description {
    color: #4b5563;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .my-discussion-header {
      padding: 1.25rem;
    }
  }
  
  @media (max-width: 576px) {
    .my-discussion-header {
      padding: 1rem;
    }
    
    .my-discussion-title {
      font-size: 1.125rem;
    }
    
    .my-discussion-description {
      font-size: 0.875rem;
    }

    .my-submit-comment-button {
     width: 100%;
    }
  }

  @media (max-width: 480px) {
    .my-submit-comment-button {
        margin-top: 4.75rem;
       }
  }
  
  