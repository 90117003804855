LabTestListPage.css
:root {
  --primary-green: #1b2f1b;
  --secondary-green: #4a7140;
  --trust-blue: #2c5282;
  --verified-green: #276749;
  --lab-tested-badge: #ebf4ff;
  --warning-red: #c53030;
  --cream-bg: #f5f5dc;
  --text-dark: #2d3748;
  --header-bg: var(--primary);
  --header-padding: 3rem 0;
  --header-margin: 2rem;
  --header-border: 4px solid var(--secondary-green);
}

/* Header Section */
.page-header,
.trust-header {
  background-color: var(--header-bg);
  padding: var(--header-padding);
  margin-bottom: var(--header-margin);
  position: relative;
  border-bottom: var(--header-border);
}

.page-header h1,
.trust-header h1 {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.page-header .subtitle,
.trust-subtitle {
  color: var(--cream-bg);
  text-align: center;
  font-size: 1.1rem;
  margin: 0;
  opacity: 0.9;
}

/* Filter Section */
.filter-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.search-input {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #718096;
}

/* Lab Test Card */
.lab-test-list-card {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.lab-test-list-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.lab-test-list-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.lab-test-list-name {
  font-size: 1.75rem;
  color: var(--primary-green);
  margin-bottom: 0.5rem;
}

.test-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
}

.test-status.pass {
  background-color: #f0fff4;
  color: var(--verified-green);
}

.test-status.fail {
  background-color: #fff5f5;
  color: var(--warning-red);
}

.test-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.test-info-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f7fafc;
  border-radius: 4px;
}

.info-label {
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 0.25rem;
}

.info-value {
  font-weight: 500;
  color: var(--text-dark);
}

.test-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn-view-test {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s;
}
.lab-tests-grid{
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
/* Responsive Design */
@media (max-width: 768px) {
  .test-info-grid {
    grid-template-columns: 1fr;
  }

  .test-actions {
    flex-direction: column;
  }

  .btn-view-test {
    width: 100%;
  }

  :root {
    --header-padding: 2rem 0;
  }

  .page-header h1,
  .trust-header h1 {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .page-header h1,
  .trust-header h1 {
    font-size: 1.75rem;
  }
}

/* Updated styles for the lab test counter */

.lab-test-counter {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  padding: 1.5rem 2rem;
  margin-top: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.counter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.counter-icon {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-icon svg {
  font-size: 2rem;
  color: white;
}

.counter-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.counter-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  line-height: 1;
}

.counter-label {
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 0.01em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .lab-test-counter {
    padding: 1.25rem;
  }

  .counter-icon {
    width: 56px;
    height: 56px;
  }

  .counter-icon svg {
    font-size: 1.75rem;
  }

  .counter-number {
    font-size: 2rem;
  }

  .counter-label {
    font-size: 1rem;
  }
}

/* Update existing header styles */
.trust-header {
  background-color: var(--primary-green);
  padding: 2.5rem 0 3rem;
  position: relative;
}

.trust-header h1 {
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: center;
}

.trust-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 2rem;
}