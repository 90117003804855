.comment-list {
    margin-top: 24px;
  }
  
  .comment-list-empty {
    padding: 24px;
    text-align: center;
    color: #6b7280;
    background-color: #f9fafb;
    border-radius: 6px;
    margin-top: 16px;
  }
  
  /* Space between top-level comments */
  .comment-list > .comment-container {
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 24px;
  }
  
  .comment-list > .comment-container:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
  
  /* Add space before loading more comments */
  .load-more-comments {
    margin-top: 24px;
    text-align: center;
  }
  
  .load-more-button {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #4b5563;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .load-more-button:hover {
    background-color: #f9fafb;
  }
  
  /* Loading indicator */
  .comments-loading {
    display: flex;
    justify-content: center;
    padding: 16px;
  }
  
  .comment-count-summary {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 8px;
    text-align: center;
  }