/* VendorReviewTab.css */
.kwt-review-container {
  background-color: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 24px 0;
  overflow: hidden;
}

.kwt-review-header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  color: white;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kwt-review-header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.kwt-review-title {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  color: white;
}

.kwt-review-count {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.95rem;
}

.kwt-review-count-icon {
  opacity: 0.9;
}

.kwt-review-content {
  padding: 32px;
}

.kwt-review-loading {
  text-align: center;
  padding: 48px;
  color: #6b7280;
  font-size: 1.1rem;
  background-color: white;
  border-radius: 8px;
  margin: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.kwt-review-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.kwt-review-empty {
  text-align: center;
  padding: 48px;
  color: #6b7280;
  background-color: white;
  border-radius: 8px;
  margin: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.kwt-review-empty-icon {
  font-size: 48px;
  color: #9ca3af;
  margin-bottom: 16px;
}

.kwt-review-empty-text {
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.kwt-review-empty-subtext {
  font-size: 0.95rem;
  color: #9ca3af;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .kwt-review-header {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .kwt-review-content {
    padding: 16px;
  }
}

/* Add these styles to your existing VendorReviewTab.css */

.kwt-review-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.kwt-pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: white;
  color: #1a472a;
  cursor: pointer;
  transition: all 0.2s ease;
}

.kwt-pagination-button:hover:not(:disabled) {
  background-color: #f3f4f6;
  border-color: #1a472a;
}

.kwt-pagination-button:disabled {
  color: #9ca3af;
  cursor: not-allowed;
  border-color: #e5e7eb;
}

.kwt-pagination-numbers {
  display: flex;
  gap: 8px;
}

.kwt-pagination-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: white;
  color: #1a472a;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.kwt-pagination-number:hover {
  background-color: #f3f4f6;
  border-color: #1a472a;
}

.kwt-pagination-number.active {
  background-color: #1a472a;
  color: white;
  border-color: #1a472a;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .kwt-review-pagination {
    gap: 8px;
  }

  .kwt-pagination-button,
  .kwt-pagination-number {
    width: 36px;
    height: 36px;
  }
}