/* VendorDashboard.css */
.vendor-dashboard {
  min-height: 100vh;
  background-color: #f8fafc;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Header */
.vendor-dashboard__header {
  background: linear-gradient(135deg, #1a472a 0%, #2d5a3c 100%);
  padding: 24px 32px;
  color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.vendor-dashboard__header h1 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
  color: white;
}

.vendor-dashboard__header p {
  margin: 8px 0 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
}

/* Sidebar */
.vendor-dashboard__sidebar {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin: 24px 0;
  height: fit-content;
}

.vendor-dashboard__sidebar nav {
  position: sticky;
  top: 24px;
}

.vendor-dashboard__sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vendor-dashboard__sidebar ul li {
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  color: #4b5563;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  flex:1
}

.vendor-dashboard__sidebar ul li svg {
  width: 20px;
  height: 20px;
  opacity: 0.7;
  transition: all 0.2s ease;
}

.vendor-dashboard__sidebar ul li:hover {
  background-color: rgba(26, 71, 42, 0.05);
  color: #1a472a;
}

.vendor-dashboard__sidebar ul li:hover svg {
  opacity: 1;
  color: #1a472a;
}

.vendor-dashboard__sidebar ul li.active {
  background-color: #1a472a;
  color: white;
  font-weight: 500;
}

.vendor-dashboard__sidebar ul li.active svg {
  opacity: 1;
  color: white;
}

/* Main Content */
.vendor-dashboard__main-content {
  padding: 0 24px 24px 12px;
}

/* Loading State */
.vendor-dashboard__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  color: #6b7280;
  font-size: 1.1rem;
  gap: 12px;
}

.vendor-dashboard__loading svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Error Alert */
.alert-danger {
  background-color: #fee2e2;
  border: none;
  color: #991b1b;
  border-radius: 8px;
  padding: 16px;
  margin: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Placeholder tabs */
.tab-placeholder {
  background-color: white;
  border-radius: 12px;
  padding: 48px;
  text-align: center;
  color: #6b7280;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin: 24px 0;
}

.tab-placeholder svg {
  width: 48px;
  height: 48px;
  color: #9ca3af;
  margin-bottom: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .vendor-dashboard__header {
    padding: 20px;
  }

  .vendor-dashboard__sidebar {
    margin: 16px;
    padding: 16px;
  }

  .vendor-dashboard__main-content {
    padding: 0 16px 16px;
  }

  .vendor-dashboard__sidebar ul {
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 8px;
  }

  .vendor-dashboard__sidebar ul li {
    white-space: nowrap;
  }
}