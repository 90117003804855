.thread-detail-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px 16px;
}

.thread-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  color: #4076f6;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
}

.thread-category {
  display: flex;
  align-items: center;
}

.category-link {
  font-size: 0.875rem;
  color: #3b7e3b;
  background-color: rgba(59, 126, 59, 0.1);
  padding: 4px 10px;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.category-link:hover {
  background-color: rgba(59, 126, 59, 0.2);
}

.thread-detail-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

.thread-title {
  font-size: 1.5rem;
  color: #1b2f1b;
  margin-bottom: 16px;
  line-height: 1.3;
}

.thread-edited-indicator {
  font-size: 0.875rem;
  font-style: italic;
  color: #6b7280;
}

.thread-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.thread-author {
  display: flex;
  align-items: center;
}

.author-info {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.author-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.author-name-role{
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.author-name {
  font-weight: 500;
  color: #1b2f1b;
}

.author-role{
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  width: fit-content;
  color:#ffffff;
  font-weight: 500;
}

.thread-timestamps {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.875rem;
  color: #6b7280;
}

.thread-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.thread-tag {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.thread-tag:hover {
  background-color: rgba(59, 126, 59, 0.2);
}

.thread-body {
  font-size: 1rem;
  line-height: 1.6;
  color: #111827;
  margin-bottom: 24px;
}

/* Style for rich text content */
.thread-body p {
  margin-bottom: 1rem;
}

.thread-body h1, .thread-body h2, .thread-body h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #1b2f1b;
}

.thread-body h1 {
  font-size: 1.5rem;
}

.thread-body h2 {
  font-size: 1.3rem;
}

.thread-body h3 {
  font-size: 1.1rem;
}

.thread-body ul, .thread-body ol {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.thread-body blockquote {
  border-left: 4px solid #e5e7eb;
  padding-left: 1rem;
  color: #6b7280;
  margin: 1rem 0;
}

.thread-body img {
  max-width: 100%;
  border-radius: 4px;
  margin: 1rem 0;
}

/* Thread edit form */
.thread-edit-form {
  width: 100%;
}

.edit-form-group {
  margin-bottom: 20px;
}

.edit-form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #1b2f1b;
}

.edit-title-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 1.1rem;
  margin-bottom: 16px;
}

.edit-title-input:focus {
  outline: none;
  border-color: #4076f6;
  box-shadow: 0 0 0 2px rgba(64, 118, 246, 0.2);
}

.edit-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  margin-bottom: 12px;
}

.edit-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
}

.edit-tag button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #3b7e3b;
}

.custom-tag-form {
  margin: 0;
}

.custom-tag-form input {
  border: none;
  outline: none;
  padding: 4px 0;
  font-size: 0.875rem;
  width: 100px;
  min-width: 40px;
  flex-grow: 1;
}

.edit-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel-edit-button,
.save-edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cancel-edit-button {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #4b5563;
}

.cancel-edit-button:hover {
  background-color: #f9fafb;
}

.save-edit-button {
  background-color: #4076f6;
  border: none;
  color: white;
}

.save-edit-button:hover {
  background-color: #2c5cd1;
}

.thread-actions {
  display: flex;
  gap: 16px;
  border-top: 1px solid #e5e7eb;
  padding-top: 16px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #6b7280;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.action-button:hover {
  background-color: #f9fafb;
  color: #4b5563;
}

.like-button.liked {
  color: #f43f5e;
}

.like-button.liked svg {
  fill: #f43f5e;
}

.more-actions-container {
  position: relative;
  margin-left: auto;
}

.more-actions-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 160px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  z-index: 10;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-size: 0.875rem;
  color: #4b5563;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f9fafb;
}

.edit-button {
  color: #4076f6;
}

.report-button {
  color: #ef4444;
}

.thread-comments-section {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.comments-header {
  margin-bottom: 16px;
}

.comments-header h2 {
  font-size: 1.25rem;
  color: #1b2f1b;
}

.thread-detail-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  color: #6b7280;
}

.loading-spinner {
  border: 3px solid rgba(59, 126, 59, 0.3);
  border-radius: 50%;
  border-top: 3px solid #3b7e3b;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin-bottom: 12px;
}

.comments-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #6b7280;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .thread-detail-container {
    padding: 16px 12px;
  }
  
  .thread-detail-content {
    padding: 16px;
  }
  
  .thread-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .thread-timestamps {
    align-items: flex-start;
  }
  
  .thread-actions {
    flex-wrap: wrap;
  }

  .edit-form-actions {
    flex-direction: column;
  }
  
  .cancel-edit-button, 
  .save-edit-button {
    width: 100%;
    justify-content: center;
  }
}

/* Add this to your existing ThreadDetail.css file */

.view-counter {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #6b7280;
}

.view-counter svg {
  color: #6b7280;
}

/* Add these styles to your existing ThreadDetail.css */

.th-delete-button {
  color: #ef4444;
}

.th-delete-button:hover {
  background-color: rgba(239, 68, 68, 0.1);
}

