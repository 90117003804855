/* VendorListPage.css */

/* Root Variables */
:root {
  --primary: #1b2f1b;
  --primary-light: #2d5a3c;
  --primary-dark: #133520;
  --secondary-green: #4a7140;
  --success: #059669;
  --warning: #dc2626;
  --info: #2563eb;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --header-bg: var(--primary);
  --header-padding: 3rem 0;
  --header-margin: 2rem;
  --header-border: 4px solid var(--secondary-green);
}

/* Base Page Styles */
.vendor-list-page {
  background-color: var(--gray-100);
  min-height: 100vh;
  padding-bottom: 3rem;
}

/* Unified Header Styles */
.page-header,
.trust-header {
  background-color: var(--header-bg);
  padding: var(--header-padding);
  margin-bottom: var(--header-margin);
  position: relative;
  border-bottom: var(--header-border);
}

.page-header h1,
.trust-header h1 {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.page-header .subtitle,
.trust-subtitle {
  color: var(--cream-bg);
  text-align: center;
  font-size: 1.1rem;
  margin: 0;
  opacity: 0.9;
}

/* Filters Section */
.filters-section {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: var(--shadow);
  margin-bottom: 2rem;
}

.vendors-search-input {
  position: relative;
}

.vendors-search-input .search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--gray-400);
  pointer-events: none;
}

.vendors-search-input input {
  padding-left: 2.75rem;
  height: 3rem;
  border-color: var(--gray-200);
  border-radius: 8px;
  width: 100%;
  transition: border-color 0.2s ease;
}

.vendors-search-input input:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
  outline: none;
}

.filter-select {
  height: 3rem;
  border-color: var(--gray-200);
  border-radius: 8px;
  padding: 0 1rem;
  width: 100%;
  background-color: white;
  transition: border-color 0.2s ease;
}

.filter-select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
  outline: none;
}

/* Vendor Card */
.vendor-card {
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: var(--shadow);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.vendor-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.vendor-card-grid {
  display: grid;
  grid-template-columns: 120px 1fr 200px;
  gap: 1.5rem;
  padding: 1.5rem;
  align-items: center;
}

/* Logo Section */
.vendor-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendor-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  /* border: 2px solid var(--gray-200); */
  /* transition: border-color 0.2s ease; */
}

/* .vendor-card:hover .vendor-logo {
  border-color: var(--primary-light);
} */

/* Main Content Section */
.vendor-main-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.vendor-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.vendor-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gray-800);
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  line-height: 1.2;
}

/* Badges and Pills */
.verified-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: var(--success);
  background-color: #ecfdf5;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-weight: 500;
}

.trust-indicators {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.trust-pill {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.trust-pill:hover {
  transform: translateY(-1px);
}

.trust-pill.success {
  background-color: #ecfdf5;
  color: var (--success);
}

.trust-pill.warning {
  background-color: #fef2f2;
  color: var(--warning);
}

.trust-pill.info {
  background-color: #eff6ff;
  color: var(--info);
}

/* Stats Section */
.vendor-stats {
  margin: 0.5rem 0;
}

.stat-group {
  display: flex;
  gap: 2rem;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--gray-800);
  line-height: 1;
}

.stat-label {
  font-size: 0.875rem;
  color: var(--gray-500);
}

/* Tags Section */
.vendor-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.kratom-tag {
  background-color: var(--gray-100);
  color: var(--gray-600);
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.kratom-tag:hover {
  background-color: var(--gray-200);
  color: var(--gray-700);
}

.kratom-tag.more {
  background-color: var(--gray-200);
  color: var(--gray-600);
}

/* Action Buttons */
.vendor-actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}

.btn-view-details {
  background-color: var(--primary);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-weight: 500;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: center;
}

.btn-view-details:hover {
  background-color: var(--primary-light);
  transform: translateY(-1px);
}

.btn-view-details:active {
  transform: translateY(0);
}

/* Loading State */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 4rem 0;
  color: var(--gray-500);
}

.loading-state .spinner-border {
  color: var(--primary);
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 4rem 0;
  color: var(--gray-500);
}

.empty-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: var(--gray-400);
}

.empty-state h3 {
  color: var(--gray-700);
  margin-bottom: 0.5rem;
}

.empty-state p {
  color: var(--gray-500);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .vendor-card-grid {
    grid-template-columns: 100px 1fr 180px;
    gap: 1rem;
  }
}

@media (max-width: 992px) {
  .vendor-card-grid {
    grid-template-columns: 100px 1fr;
  }
  
  .vendor-actions {
    grid-column: 1 / -1;
    justify-content: stretch;
    margin-top: 1rem;
  }
  
  .btn-view-details {
    width: 100%;
    justify-content: center;
  }
  
  .stat-group {
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  :root {
    --header-padding: 2rem 0;
  }

  .page-header h1,
  .trust-header h1 {
    font-size: 2rem;
  }

  .page-header {
    padding: 2rem 0;
  }
  
  .page-header h1 {
    font-size: 2rem;
  }
  
  .vendor-card-grid {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .vendor-logo-section {
    margin: 0 auto;
  }
  
  .vendor-name {
    justify-content: center;
  }
  
  .trust-indicators {
    justify-content: center;
  }
  
  .stat-group {
    justify-content: center;
  }
  
  .vendor-tags {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .page-header h1,
  .trust-header h1 {
    font-size: 1.75rem;
  }

  .page-header h1 {
    font-size: 1.75rem;
  }
  
  .vendor-card {
    margin: 0 0.5rem 1rem;
  }
  
  .vendor-card-grid {
    padding: 1rem;
  }
  
  .stat-group {
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .stat {
    flex: 0 0 calc(50% - 0.5rem);
  }
}

/* Add these new styles to your existing CSS */

.info-sections {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.section-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-600);
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.info-tag {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: var(--gray-100);
  color: var(--gray-700);
  transition: all 0.2s ease;
}

.info-tag.payment {
  background-color: #f0f9ff;
  color: #0369a1;
}

.info-tag.more {
  background-color: var(--gray-200);
  color: var(--gray-600);
}

.info-tag:hover {
  transform: translateY(-1px);
}

.vendor-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.btn-view-details,
.btn-visit-website {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.btn-view-details {
  background-color: var(--primary);
  color: white;
}

.btn-visit-website {
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);
}

.btn-view-details:hover,
.btn-visit-website:hover {
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .vendor-actions {
    flex-direction: row;
    gap: 1rem;
  }
  
  .btn-view-details,
  .btn-visit-website {
    width: auto;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .info-sections {
    align-items: center;
  }

  .info-section {
    align-items: center;
    text-align: center;
  }

  .tag-container {
    justify-content: center;
  }

  .vendor-actions {
    flex-direction: column;
  }

  .btn-view-details,
  .btn-visit-website {
    width: 100%;
  }
}

/* New styles for info sections */
.info-sections {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.section-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-600);
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.info-tag {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: var(--gray-100);
  color: var(--gray-700);
  transition: all 0.2s ease;
}

.info-tag.payment {
  background-color: #f0f9ff;
  color: #0369a1;
}

.info-tag.more {
  background-color: var(--gray-200);
  color: var(--gray-600);
}

.info-tag:hover {
  transform: translateY(-1px);
}

/* Updated styles for buttons */
.vendor-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.btn-view-details,
.btn-visit-website {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.btn-view-details {
  background-color: var(--primary);
  color: white;
}

.btn-visit-website {
  background-color: white;
  color: var(--primary);
  border: 2px solid var(--primary);
}

.btn-view-details:hover,
.btn-visit-website:hover {
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .vendor-actions {
    flex-direction: row;
    gap: 1rem;
  }

  .btn-view-details,
  .btn-visit-website {
    width: auto;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .info-sections {
    align-items: center;
  }

  .info-section {
    align-items: center;
    text-align: center;
  }

  .tag-container {
    justify-content: center;
  }

  .vendor-actions {
    flex-direction: column;
  }

  .btn-view-details,
  .btn-visit-website {
    width: 100%;
  }
}

/* VendorCard.css */
.vendor-card {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.vendor-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

/* Header Section */
.vendor-header {
  margin-bottom: 24px;
}

.vendor-identity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.vendor-logo-wrapper {
  position: relative;
  flex-shrink: 0;
}

.vendor-logo {
  /* width: 80px;
  height: 80px; */
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid #f3f4f6;
  background-color: white;
}

.verified-badge {
  position: absolute;
  bottom: -6px;
  right: -6px;
  background: #059669;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vendor-name-section {
  flex-grow: 1;
}

.vendor-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0 0 8px 0;
  line-height: 1.2;
}

.vendor-trust-badges {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.trust-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.trust-badge.lab-verified {
  background-color: #ecfdf5;
  color: #059669;
}

.trust-badge.shipping {
  background-color: #eff6ff;
  color: #2563eb;
}

/* Metrics Section */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  padding: 20px;
  background: #f9fafb;
  border-radius: 12px;
}

.metric {
  text-align: center;
}

.metric-value {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1f2937;
  line-height: 1;
  margin-bottom: 8px;
}

.metric-value.highlight {
  color: #059669;
}

.metric-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 4px;
}

.metric-subtext {
  font-size: 0.75rem;
  color: #6b7280;
}

/* Details Section */
.vendor-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.detail-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
  margin: 0;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.product-tag {
  background: #f3f4f6;
  color: #1f2937;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.product-tag:hover {
  background: #e5e7eb;
}

.product-tag.more {
  background: #e5e7eb;
  color: #6b7280;
}

.payment-icons {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.payment-icon {
  width: 40px;
  height: 40px;
  background: #f9fafb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.payment-icon.more {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Tooltip Styles */
.tooltip-container {
  position: relative;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background: #1f2937;
  color: white;
  font-size: 0.75rem;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  pointer-events: none;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #1f2937 transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* For wider tooltips (like in the more tags) */
.product-tag.more .tooltip,
.payment-icon.more .tooltip {
  white-space: normal;
  max-width: 200px;
  text-align: center;
  line-height: 1.4;
}

/* Action Buttons */
.vendor-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;
}

.btn-primary,
.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9375rem;
  transition: all 0.2s ease;
  text-decoration: none;
}

.btn-primary {
  background-color: #1a472a;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #133520;
  transform: translateY(-1px);
}

.btn-secondary {
  background-color: white;
  color: #1a472a;
  border: 2px solid #1a472a;
}

.btn-secondary:hover {
  background-color: #f9fafb;
  transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .vendor-details {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .metrics-grid {
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .vendor-card {
    padding: 20px;
  }

  .vendor-identity {
    flex-direction: column;
    text-align: center;
  }

  .vendor-trust-badges {
    justify-content: center;
  }

  .metrics-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 16px;
  }

  .vendor-actions {
    grid-template-columns: 1fr;
  }

  .metric-value {
    font-size: 1.5rem;
  }

  .vendor-name {
    font-size: 1.25rem;
  }

  .detail-section {
    text-align: center;
  }

  .tag-list {
    justify-content: center;
  }

  .payment-icons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .vendor-card {
    padding: 16px;
  }

  .vendor-logo {
    width: 90px;
    height: 90px;
  }

  .metric-value {
    font-size: 1.25rem;
  }

  .btn-primary,
  .btn-secondary {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}
.results-counter {
  padding: 1rem 0;
  color: var(--gray-600);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.results-counter.bottom {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--gray-200);
}

/* Update spacing between filters and grid */
.filters-section {
  margin-bottom: 1rem;
}

/* Update vendors grid spacing */
.vendors-grid {
  margin-top: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .results-counter {
    justify-content: center;
    text-align: center;
  }
  
  .results-counter.bottom {
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

/* Add to your VendorListPage.css */

.pagination-wrapper {
  margin-top: 2rem;
  padding-top: 1.5rem;
  /* border-top: 1px solid var(--gray-200); */
  padding-bottom: 20px;
}

.pagination {
  margin: 1.5rem 0 0;
  /* padding: 29px; */
}

.pagination .page-item .page-link {
  border: none;
  margin: 0 0.25rem;
  padding: 0.5rem 0.75rem;
  color: var(--gray-700);
  border-radius: 6px;
  transition: all 0.2s ease;
  min-width: 2.5rem;
  text-align: center;
}

.pagination .page-item .page-link:hover {
  background-color: var(--gray-100);
  color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  color: white;
  font-weight: 500;
}

.pagination .page-item.disabled .page-link {
  color: var(--gray-400);
  background-color: transparent;
}

/* Update results counter for pagination */
.results-counter {
  padding: 0.75rem 0;
  color: var(--gray-600);
  font-size: 0.875rem;
}

.results-counter.bottom {
  padding: 0;
  margin-bottom: 1rem;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .pagination {
    margin-top: 1rem;
  }

  .pagination .page-link {
    padding: 0.4rem 0.6rem;
    min-width: 2.25rem;
  }
  
  .results-counter {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .pagination .page-item .page-link {
    margin: 0 0.125rem;
    padding: 0.375rem 0.5rem;
    min-width: 2rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 576px) {
  .metrics-grid {
    display: flex;
    flex-direction: row;
    /* overflow-x: auto; */
    gap: 13px;
  }

  .metric {
    flex: 0 0 auto;
    /* min-width: 150px; */
  }
}

@media (min-width: 992px) { /* Bootstrap's lg breakpoint */
  .vendor-content {
    display: flex;
    align-items: center;    /* Center children horizontally */
    justify-content: center; /* Center children vertically if needed */
    padding: 20px;          /* Optional: Adjust padding as necessary */
  }

  .metrics-grid {
    display: flex;
    justify-content: center; /* Center metrics horizontally */
    gap: 20rem;               /* Adjust spacing between metrics */
    width: 100%;             /* Ensure metrics-grid takes full width */
  }

  .metric {
    text-align: center;      /* Center text within each metric */
  }

 
}

.country-badge {
  display: inline-block;
  /* background-color: #e0f7fa; */
  color: #2563eb;
  padding: 3px 8px;
  border-radius: 12px;
  /* margin: 0 5px; */
  font-size: 0.8rem;
}

.nationwide-badge {
  display: inline-block;
  background-color: #c8e6c9;
  color: #388e3c;
  padding: 3px 8px;
  border-radius: 12px;
  margin-left: 5px;
  font-size: 0.8rem;
}


.load-more-container {
  text-align: center;
  margin: 2rem 0;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-200);
}

.load-more-btn {
  min-width: 200px;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
}

.load-more-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Media query for mobile */
@media (max-width: 768px) {
  .load-more-btn {
    width: 100%;
    max-width: 300px;
  }
}
