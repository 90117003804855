/* Unique Comment Container Styling */
.cc-comment-container {
  position: relative;
}

/* Indentation for nested comments */
.cc-comment-container.depth-1 {
  margin-left: 24px;
  padding-left: 16px;
  border-left: 2px solid #e5e7eb;
}

.cc-comment-container.depth-2 {
  margin-left: 24px;
  padding-left: 16px;
  border-left: 2px solid #e5e7eb;
}

.cc-comment-container.depth-3 {
  margin-left: 24px;
  padding-left: 16px;
  border-left: 2px solid #e5e7eb;
}

/* Comment content box */
.cc-comment-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

/* Comment header with author info and timestamp */
.cc-comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

/* Author link styling */
.cc-comment-author {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

/* Profile picture - fixed size */
.cc-author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.cc-author-info{
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

/* Author name styling */
.cc-author-name {
  font-weight: 600;
  color: #1b2f1b;
  font-size: 0.95rem;
}

.cc-author-role{
  font-size: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  width: fit-content;
  color:#ffffff;
  font-weight: 500;
}
/* Timestamp styling */
.cc-comment-time {
  font-size: 0.75rem;
  color: #6b7280;
}

/* Edited indicator */
.cc-edited-indicator {
  font-style: italic;
  color: #6b7280;
}

/* Comment text content */
.cc-comment-body {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #111827;
  margin-bottom: 16px;
}

/* Rich text styling */
.cc-comment-body p {
  margin-bottom: 0.75rem;
}

.cc-comment-body p:last-child {
  margin-bottom: 0;
}

.cc-comment-body img {
  max-width: 100%;
  border-radius: 4px;
  margin: 0.75rem 0;
}

.cc-comment-body a {
  color: #4076f6;
  text-decoration: none;
}

.cc-comment-body a:hover {
  text-decoration: underline;
}

/* Edit form */
.cc-edit-form {
  margin-bottom: 16px;
}

.cc-edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.cc-cancel-edit-button,
.cc-save-edit-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cc-cancel-edit-button {
  background-color: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.cc-cancel-edit-button:hover {
  background-color: #e5e7eb;
}

.cc-save-edit-button {
  background-color: #4076f6;
  color: white;
  border: none;
}

.cc-save-edit-button:hover {
  background-color: #2c5cd1;
}

/* Comment actions row */
.cc-comment-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

/* Action buttons */
.cc-action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #6b7280;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.cc-action-button:hover {
  background-color: #f9fafb;
  color: #4b5563;
}

/* Liked state */
.cc-like-button.liked {
  color: #f43f5e;
}

.cc-like-button.liked svg {
  fill: #f43f5e;
}

/* More actions dropdown */
.cc-more-actions-container {
  position: relative;
  margin-left: auto;
}

.cc-more-actions-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 140px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  z-index: 10;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.cc-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-size: 0.75rem;
  color: #4b5563;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cc-dropdown-item:hover {
  background-color: #f9fafb;
}

.cc-edit-button {
  color: #4076f6;
}

.cc-report-button {
  color: #ef4444;
}

/* Replies section */
.cc-replies-container {
  margin-top: 12px;
}

.cc-reply-form-container {
  margin-bottom: 16px;
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .cc-comment-container.depth-1,
  .cc-comment-container.depth-2,
  .cc-comment-container.depth-3 {
    margin-left: 16px;
    padding-left: 12px;
  }
  
  .cc-comment-content {
    padding: 14px;
  }
  
  .cc-comment-header {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .cc-author-avatar {
    width: 36px;
    height: 36px;
  }
  
  .cc-comment-time {
    font-size: 0.7rem;
    margin-left: auto;
  }
  
  .cc-comment-actions {
    flex-wrap: wrap;
  }

  .cc-edit-actions {
    flex-direction: column;
  }
  
  .cc-cancel-edit-button, 
  .cc-save-edit-button {
    width: 100%;
    justify-content: center;
  }
}

/* Add these styles to your existing Comment.css file */

/* Deleted comment styling */
.cc-comment-content.deleted {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  padding: 12px;
  opacity: 0.8;
}

.cc-deleted-comment-text {
  color: #6c757d;
  font-style: italic;
  text-align: center;
  margin: 0;
  padding: 8px 0;
  font-size: 0.9rem;
}

/* Delete button in the dropdown */
.cc-dropdown-item.cc-delete-button {
  color: #e03131;
}

.cc-dropdown-item.cc-delete-button:hover {
  background-color: #fff5f5;
}