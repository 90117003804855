.review-modal .modal-content {
  border-radius: 16px 16px 0 0;
  border: none;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
  max-height: 100vh;
}

.review-modal .modal-dialog {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

.review-modal .modal-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.review-modal .modal-title {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin: 0;
}

.review-modal .modal-body {
  padding: 16px;
}

.review-modal .intro-text {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

/* Experience Slider Section */
.review-modal .slider-section {
  margin-bottom: 24px;
}

.review-modal .slider-section h6 {
  font-size: 15px;
  margin-bottom: 12px;
  text-align: center;
}

.review-modal .experience-description {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 8px;
}

/* Feedback Sections */
.review-modal .feedback-section {
  margin-bottom: 24px;
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

.review-modal .section-label {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}

.review-modal .section-subtext {
  font-size: 13px;
  color: #666;
  margin-bottom: 12px;
}

/* Input Groups */
.review-modal .input-group {
  margin-bottom: 12px;
  align-items: stretch;
}

.review-modal .input-group .form-control {
  height: 40px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
}

.review-modal .input-group .btn {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
  border-radius: 4px;
}

/* List Items */
.review-modal .list-group-item {
  padding: 12px;
  margin-bottom: 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.review-modal .list-group-item .btn-outline-danger {
  padding: 4px 8px;
  font-size: 12px;
  margin-left: auto;
}

/* Text Area */
.review-modal textarea.form-control {
  min-height: 100px;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

/* Private Information Section */
.review-modal .private-info-section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #eee;
}

.review-modal .private-info-section .section-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
}

.review-modal .private-info-section .section-description {
  font-size: 13px;
  color: #666;
  margin-bottom: 16px;
}

/* Radio Group */
.review-modal .radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.review-modal .form-check-label {
  font-size: 14px;
}

/* Error Messages */
.review-modal .text-danger {
  font-size: 12px;
  color: #dc3545;
  margin-top: 4px;
}

/* Footer */
.review-modal .modal-footer {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 12px;
}

.review-modal .modal-footer .btn {
  font-size: 14px;
  font-weight: 500;
}

/* Button Colors */
.review-modal .btn-success {
  background-color: #28a745;
  border-color: #28a745;
  color: white;
}

.review-modal .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.review-modal .btn-primary {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .review-modal .modal-dialog {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  .review-modal .modal-content {
    border-radius: 0;
    max-height: 100vh;
  }

  .review-modal .modal-header,
  .review-modal .modal-footer {
    padding: 12px;
  }

  .review-modal .input-group {
    flex-direction: column;
    gap: 8px;
  }

  .review-modal .list-group-item {
    flex-direction: row;
    gap: 8px;
  }

  .review-modal .modal-footer {
    flex-direction: column;
  }

  .review-modal .modal-footer .btn {
    width: 100%;
  }
}

/* Touch Optimizations */
@media (hover: none) {
  .review-modal .btn:active {
    opacity: 0.8;
  }
}


.review-modal .modal-body {
  overflow-y: auto;
  max-height: 70vh; /* Adjust the value as needed */
  padding: 16px; /* Optional: Adjust padding for better spacing */
}

/* Optional: Ensure the modal itself handles overflow */
.review-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.review-modal .modal-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.review-modal .modal-header,
.review-modal .modal-footer {
  flex: 0 0 auto;
}

.review-modal .modal-body {
  flex: 1 1 auto;
}


