:root {
  --ltc-primary-color: #4B0082;
  --ltc-info: #3b82f6;
  --ltc-lab: #9333ea;
  --ltc-text-dark: #1f2937;
  --ltc-border: #e5e7eb;
  --ltc-card-bg: #ffffff;
  --ltc-secondary-color: #6c757d; /* Example secondary color for buttons */
}

.ltc-card {
  background: var(--ltc-card-bg);
  border: 1px solid var(--ltc-border);
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  width: 100%;
  transition: box-shadow 0.2s ease;
}

.ltc-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.ltc-content-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Prominent Lab Test ID Section */
.ltc-id-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 2px solid var(--ltc-border);
  padding-bottom: 12px;
}

.ltc-test-id-label {
  font-size: 1.5rem; /* Same as before */
  font-weight: 600;
  color: var(--ltc-text-dark);
  margin: 0;
  white-space: nowrap;
}

.ltc-test-id {
  font-size: 1.7rem; /* Same as before */
  color: var(--ltc-primary-color);
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* Allow it to take up to 100% width */
}

/* Class for the full ID (when expanded) */
.ltc-full-id {
  max-width: none; /* Remove width constraint */
  overflow: visible;
  text-overflow: clip;
}

.ltc-top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

.ltc-left-side {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.ltc-logo-section {
  flex-shrink: 0;
}

.ltc-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.ltc-company-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.ltc-name {
  font-size: 1.3rem; /* Increased size */
  font-weight: 600;
  color: var(--ltc-primary-color); /* Changed color to make it stand out */
  margin: 0;
}

.ltc-trust-indicators {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.ltc-trust-pill {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 0.75rem;
  padding: 3px 10px;
  border-radius: 9999px;
  font-weight: 500;
  background-color: #f9fafb;
  white-space: nowrap;
}

.ltc-info {
  background-color: rgba(59, 130, 246, 0.1);
  color: var(--ltc-info);
}

.ltc-lab {
  background-color: rgba(147, 51, 234, 0.1);
  color: var(--ltc-lab);
}

.ltc-right-side {
  display: flex;
  align-items: center;
  min-width: 19rem;
}

.ltc-stats-container {
  background: #f9fafb;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

/* Alignment adjustments for metadata items */
.ltc-metadata-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.ltc-metadata-item strong {
  display: inline-block;
  width: 75px; /* Reduced width */
  text-align: right;
  margin-right: 8px;
  color: var(--ltc-text-dark); /* Darker text color */
}

.ltc-product-link {
  /* color: var(--ltc-text-dark); */
  text-decoration: none;
  font-size: 1.1rem; /* Increased size */
  text-decoration: none;
  color: "blue"; /* Define your primary color in CSS variables */
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  transition: color 0.2s ease, text-decoration 0.2s ease;
  cursor: pointer;
}

.ltc-product-link:hover,
.ltc-product-link:focus {
  color: var(--primary-dark); /* Darker shade on hover/focus */
  text-decoration: underline;
}

.ltc-link-icon {
  font-size: 0.75rem;
  transition: transform 0.2s ease;
}

.ltc-product-link:hover .ltc-link-icon,
.ltc-product-link:focus .ltc-link-icon {
  transform: translateX(2px);
}

/* Focus Outline for Accessibility */
.ltc-product-link:focus {
  outline: 2px dashed var(--primary-dark);
  outline-offset: 2px;
}

/* Optional: Hover Cursor Style */
.ltc-product-link:hover {
  cursor: pointer;
}

.ltc-product-link:hover {
  text-decoration: underline;
}

.ltc-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end; /* Buttons on the right */
}

.ltc-btn-view-details {
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  border: none;
  background-color: var(--ltc-primary-color);
  color: #ffffff;
  white-space: nowrap;
  transition: all 0.2s ease;
  cursor: pointer;
}

/* Secondary button style */
.ltc-btn-secondary {
  background-color: var(--ltc-secondary-color);
  color: #fff;
}

.ltc-btn-view-details:hover {
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ltc-top-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .ltc-right-side {
    width: 100%;
    margin-top: 8px;
    justify-content: center;
  }

  .ltc-stats-container {
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }

  .ltc-metadata-item {
    justify-content: flex-start;
  }

  .ltc-actions {
    justify-content: center;
  }

  .ltc-product-link {
    word-break: break-word; /* Allow the product name to wrap */
  }

  /* Hide full ID on smaller screens */
  .ltc-full-id {
    display: none;
  }
  /* Show the full ID when clicked */
  .ltc-test-id.ltc-full-id {
    display: inline;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
  }
}

@media (max-width: 480px) {
  .ltc-id-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-bottom: 16px;
  }

  .ltc-id-icon-large {
    font-size: 1.8rem;
  }

  .ltc-test-id {
    font-size: 1.7rem;
    word-break: break-all;
  }

  .ltc-name {
    font-size: 1rem;
  }

  .ltc-stats-container {
    padding: 10px 12px;
    gap: 8px;
  }

  .ltc-btn-view-details {
    padding: 10px 16px;
    font-size: 0.8rem;
  }

  .ltc-metadata-item {
    justify-content: flex-start;
    margin-bottom: 4px;
  }
}

/* ... other LabTestPage CSS ... */

/* Info Icon for Card Titles */
.labtest-info-icon {
  color: var(--vc-info); /* Or your preferred color */
  margin-left: 0.5rem;
  cursor: pointer;
}

/* Info Icon for Test Options */
.labtest-option-info-icon {
  color: var(--vc-info); /* Or your preferred color */
  margin-left: 0.5rem;
  cursor: pointer;
}

/* Popover styling */
.popover {
  border: 1px solid var(--vc-border);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.popover .popover-header {
  background-color: var(--vc-primary-green);
  color: #fff;
  font-weight: 600;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popover .popover-body {
  font-size: 0.9rem;
  color: var(--vc-text-dark);
}

/* Popover styling for Test Options */
.info-popover {
  border: 1px solid var(--vc-border);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.info-popover .popover-header {
  background-color: var(--vc-primary-green);
  color: #fff;
  font-weight: 600;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.5rem 1rem; /* Adjust padding */
  font-size: 1rem; /* Adjust font size */
}

.info-popover .popover-body {
  font-size: 0.9rem;
  color: var(--vc-text-dark);
  padding: 0.5rem 1rem; /* Adjust padding */
}

/* Add these styles to your LabTestTopCard.css file */

/* Update the existing ltc-id-section styles */
.ltc-id-section {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ltc-test-id-label {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.ltc-test-id {
  font-family: var(--bs-font-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  background-color: rgba(27, 47, 27, 0.05);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
  max-width: 100%;
  overflow-wrap: break-word;
  word-break: break-all;
}

.ltc-test-id:hover {
  background-color: rgba(27, 47, 27, 0.1);
}

/* Style for the friendly name display */
.ltc-test-id.ltc-friendly-id {
  font-family: var(--bs-font-sans-serif);
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  word-break: normal;
  color: #343a40;
  font-weight: 600;
}

.ltc-test-id.ltc-friendly-id:hover {
  background-color: #e9ecef;
}

/* Toggle hint for the ID/name display */
.ltc-id-toggle-hint {
  font-size: 0.75rem;
  color: #6c757d;
  margin-top: 0.25rem;
  font-style: italic;
}

/* Update for Lot Number display in metadata */
.ltc-metadata-item {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  flex-wrap: wrap;
}

.ltc-metadata-item strong {
  margin-right: 0.5rem;
  min-width: 70px;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ltc-test-id {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }
  
  .ltc-id-toggle-hint {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .ltc-test-id {
    font-size: 0.85rem;
    padding: 0.3rem 0.5rem;
    max-width: 100%;
  }
}