/* Offcanvas Container */
.signin-signup-offcanvas {
    max-width: 450px;
    /* Adjust width as needed */
    width: 95%;
    border-left: none;
    /* Remove default border */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    /* Add a subtle shadow */
  }
  
  /* Offcanvas Header */
  .signin-signup-offcanvas .offcanvas-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    background-color: #1b2f1b; /* Primary green color */
    display: flex; /* Use flexbox for better alignment control */
    align-items: center; /* Align items vertically */
  }
  
  .signin-signup-offcanvas .offcanvas-title {
    font-size: 1.35rem; /* Increased font size */
    font-weight: 600;
    text-align: left; /* Align title to the left */
    margin: 0; /* Remove default margin */
    color: #fff; /* White text color for contrast */
  }
  
  /* Close button alignment */
  .signin-signup-offcanvas .btn-close {
    margin-left: auto; /* Push close button to the right */
  }
  
  /* Offcanvas Body */
  .signin-signup-offcanvas .offcanvas-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Switch Mode Button */
  .signin-signup-offcanvas .switch-mode-btn {
    color: #1b2f1b; /* Primary green color */
    font-weight: 500;
    text-decoration: underline;
    padding: 0;
    border: none;
    background: none;
    font-size: 0.9rem; /* Slightly larger font size */
  }
  
  .signin-signup-offcanvas .switch-mode-btn:hover {
    color: #370063;
    /* Darker shade on hover */
  }
  
  /* Alert Messages */
  .signin-signup-offcanvas .alert {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
  
  /* Form Styling */
  .signin-signup-offcanvas .form-label {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .signin-signup-offcanvas .form-control {
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
  
  .signin-signup-offcanvas .form-control:focus {
    border-color: #4a7140; /* Secondary green color */
    box-shadow: 0 0 0 0.2rem rgba(74, 113, 64, 0.25); /* Adjust color as needed */
  }
  
  .psw-group{
    position: relative;
}
  /* Password Toggle */
  .signin-signup-offcanvas .password-toggle {
    cursor: pointer;
    user-select: none;
    padding: 0 0.5rem!important;
  }
  
  .signin-signup-offcanvas .password-toggle svg {
    /* Gray color for icons */
    color: #6b7280;
    font-size: 1.2rem;
  }
  
  .signin-signup-offcanvas .password-toggle:hover svg {
    color: #1b2f1b; /* Primary green color on hover */
  }
  
  /* Input Group (for Password Toggle) */
  .signin-signup-offcanvas .input-group-text {
    background: none;
    border: none;
    padding: 0;
  }
  
  /* Buttons */
  .signin-signup-offcanvas .btn {
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .signin-signup-offcanvas .btn-primary {
    background-color: var(--secondary-green);
    border-color: var(--secondary-green);
  }
  
  .signin-signup-offcanvas .btn-primary:hover {
    background-color: #2e7d32;
    /* Darker green on hover */
    border-color: #2e7d32;
  }
  
  /* Google Button */
  .signin-signup-offcanvas .google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dadce0;
    color: #444;
    background-color: #fff;
  }
  
  .signin-signup-offcanvas .google-btn:hover {
    background-color: #f8f9fa;
    /* Slightly darker background on hover */
  }
  
  .signin-signup-offcanvas .google-btn svg {
    margin-right: 0.5rem;
  }
  
  /* Modal Styling (for Account Linking) */
  .signin-signup-offcanvas .modal-custom-header {
    background: linear-gradient(to right, #1b2f1b, #4a7140); /* Gradient with your green colors */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff;
  }
  
  .signin-signup-offcanvas .modal-custom-title {
    color: #fff;
    font-weight: 600;
  }
  
  /* Responsive Design */
  @media (max-width: 576px) {
    .signin-signup-offcanvas {
      width: 100%;
      max-width: none;
      box-shadow: none;
    }
  
    .signin-signup-offcanvas .offcanvas-header {
      padding: 1rem;
    }
  
    .signin-signup-offcanvas .offcanvas-title {
      font-size: 1.2rem; /* Adjusted for smaller screens */
    }
  
    .signin-signup-offcanvas .switch-mode-btn {
      font-size: 0.8rem;
    }
  
    .signin-signup-offcanvas .alert {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
  
    .signin-signup-offcanvas .form-label {
      font-size: 0.8rem;
    }
  
    .signin-signup-offcanvas .form-control {
      font-size: 0.8rem;
      padding: 0.375rem 0.5rem;
    }
  
    .signin-signup-offcanvas .password-toggle svg {
      font-size: 1rem;
    }
  
    .signin-signup-offcanvas .btn {
      font-size: 0.9rem;
      padding: 0.5rem;
    }
  
    /* Ensure Offcanvas takes up full screen height on mobile */
    .signin-signup-offcanvas.offcanvas.offcanvas-bottom {
      height: auto; /* Let it take the full available height */
      max-height: none; /* Remove max-height */
      bottom: 0; /* Ensure it sticks to the bottom */
      top: auto;
    }
  
    .signin-signup-offcanvas .offcanvas-body {
      overflow-y: auto; /* Allow vertical scrolling if content overflows */
    }
  }
  
  /* Touch Optimizations */
  @media (hover: none) {
    .signin-signup-offcanvas .btn:active {
      opacity: 0.8;
    }
  
    .signin-signup-offcanvas .google-btn:active {
      background-color: #f8f9fa;
    }
  
    .signin-signup-offcanvas .switch-mode-btn:active {
      color: #385730; /* Darker green on active */
    }
  
    .signin-signup-offcanvas .password-toggle:active svg {
      color: #1b2f1b; /* Primary green on active */
    }
  }