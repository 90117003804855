/* KratomHubHomepage.css - Redesigned with modern aesthetics */

/* -----------------------------------------------------
   VARIABLES & GLOBAL STYLES
   ----------------------------------------------------- */
   :root {
    --primary-color: #3b7e3b;
    --primary-dark: #2c5e2c;
    --primary-light: #519951;
    --secondary-color: #f5f5dc;
    --accent-color: #65a765;
    --dark-color: #1b2f1b;
    --text-color: #333;
    --text-light: #666;
    --card-bg: #fff;
    --section-bg: #f9f9f7;
    --border-radius: 10px;
    --box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
  }
  
  .kh-community-section {
    padding: 7rem 0;
    background: linear-gradient(135deg, rgba(59, 126, 59, 0.05) 0%, rgba(59, 126, 59, 0.1) 100%);
    position: relative;
    overflow: hidden;
  }
  
  .kh-community-content {
    max-width: 700px;
    margin: 0 auto;
    padding: 3rem;
    background: white;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    position: relative;
    z-index: 2;
    transition: var(--transition);
  }
  
  .kh-community-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .kh-community-content h2 {
    color: var(--dark-color);
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  
  .kh-community-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: var(--text-color);
  }
  
  /* -----------------------------------------------------
     RESPONSIVE STYLES
     ----------------------------------------------------- */
  @media (max-width: 1199px) {
    .kh-hero-title {
      font-size: 3.25rem;
    }
    
    .kh-hero-subtitle {
      font-size: 1.2rem;
    }
    
    .kh-vendor-content {
      padding-right: 1rem;
    }
  }
  
  @media (max-width: 991px) {
    .kh-hero-section {
      padding: 5rem 0;
    }
    
    .kh-hero-title {
      font-size: 3rem;
    }
    
    .kh-section-title {
      font-size: 2.25rem;
    }
    
    .kh-feature-card {
      margin-bottom: 2rem;
    }
    
    .kh-vendor-content {
      padding-right: 0;
      margin-bottom: 3rem;
    }
    
    .kh-vendor-cta {
      max-width: 600px;
      margin: 0 auto;
    }
    
    .kh-community-content {
      padding: 2.5rem;
    }
    
    .kh-community-content h2 {
      font-size: 2.25rem;
    }
  }
  
  @media (max-width: 767px) {
    .kh-hero-section {
      padding: 4rem 0;
    }
    
    .kh-hero-title {
      font-size: 2.5rem;
    }
    
    .kh-hero-subtitle {
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }
    
    .kh-hero-buttons {
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
    
    .kh-section-title {
      font-size: 2rem;
    }
    
    .kh-trust-section,
    .kh-featured-content,
    .kh-vendor-section,
    .kh-community-section {
      padding: 4rem 0;
    }
    
    .kh-vendor-content h2 {
      font-size: 2.25rem;
    }
    
    .kh-vendor-content h2::after {
      left: 50%;
      transform: translateX(-50%);
    }
    
    .kh-vendor-content {
      text-align: center;
    }
    
    .kh-vendor-benefits li {
      justify-content: flex-start;
      text-align: left;
    }
    
    .kh-community-content {
      padding: 2rem;
    }
    
    .kh-community-content h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 575px) {
    .kh-hero-title {
      font-size: 2.25rem;
    }
    
    .kh-section-title {
      font-size: 1.75rem;
    }
    
    .kh-feature-icon {
      width: 70px;
      height: 70px;
      font-size: 1.75rem;
    }
    
    .kh-feature-card h3 {
      font-size: 1.35rem;
    }
    
    .kh-card-header {
      padding: 1.25rem;
    }
    
    .kh-card-header h2 {
      font-size: 1.5rem;
    }
    
    .kh-card-body {
      padding: 1.25rem;
    }
    
    .kh-card-footer {
      padding: 1.25rem;
    }
    
    .kh-vendor-cta {
      padding: 2rem;
    }
    
    .kh-vendor-cta h3 {
      font-size: 1.5rem;
    }
    
    .kh-vendor-benefits li {
      font-size: 1rem;
    }
    
    .kh-community-content {
      padding: 1.75rem;
    }
    
    .kh-community-content h2 {
      font-size: 1.75rem;
    }
    
    .kh-community-content p {
      font-size: 1.1rem;
    }
  }
  
  /* -----------------------------------------------------
     ANIMATIONS & EFFECTS
     ----------------------------------------------------- */
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .kh-vendor-cta::before {
    animation: pulse 6s infinite ease-in-out;
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--primary-light);
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
  }ratom-hub-homepage {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .kh-section-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .kh-section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--dark-color);
    margin-bottom: 1rem;
  }
  
  .kh-section-underline {
    height: 4px;
    width: 80px;
    background: var(--primary-color);
    margin: 0 auto;
    border-radius: 2px;
  }
  
  .kh-highlight {
    color: var(--primary-color);
    position: relative;
  }
  
  .kh-highlight::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: rgba(101, 167, 101, 0.3);
    z-index: -1;
  }
  
  .kh-number-one {
    color: var(--primary-color);
    font-weight: 800;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 0 8px;
    border-radius: 6px;
    margin-right: 4px;
    display: inline-block;
    position: relative;
    transform: rotate(-3deg);
  }
  
  /* -----------------------------------------------------
     BUTTONS
     ----------------------------------------------------- */
  .kh-btn-primary, 
  .kh-btn-secondary,
  .kh-btn-view-all,
  .kh-btn-community,
  .kh-btn-cta {
    border: none;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: var(--transition);
    padding: 12px 24px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .kh-btn-primary {
    background: var(--primary-color);
    color: white;
  }
  
  .kh-btn-primary:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(59, 126, 59, 0.3);
  }
  
  .kh-btn-secondary {
    background: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  
  .kh-btn-secondary:hover {
    background: rgba(59, 126, 59, 0.1);
    transform: translateY(-2px);
    color: var(--primary-dark);
  }
  
  .kh-btn-view-all {
    background: var(--primary-color);
    color: white;
    width: 100%;
    padding: 12px;
    font-weight: 600;
    transition: var(--transition);
  }
  
  .kh-btn-view-all:hover {
    background: var(--primary-dark);
    color: white;
  }
  
  .kh-btn-community {
    background: var(--primary-color);
    color: white;
    padding: 15px 30px;
    font-size: 1.1rem;
    margin-top: 1.5rem;
    min-width: 200px;
  }
  
  .kh-btn-community:hover {
    background: var(--primary-dark);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(59, 126, 59, 0.3);
  }
  
  .kh-btn-cta {
    background: var(--primary-color);
    color: white;
    width: 100%;
    padding: 15px;
    font-size: 1.1rem;
    margin: 1.5rem 0;
    position: relative;
    overflow: hidden;
  }
  
  .kh-btn-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: left 0.7s ease;
  }
  
  .kh-btn-cta:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(59, 126, 59, 0.3);
    color: white;
  }
  
  .kh-btn-cta:hover::before {
    left: 100%;
  }
  
  .kh-btn-icon {
    transition: transform 0.2s ease;
  }
  
  button:hover .kh-btn-icon {
    transform: translateX(3px);
  }
  
  /* -----------------------------------------------------
     HERO SECTION
     ----------------------------------------------------- */
  .kh-hero-section {
    background: linear-gradient(135deg, var(--dark-color) 0%, #2a4a2a 100%);
    padding: 7rem 0;
    position: relative;
    overflow: hidden;
    color: white;
  }
  
  .kh-leaf-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b7e3b' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.7;
  }
  
  .kh-hero-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;
  }
  
  .kh-hero-title {
    font-size: 3.75rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    letter-spacing: -0.03em;
  }
  
  .kh-hero-subtitle {
    font-size: 1.35rem;
    opacity: 0.9;
    max-width: 700px;
    margin: 0 auto 2.5rem;
    font-weight: 400;
  }
  
  .kh-hero-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  /* -----------------------------------------------------
     TRUST SECTION
     ----------------------------------------------------- */
  .kh-trust-section {
    padding: 5rem 0;
    background-color: var(--section-bg);
  }
  
  .kh-trust-features {
    margin-top: 3rem;
  }
  
  .kh-feature-card {
    background: var(--card-bg);
    border-radius: var(--border-radius);
    padding: 2.5rem 1.75rem;
    text-align: center;
    box-shadow: var(--box-shadow);
    height: 100%;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  .kh-feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: var(--primary-color);
    z-index: -1;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }
  
  .kh-feature-card:hover {
    transform: translateY(-10px);
  }
  
  .kh-feature-card:hover::before {
    transform: translateY(0);
  }
  
  .kh-feature-icon {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, rgba(59, 126, 59, 0.1) 0%, rgba(59, 126, 59, 0.2) 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.75rem;
    font-size: 2rem;
    color: var(--primary-color);
    transition: var(--transition);
  }
  
  .kh-feature-card:hover .kh-feature-icon {
    transform: scale(1.1);
    background: linear-gradient(135deg, rgba(59, 126, 59, 0.2) 0%, rgba(59, 126, 59, 0.3) 100%);
  }
  
  .kh-feature-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--dark-color);
  }
  
  .kh-feature-card p {
    color: var(--text-light);
    margin-bottom: 0;
    font-size: 1rem;
  }
  
  /* -----------------------------------------------------
     FEATURED CONTENT SECTION
     ----------------------------------------------------- */
  .kh-featured-content {
    padding: 5rem 0;
    background-color: white;
  }
  
  .kh-featured-row {
    margin-top: 1rem;
  }
  
  .kh-card {
    height: 100%;
    background: white;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    transition: var(--transition);
  }
  
  .kh-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
  
  .kh-card-header {
    background: #243924;
    color: white;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .kh-card-header h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
  }
  
  .kh-card-icon {
    font-size: 1.75rem;
    opacity: 0.8;
  }
  
  .kh-card-body {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .kh-card-footer {
    padding: 1.5rem;
    background: #f9f9f7;
  }
  
  /* -----------------------------------------------------
     VENDOR SECTION
     ----------------------------------------------------- */
  .kh-vendor-section {
    padding: 6rem 0;
    background-color: var(--secondary-color);
    position: relative;
    overflow: hidden;
  }
  
  .kh-vendor-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.4) 1px, transparent 1px),
                      linear-gradient(90deg, rgba(255, 255, 255, 0.4) 1px, transparent 1px);
    background-size: 20px 20px;
    z-index: 1;
  }
  
  .kh-vendor-row {
    position: relative;
    z-index: 2;
  }
  
  .kh-vendor-content {
    padding-right: 2rem;
  }
  
  .kh-vendor-content h2 {
    color: var(--dark-color);
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
  }
  
  .kh-vendor-content h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 80px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 2px;
  }
  
  .kh-vendor-lead {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .kh-vendor-benefits {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
  }
  
  .kh-vendor-benefits li {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1.25rem;
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 500;
    transition: var(--transition);
    padding-left: 0.5rem;
  }
  
  .kh-vendor-benefits li:hover {
    transform: translateX(5px);
  }
  
  .kh-benefit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    height: 28px;
    background-color: var(--primary-light);
    color: white;
    border-radius: 50%;
    margin-top: 2px;
    flex-shrink: 0;
  }
  
  .kh-vendor-cta {
    background: white;
    border-radius: var(--border-radius);
    padding: 2.5rem;
    box-shadow: var(--box-shadow);
    height: 100%;
    position: relative;
    z-index: 3;
    transition: var(--transition);
  }
  
  .kh-vendor-cta:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  .kh-vendor-cta::before {
    content: '';
    position: absolute;
    top: -15px;
    right: -15px;
    width: 80px;
    height: 80px;
    background-color: var(--primary-color);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    z-index: -1;
    opacity: 0.8;
  }
  
  .kh-vendor-cta h3 {
    color: var(--dark-color);
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .kh-vendor-cta p {
    color: var(--text-color);
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
  
  .kh-vendor-note {
    color: var(--text-light);
    font-size: 0.95rem;
    font-style: italic;
    margin-top: 1rem;
    text-align: center;
  }
  
  .kh-featured-vendors {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    opacity: 0.7;
    font-size: 0.9rem;
    color: var(--text-light);
  }
  
  /* -----------------------------------------------------
     COMMUNITY SECTION
     ----------------------------------------------------- */
  .kh-community-section {
    padding: 7rem 0;
    background: linear-gradient(135deg, rgba(59, 126, 59, 0.05) 0%, rgba(59, 126, 59, 0.1) 100%);
    position: relative;
    overflow: hidden;
  }
  
  