.complaints-wrapper {
    background: #fff;
    border-radius: 8px;
    margin-top: 1rem;
  }
  
  /* Shared container styles */
  .complaints-content,
  .empty-complaints {
    width: 100%;
  }
  
  /* Empty State */
  .empty-complaints {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
    background: #f8faf9;
    border-radius: 8px;
  }
  
  .empty-content {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .empty-icon {
    font-size: 48px !important;
    color: #166534;
    margin-bottom: 1.5rem;
    opacity: 0.7;
  }
  
  .empty-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #166534;
    margin-bottom: 0.75rem;
  }
  
  .empty-description {
    color: #4b5563;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  /* Main Content Area */
  .complaints-content {
    display: flex;
    flex-direction: column;
  }
  
  .complaints-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    background: #f8faf9;
    border-radius: 8px 8px 0 0;
    margin-bottom: 1.5rem;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
  }
  
  .header-text {
    flex: 1;
  }
  
  .complaint-section-title {
    color: #166534;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  
  .complaint-section-description {
    color: #4b5563;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 0;
  }
  
  /* Notice Box */
  .complaint-notice {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: rgba(22, 101, 52, 0.05);
    border-radius: 6px;
    color: #166534;
    font-size: 0.875rem;
  }
  
  .complaint-notice svg {
    color: #166534;
    opacity: 0.8;
    flex-shrink: 0;
  }
  
  /* Button Styles */
  .complaint-button {
    background-color: #166534 !important;
    border-color: #166534 !important;
    padding: 0.75rem 1.5rem !important;
    font-weight: 500;
    font-size: 0.9375rem;
    border-radius: 6px;
    min-width: 180px;
    white-space: nowrap;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(22, 101, 52, 0.1);
  }
  
  .complaint-button:hover {
    background-color: #15803d !important;
    border-color: #15803d !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(22, 101, 52, 0.15);
  }
  
  .complaint-button:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(22, 101, 52, 0.1);
  }
  
  /* Sign In Dialog */
  .sign-in-wrapper {
    padding: 1rem;
  }
  
  .sign-in-text {
    color: #374151;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin: 0;
  }
  
  .sign-in-link {
    color: #166534 !important;
    font-weight: 500;
    padding: 0 !important;
    text-decoration: none;
    transition: all 0.2s ease;
    border: none;
    background: none;
  }
  
  .sign-in-link:hover {
    color: #15803d !important;
    text-decoration: underline;
  }

  .empty-complaints {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
    background: #f8faf9;
    border-radius: 8px;
  }
  
  .empty-content {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-wrapper {
    background: rgba(22, 101, 52, 0.1);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .empty-icon {
    font-size: 32px !important;
    color: #166534;
  }
  
  .empty-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #166534;
    margin-bottom: 1rem;
  }
  
  .empty-description {
    color: #4b5563;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .empty-state-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  
  .empty-state-actions .process-link {
    color: #166534;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  
  .empty-state-actions .process-link:hover {
    background: rgba(22, 101, 52, 0.1);
    text-decoration: none;
  }
  
  .empty-state-actions .complaint-button {
    width: auto;
    min-width: 200px;
  }
  
  @media (max-width: 768px) {
    .empty-complaints {
      padding: 3rem 1.5rem;
    }
  
    .empty-content {
      max-width: 100%;
    }
  
    .empty-state-actions {
      width: 100%;
    }
  
    .empty-state-actions .complaint-button {
      width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .empty-complaints {
      padding: 2rem 1rem;
    }
  
    .icon-wrapper {
      width: 56px;
      height: 56px;
    }
  
    .empty-icon {
      font-size: 28px !important;
    }
  
    .empty-title {
      font-size: 1.25rem;
    }
  
    .empty-description {
      font-size: 0.875rem;
    }
  }
  
  /* Complaints List Container */
  .complaints-list-container {
    padding: 0 1.5rem 1.5rem;
  }
  .process-link {
    color: #166534;
    text-decoration: underline;
    font-weight: 500;
    transition: color 0.2s ease;
  }
  
  .process-link:hover {
    color: #15803d;
  }
  
  .complaint-process {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.25rem;
    padding: 1rem;
    background: rgba(22, 101, 52, 0.05);
    border-radius: 8px;
  }
  
  .process-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    color: #166534;
    font-size: 0.9375rem;
  }
  
  .process-item svg {
    flex-shrink: 0;
    margin-top: 0.25rem;
  }
  
  .example-title {
    font-weight: 500;
    margin-right: 0.5rem;
  }
  
  .examples-list {
    margin: 0;
    padding-left: 1.25rem;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1.5rem;
  }
  
  .examples-list li {
    position: relative;
    padding-left: 0.75rem;
    color: #374151;
  }
  
  .examples-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #166534;
  }
  
  @media (max-width: 768px) {
    .examples-list {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .complaint-process {
      padding: 0.875rem;
      margin-top: 1rem;
    }
    
    .process-item {
      font-size: 0.875rem;
    }
  }
  
  /* Animation */
  @keyframes fadeSlideUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .empty-icon {
    animation: fadeSlideUp 0.4s ease-out;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .complaints-header {
      padding: 1.25rem;
    }
  
    .header-content {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  
    .complaint-button {
      width: 100%;
      max-width: none;
    }
  
    .empty-complaints {
      padding: 3rem 1.5rem;
    }
  
    .complaints-list-container {
      padding: 0 1rem 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .complaints-header {
      padding: 1rem;
    }
  
    .complaint-section-title {
      font-size: 1.125rem;
    }
  
    .complaint-section-description {
      font-size: 0.875rem;
    }
  
    .empty-complaints {
      padding: 2rem 1rem;
    }
  
    .empty-title {
      font-size: 1.25rem;
    }
  
    .empty-description {
      font-size: 0.875rem;
    }
  
    .complaints-list-container {
      padding: 0 0.75rem 0.75rem;
    }
  }