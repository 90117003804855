/* Container for the entire Privacy Policy page */
.privacy-policy-container {
    font-family: inherit; /* Keep your site's default font */
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
    color: #333;
    line-height: 1.6;
  }
  
  /* Hero section styling */
  .privacy-hero {
    background: #105210;
    color: #fff;
    padding: 4rem 2rem;
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 8px;
  }
  
  .privacy-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .privacy-hero p {
    font-size: 1.1rem;
    margin: 0;
  }
  
  /* Generic section styling */
  .privacy-section {
    margin-bottom: 3rem;
  }
  
  .privacy-section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: #222;
  }
  
  .privacy-section-text {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #555;
  }
  
  /* Unordered list styling within sections */
  .privacy-section ul {
    list-style: disc;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* Link styling */
  .privacy-link {
    color: #34a853;
    text-decoration: none;
  }
  
  .privacy-link:hover {
    text-decoration: underline;
  }
  
  /* Contact section styling */
  .privacy-contact {
    text-align: center;
    background: #f7f7f7;
    padding: 2rem;
    border-radius: 8px;
  }
  