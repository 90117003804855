.review-modal .offcanvas-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .review-modal .offcanvas-title {
    font-size: 1.25rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  
  .review-modal .offcanvas-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Spacing between sections */
    overflow-y: auto;
    max-height: 80vh;
  }
  
  /* Intro Text */
  .review-modal .intro-text {
    font-size: 0.9rem;
    color: #4b5563;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* Form Sections */
  .review-modal .form-section {
    margin-bottom: 1.5rem;
  }
  
  /* Section Titles */
  .review-modal .section-label,
  .review-modal .section-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .review-modal .section-subtext,
  .review-modal .section-description {
    font-size: 0.8rem;
    color: #6b7280;
    margin-bottom: 0.75rem;
  }
  
  /* Slider Section */
  .review-modal .slider-section {
    text-align: center;
  }
  
  .review-modal .slider-section h6 {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  .review-modal .experience-description {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }
  
  /* Input Groups */
  .review-modal .input-group {
    margin-bottom: 0.75rem;
  }
  
  .review-modal .input-group .form-control {
    font-size: 0.9rem;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
  }
  
  .review-modal .input-group .btn {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
  }
  
  /* List Items (Positives/Negatives) */
  .review-modal .list-group-item {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
  }
  
  .review-modal .list-group-item .btn-outline-danger {
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
  }
  
  /* Text Area */
  .review-modal textarea.form-control {
    min-height: 5rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    resize: vertical;
  }
  
  /* Private Information Section */
  .review-modal .private-info-section {
    border-top: 1px solid #e5e7eb;
    padding-top: 1.5rem;
  }
  
  /* Radio Group */
  .review-modal .radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .review-modal .form-check-input {
    margin-top: 0.2rem;
  }
  
  .review-modal .form-check-label {
    font-size: 0.9rem;
  }
  
  /* Error Messages */
  .review-modal .text-danger {
    font-size: 0.8rem;
  }
  
  /* Submit Button Section */
  .review-modal .submit-button-section {
    margin-top: auto; /* Push to the bottom */
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .review-modal .submit-button-section .btn {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
  
  /* Button Colors */
  .review-modal .btn-success {
    background-color: #10b981;
    border-color: #10b981;
  }
  
  .review-modal .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .review-modal .btn-primary {
    background-color: #4B0082;
    border-color: #4B0082;
  }
  .review-modal .btn-outline-secondary {
    color: #4B0082;
  }
  
  /* Responsiveness */
  @media (max-width: 576px) {
    .review-modal .offcanvas-title {
      font-size: 1.1rem;
    }
  
    .review-modal .intro-text {
      font-size: 0.8rem;
    }
  
    .review-modal .section-label,
    .review-modal .section-title {
      font-size: 0.9rem;
    }
    .review-modal.offcanvas.offcanvas-bottom {
        height: 80vh;
        max-height: 800px; /* Adjust if needed */
      }
  
    .review-modal .section-subtext,
    .review-modal .section-description,
    .review-modal .slider-section h6,
    .review-modal .experience-description,
    .review-modal .input-group .form-control,
    .review-modal .input-group .btn,
    .review-modal .list-group-item,
    .review-modal textarea.form-control,
    .review-modal .form-check-label {
      font-size: 0.8rem;
    }
  
    .review-modal .list-group-item .btn-outline-danger {
      padding: 0.1rem 0.4rem;
      font-size: 0.6rem;
    }
  
    .review-modal .submit-button-section .btn {
      font-size: 0.9rem;
    }
  }
  
  /* Touch Optimizations */
  @media (hover: none) {
    .review-modal .btn:active {
      opacity: 0.8;
    }
  }

@media (max-width: 767px) {
  .review-modal .form-section {
    width: 100%;
  }

  .review-modal .input-group {
    width: 100%;
  }

  .review-modal .form-control {
    width: 100%;
  }
}


/* Rating Section */
.review-modal .rating-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .review-modal .rating-section h6 {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .rating-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .rating-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    color: #6b7280; /* Gray color */
    cursor: pointer;
    padding: 0.5rem; /* Add padding */
    border-radius: 8px; /* Rounded corners */
    transition: all 0.2s ease;
    border: 1px solid transparent; /* Transparent border by default */
  }
  
  .rating-option svg {
    font-size: 2rem; /* Increase icon size */
    margin-bottom: 0.2rem;
  }
  
  .rating-option .label-text {
    display: block;
    line-height: 1;
  }
  
  /* Style the selected option */
  .rating-option.selected {
    border-color: #4B0082; /* Primary color border */
    background-color: #f5f2ff; /* Light background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .rating-option.selected svg {
    color: #4B0082; /* Primary color for the icon */
  }
  
  /* Responsiveness for Rating Section */
  @media (max-width: 576px) {
    .rating-option {
      font-size: 0.7rem; /* Smaller font on mobile */
      padding: 0.3rem; /* Reduce padding */
    }
  
    .rating-option svg {
      font-size: 1.5rem; /* Smaller icon size on mobile */
    }
  }

  .review-modal .form-section .react-select__menu { /* Target the dropdown menu specifically */
    z-index: 1000; /* Or a higher value than any overlapping elements */
  }