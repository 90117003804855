/* KratomHub ShowcaseVendorCard - Enhanced Styles */

/* Outer card container */
.showcase-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  padding: 24px;
  transition: all 0.3s ease;
  border: 1px solid rgba(59, 126, 59, 0.1);
  position: relative;
  overflow: hidden;
  margin: 20px;
}

.showcase-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #3b7e3b, #65a765);
  transition: height 0.3s ease;
}

.showcase-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.12);
}

.showcase-card:hover::before {
  height: 6px;
}

/* Header Section */
.showcase-header {
  margin-bottom: 24px;
  text-align: start;
}

.showcase-identity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.showcase-logo-wrapper {
  position: relative;
  flex-shrink: 0;
}

.vendor-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #f5f5dc;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.vendor-logo-placeholder {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
  background: linear-gradient(135deg, #2c5e2c 0%, #3b7e3b 100%) !important;
  border: 3px solid #f5f5dc;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.showcase-card:hover .vendor-logo,
.showcase-card:hover .vendor-logo-placeholder {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.showcase-verified-badge {
  position: absolute;
  bottom: -6px;
  right: -6px;
  background: #3b7e3b;
  color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 2px solid #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 1;
  animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 6px 16px rgba(59, 126, 59, 0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
}

.showcase-name-section {
  flex-grow: 1;
}

.showcase-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c5e2c;
  margin: 0 0 12px 0;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
}

.showcase-name::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #3b7e3b;
  transition: width 0.3s ease;
  border-radius: 1.5px;
}

.showcase-card:hover .showcase-name::after {
  width: 100%;
}

.showcase-trust-badges {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 6px;
}

/* Badges e.g. Lab Verified, shipping info */
.showcase-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 30px;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.showcase-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.showcase-lab-verified {
  background-color: rgba(59, 126, 59, 0.1);
  color: #3b7e3b;
}

.showcase-trust-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 30px;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.showcase-trust-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.showcase-trust-badge.shipping {
  background-color: rgba(59, 126, 59, 0.15);
  color: #2c5e2c;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.showcase-trust-badge.shipping svg {
  flex-shrink: 0;
  margin-right: 2px;
}

.showcase-trust-badge svg,
.showcase-badge svg {
  font-size: 14px;
  color: #3b7e3b;
}

.nationwide-badge, 
.country-badge {
  display: inline-block;
  font-weight: 500;
  margin: 2px;
}

/* Main Content (metrics) */
.showcase-content {
  margin: 24px 0;
}

.showcase-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  /* background: linear-gradient(135deg, rgba(59, 126, 59, 0.05) 0%, rgba(59, 126, 59, 0.1) 100%);
  border-radius: 10px;
  border: 1px solid rgba(59, 126, 59, 0.1); */
}

.showcase-metric {
  text-align: center;
  padding: 16px 12px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.showcase-metric:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}

/* Star Rating Styles */
.rating-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.showcase-metric-star-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.star-rating {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 6px;
}

.star {
  font-size: 1.25rem;
  transition: all 0.3s ease;
}

.showcase-metric:hover .star {
  transform: scale(1.1);
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

.star.filled {
  color: #FFBA08;
}

.star.half {
  color: #FFBA08;
}

.star.empty {
  color: #E9ECEF;
}

.showcase-metric-rating-value {
  font-size: 1.3rem;
  font-weight: 800;
  color: #2d3748;
  margin-top: 4px;
  background: linear-gradient(135deg, #2c5e2c 0%, #3b7e3b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-rating-display {
  font-size: 1.2rem;
  font-weight: 600;
  color: #8a8a8a;
  font-style: italic;
  margin: 10px 0;
  padding: 8px 12px;
  background-color: #f8f8f8;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.showcase-metric:hover .no-rating-display {
  background-color: #f2f2f2;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.08);
}

/* Regular metric value styles */
.showcase-metric-value {
  font-size: 1.75rem;
  font-weight: 800;
  color: #2d3748;
  line-height: 1;
  margin-bottom: 10px;
}

.showcase-metric-value.highlight {
  color: #3b7e3b;
  background: linear-gradient(135deg, #3b7e3b 0%, #65a765 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.showcase-metric-label {
  font-size: 0.95rem;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.showcase-metric-subtext {
  font-size: 0.85rem;
  color: #6b7280;
}

/* Actions */
.showcase-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;
}

.showcase-btn,
.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.showcase-btn::before,
.btn-secondary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: left 0.7s ease;
}

.showcase-btn:hover::before,
.btn-secondary:hover::before {
  left: 100%;
}

.showcase-btn-primary {
  background: linear-gradient(135deg, #3b7e3b 0%, #2c5e2c 100%);
  color: #fff;
  border: none;
}

.showcase-btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(59, 126, 59, 0.3);
  color: white;
}

.btn-secondary {
  background-color: white;
  color: #3b7e3b;
  border: 2px solid #3b7e3b;
}

.btn-secondary:hover {
  background-color: rgba(59, 126, 59, 0.05);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  color: #2c5e2c;
}

.showcase-btn svg,
.btn-secondary svg {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.showcase-btn:hover svg,
.btn-secondary:hover svg {
  transform: translateX(3px);
}

/* Responsive Tweaks */
@media (max-width: 992px) {
  .showcase-card {
    padding: 20px;
  }
  
  .showcase-name {
    font-size: 1.4rem;
  }
  
  .vendor-logo,
  .vendor-logo-placeholder {
    width: 70px;
    height: 70px;
  }
  
  .star {
    font-size: 1.1rem;
  }
  
  .showcase-metric-rating-value {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .showcase-identity {
    flex-direction: column;
    text-align: center;
    gap: 16px;
  }
  
  .showcase-name-section {
    text-align: center;
  }
  
  .showcase-name::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .showcase-trust-badges {
    justify-content: center;
    margin-top: 12px;
  }
  
  .showcase-metrics-grid {
    gap: 16px;
    padding: 16px;
  }
  
  .showcase-metric {
    padding: 12px 8px;
  }
  
  .showcase-metric-value {
    font-size: 1.5rem;
  }
  
  .showcase-metric-label {
    font-size: 0.85rem;
  }

  .showcase-trust-badge.shipping {
    padding: 8px;
    gap: 4px;
    width: fit-content;
    margin: 0 auto;
  }
  
  /* Ensure the text wraps properly */
  .country-badge, .nationwide-badge {
    white-space: normal;
    text-align: center;
  }
  
  .star {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .showcase-card {
    padding: 16px;
  }
  
  .showcase-actions {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .vendor-logo,
  .vendor-logo-placeholder {
    width: 60px;
    height: 60px;
    font-size: 1.5rem !important;
  }
  
  .showcase-verified-badge {
    width: 22px;
    height: 22px;
    font-size: 12px;
  }
  
  .showcase-name {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
  
  .showcase-metric-value {
    font-size: 1.25rem;
  }
  
  .showcase-badge,
  .showcase-trust-badge {
    padding: 4px 10px;
    font-size: 0.8rem;
  }
  
  .showcase-btn,
  .btn-secondary {
    padding: 10px 16px;
    font-size: 0.875rem;
  }

  .showcase-trust-badges {
    flex-direction: column;
    align-items: center;
  }
  
  .showcase-trust-badge.shipping {
    width: 100%;
    max-width: 280px;
  }
  
  .star-rating {
    gap: 2px;
  }
  
  .star {
    font-size: 0.9rem;
  }
  
  .showcase-metric-rating-value {
    font-size: 1.1rem;
  }
}