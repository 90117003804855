/* ReviewList.css */

/* .reviews-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
} */

/* Community Feedback Section */
.community-feedback {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.feedback-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  color: #1a1a1a;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin: 0 auto;
  max-width: 400px; /* Limit width for better centering */
  margin-bottom: 32px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Star Rating Styles */
.star-rating {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 8px;
}

.star-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rating-item .stat-value {
  margin-top: 6px;
}

.star {
  font-size: 1.25rem;
  transition: all 0.3s ease;
}

.stat-item:hover .star {
  transform: scale(1.1);
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

.star.filled {
  color: #FFBA08;
}

.star.half {
  color: #FFBA08;
}

.star.empty {
  color: #E9ECEF;
}

.stat-value {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  color: #1a1a1a;
}

.stat-value.highlight {
  color: #059669;
  background: linear-gradient(135deg, #3b7e3b 0%, #65a765 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-value.positive {
  color: #059669;
}

.stat-value.negative {
  color: #DC2626;
}

.stat-label {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.review-count {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
}

/* Experience Breakdown */
.experience-breakdown {
  max-width: 800px;
  margin: 0 auto;
}

.experience-row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  padding-left: 16px; /* Add consistent left padding */
}

.experience-label {
  width: 120px; /* Fixed width for all labels */
  font-size: 14px;
  color: #666;
  text-align: left; /* Ensure left alignment */
  flex-shrink: 0; /* Prevent label from shrinking */
}

.experience-bar {
  flex: 1;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  min-width: 100px; /* Minimum width for bar */
}

.experience-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.6s ease-out;
}

.experience-fill.very-positive,
.experience-fill.positive {
  background-color: #059669;
}

.experience-fill.neutral {
  background-color: #6B7280;
}

.experience-fill.negative,
.experience-fill.very-negative {
  background-color: #DC2626;
}

.experience-count {
  width: 40px; /* Fixed width for count */
  text-align: right;
  font-size: 14px;
  color: #666;
  flex-shrink: 0; /* Prevent count from shrinking */
}

/* Review Button */
.review-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #2563eb;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 24px;
}

.review-button:hover {
  background-color: #1d4ed8;
}

/* Reviews List */
.reviews-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.no-reviews {
  text-align: center;
  color: #666;
  padding: 24px;
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.pagination {
  display: flex;
  gap: 4px;
}

.pagination .page-item .page-link {
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
  color: #4b5563;
  background-color: white;
  transition: all 0.2s;
}

.pagination .page-item.active .page-link {
  background-color: #2563eb;
  border-color: #2563eb;
  color: white;
}

.pagination .page-item .page-link:hover:not(:disabled) {
  background-color: #f3f4f6;
  border-color: #d1d5db;
}

.pagination .page-item .page-link:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stat-item {
  animation: fadeIn 0.3s ease-out forwards;
}

.stat-item:nth-child(1) { animation-delay: 0s; }
.stat-item:nth-child(2) { animation-delay: 0.1s; }
.stat-item:nth-child(3) { animation-delay: 0.2s; }
.stat-item:nth-child(4) { animation-delay: 0.3s; }

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .community-feedback {
    padding: 16px;
  }

  .experience-label {
    width: 100px;
  }

  .feedback-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .stat-value {
    font-size: 24px;
  }
  
  .star {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  /* Keep a layout similar to desktop but scaled down */
  .experience-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure label on left and count on right */
    gap: 8px;
    margin-bottom: 8px;
  }

  .experience-label {
    width: 100px; /* Slightly smaller on mobile */
    font-size: 14px;
  }

  .experience-bar {
    flex: 1;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
    margin: 0 8px; /* spacing on mobile */
  }

  .experience-count {
    width: 30px;
    font-size: 14px;
    text-align: right;
  }

  .pagination .page-item .page-link {
    min-width: 36px;
    height: 36px;
  }

  .stats-grid {
    gap: 16px;
    max-width: 300px;
  }
  
  .star {
    font-size: 0.95rem;
  }
}

/* Add to ReviewList.css */
.filter-section {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-bar {
  position: relative;
  margin-bottom: 16px;
}

.search-bar input {
  width: 100%;
  padding: 12px 40px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.filter-controls {
  display: flex;
  gap: 12px;
}

.filter-select {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #4b5563;
  background-color: white;
  cursor: pointer;
}

@media (max-width: 480px) {
  .filter-controls {
    flex-direction: column;
    gap: 8px;
  }
}

/* No overall reviews state */
.no-reviews-overall {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 1rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.no-reviews-icon {
  font-size: 48px;
  color: #9ca3af; /* A neutral gray */
  margin-bottom: 1rem;
}

.no-reviews-text {
  color: #6b7280;
  margin-bottom: 1.5rem;
  max-width: 600px;
}

/* Button that appears in the no-reviews container */
.review-button {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.review-button:hover {
  background-color: #1d4ed8;
}

/* For mobile screens */
@media (max-width: 480px) {
  .no-reviews-overall {
    padding: 2rem 1rem;
  }
  
  .no-reviews-icon {
    font-size: 36px;
  }
  
  .no-reviews-text {
    font-size: 14px;
  }
}

/* Updated No Reviews State - KratomHub Style */
.no-reviews-overall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  /* max-width: 800px; */
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(59, 126, 59, 0.1);
}

.no-reviews-overall::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #3b7e3b, #65a765);
}

.no-reviews-icon {
  font-size: 64px;
  color: #3b7e3b;
  margin-bottom: 1.5rem;
  opacity: 0.8;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-reviews-overall h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2c5e2c;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.no-reviews-overall h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #3b7e3b;
  border-radius: 2px;
}

.no-reviews-text {
  color: #555;
  margin-bottom: 2rem;
  max-width: 500px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.review-button {
  padding: 12px 28px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  background-color: #3b7e3b;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(59, 126, 59, 0.3);
  position: relative;
  overflow: hidden;
}

.review-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: left 0.7s ease;
}

.review-button:hover {
  background-color: #2c5e2c;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(59, 126, 59, 0.4);
}

.review-button:hover::before {
  left: 100%;
}

.review-button:active {
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .no-reviews-overall {
    padding: 3rem 1.5rem;
  }
  
  .no-reviews-icon {
    font-size: 56px;
  }
  
  .no-reviews-overall h3 {
    font-size: 1.6rem;
  }
  
  .no-reviews-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .no-reviews-overall {
    padding: 2.5rem 1rem;
  }
  
  .no-reviews-icon {
    font-size: 48px;
    margin-bottom: 1rem;
  }
  
  .no-reviews-overall h3 {
    font-size: 1.4rem;
  }
  
  .no-reviews-text {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
  }
  
  .review-button {
    padding: 10px 20px;
    font-size: 0.95rem;
  }
}