/* src/components/NavBar/NotificationAlert.css */

.notification-alert-container {
  position: relative;
  margin: 0 0.5rem;
}

.notification-bell-wrapper {
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-bell-wrapper:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.notification-icon {
  color: var(--cream-bg);
  font-size: 1.5rem;
}

.notification-avatar {
  width: 40px!important;
  height: 40px!important;
  font-size: 1.2rem!important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.userProfile-avatarPlaceholder {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}

/* Customize the notification badge */
.notification-badge .MuiBadge-badge {
  background-color: #ff4757;
  color: white;
  font-weight: 600;
  min-width: 18px;
  height: 18px;
  font-size: 0.65rem;
  padding: 0 4px;
  border: 2px solid var(--primary-green);
}

/* Desktop notifications menu */
.notifications-paper {
  width: 350px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
}

/* Fix for menu item width */
.notifications-paper .MuiMenuItem-root {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 14px 16px;
  height: auto;
  white-space: normal;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8f9fa;
}

.notifications-title {
  font-weight: 600;
  color: #2c3e50;
}

.mark-all-read {
  color: var(--secondary-green);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.mark-all-read:hover {
  text-decoration: underline;
  color: var(--primary-green);
}

.notification-item {
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
  text-decoration: none !important;
  color: inherit !important;
  min-height: 72px;
  width: 100%;
  box-sizing: border-box;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-content {
  flex: 1;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0; /* Important for text wrapping in flex containers */
  width: 100%;
  overflow-wrap: break-word;
}

/* This is the key fix for proper ellipsis on multiline text */
.notification-message {
  font-size: 0.9rem;
  color: #37474f;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  font-weight: 400;
  width: 100%;
  /* Ensure the parent container has enough height */
  min-height: 3em;
  position: relative;
}

.notification-username {
  font-weight: 600;
}

.thread-title {
  font-weight: 600; /* Reduced from 700 to be less bold */
  font-size: 1rem!important;
}

.notification-time {
  color: #78909c;
  display: block;
  margin-top: 2px;
}

.mark-read-button {
  color: var(--secondary-green);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.notification-item:hover .mark-read-button {
  opacity: 1;
}

/* Empty state */
.empty-notifications {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #78909c;
}

.empty-notifications-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
  opacity: 0.7;
  color: #b0bec5;
  font-size: 3rem !important;
}

/* Mobile dialog styling */
.notifications-dialog-paper {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.dialog-title {
  padding: 16px;
  background-color: #f8f9fa;
}

.dialog-content {
  padding: 0 !important;
}

.empty-notifications-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  color: #78909c;
}

.notifications-header-mobile {
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  background-color: #f8f9fa;
}

.notification-list-item {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  text-decoration: none !important;
  color: inherit !important;
}

/* Fix for mobile ellipsis */
.notification-text .MuiListItemText-primary {
  font-weight: 400;
  color: #37474f;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  line-height: 1.5;
  position: relative;
  max-height: 3em;
  word-break: break-word;
}

.notification-text-primary {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; 
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  white-space: normal;
  max-height: 3em;
  word-break: break-word;
}

.notification-text .MuiListItemText-primary span.notification-username {
  font-weight: 600;
}

.notification-text .MuiListItemText-primary span.thread-title {
  font-weight: 600; /* Reduced from 700 to be less bold */
  font-size: 1rem!important;
}

.notification-text .MuiListItemText-secondary {
  color: #78909c;
  display: block;
  margin-top: 4px;
}

.mark-read-button-mobile {
  color: var(--secondary-green);
}

/* Scrollbar customization for the notifications list */
.notifications-paper::-webkit-scrollbar {
  width: 6px;
}

.notifications-paper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notifications-paper::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.notifications-paper::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .notification-alert-container {
    margin: 0;
    width: 100%;
  }
  
  .notification-bell-wrapper {
    display: inline-flex;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;
    padding: 12px 16px;
  }
  
  .notification-message {
    max-width: none;
  }
  
  /* Mobile dialog should take full width */
  .notifications-dialog .MuiDialog-paper {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

@media (min-width: 768px) {
  .notification-list-item-avatar{
    min-width: 0!important;
  }
}