/* src/components/NavBar/Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.custom-navbar {
  background-color: var(--primary-green);
  padding: 1rem 0;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 4px solid var(--secondary-green);
}

.custom-navbar .navbar-nav .dropdown-menu{
  width: 100%;
}

.custom-navbar .signOut-button{
  background-color: transparent;
  padding: 1rem;
  justify-content: flex-start;
  color: var(--cream-bg);
}

.navbar-brand-custom {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--cream-bg) !important;
  letter-spacing: 0.5px;
  width: 30%;
}

.navbar-brand-custom:hover {
  transform: translateY(-1px);
}

.nav-link {
  color: var(--cream-bg) !important;
  font-weight: 500;
  padding: 0.5rem 1rem !important;
  margin: 0 0.25rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
  position: relative;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.nav-link.active {
  background-color: var(--secondary-green);
  color: white !important;
}

.navbar .navdropdown-active > .nav-link.dropdown-toggle {
  background-color: var(--secondary-green);
  color: white !important;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  background-color: var(--cream-bg);
  border-radius: 2px;
}

/* Scoped User Section */
.navbar-user-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.auth-button {
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.login-button {
  background-color: transparent;
  border: 2px solid var(--cream-bg);
  color: var(--cream-bg) !important;
}

.login-button:hover {
  background-color: var(--cream-bg);
  color: var(--primary-green) !important;
}

/* Scoped User Info */
.singIn-menu{
  display: flex;
  flex-direction: column;
  flex:1;
}

.navbar-user-info {
  display: flex;
  flex-direction: row!important;
}

.navbar-user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--cream-bg);
  transition: transform 0.2s ease;
}

.navbar-user-avatar:hover {
  transform: scale(1.05);
}

.navbar-user-name {
  font-size: 0.95rem;
  color: var(--cream-bg);
  font-weight: 500;
  margin-left: 0.5rem; /* Space between avatar and greeting */
}

/* Mobile Menu Styles */
.dropdown-toggle::after {
  display: none !important; 
}
.navbar-toggler {
  border-color: var(--cream-bg);
  padding: 0.5rem;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(245, 245, 220, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media (max-width: 991.98px) {
  .nav-link {
    margin: 0.5rem 0;
  }

  .navbar-user-section {
    margin: 1rem 0;
    padding: 1rem 0;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-user-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-user-name {
    margin-top: 0.5rem;
    margin-left: 0; /* Remove left margin in mobile view */
  }

  .login-button {
    margin-left: 1rem;
    font-size: 1rem;
  }
}


.navbar-logo{
  width: 100%;
   height: 100% ;
}
/* style={{ width: "100%", height: '100%' }} */
@media (max-width: 480px) {
  .navbar-logo{
    width: 278px;
     height: 100% ;
  }

}